import React from "react";
import { images } from "../../images";
// eslint-disable-next-line
import styles from "./ArrowBackButton.module.css";

const ArrowBackButton = ({
  onClickFunction = () => {
    console.warn("TODO - Function not connected - ArrowBackButton");
  },
}) => {
  return (
    <img
      className={styles.arrowBackWhite}
      src={images.arrowBackWhite}
      alt="taxi logo"
      onClick={onClickFunction}
    />
  );
};

export default ArrowBackButton;
