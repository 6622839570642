import React from "react";
// eslint-disable-next-line
import styles from "./YourEarningCommission.module.css";

const YourEarningCommission = ({
  commissionCollectedByDriver = false,
  amountPayable,
  taxiwarsCommissionAmount,
  totalAmount,
}) => {
  return (
    <>
      {commissionCollectedByDriver && (
        <div className={styles.yourEarningCommission}>
          <div>
            {`Your earning : ₹ ${(
              (parseInt(totalAmount) - parseInt(taxiwarsCommissionAmount)) /
              100
            ).toFixed(1)}`}
          </div>
          <div>
            {`Taxiwars commission : ₹ ${(
              taxiwarsCommissionAmount / 100
            ).toFixed(1)}`}
          </div>
        </div>
      )}
      <p className={styles.tollLine}>
        Parking / Toll will be paid by the customer.
      </p>
    </>
  );
};

export default YourEarningCommission;
