import Cookies from "js-cookie";
import React, { useState } from "react";
import {
  BottomMessagePara,
  Button,
  LoadingSpinner,
  RiderDriverSign,
  TextHeadingSign,
} from "../../../components";
import {
  BASE_URL,
  CODE_200,
  DRIVER_BID_SECURITY_RAZORPAY_PAYMENT_SUCCESS,
  DRIVER_PROFILE_DETAILS,
  DRIVER_RAZORPAY_BID_SECURITY_CREATE_ORDER,
  RAZORPAY_CHECKOUT_V1_JS,
  RAZORPAY_KEY_ID,
} from "../../../constants/apiConstants";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../../constants/storageConstants";
import { images } from "../../../images";
// eslint-disable-next-line
import styles from "./BidSecurityDeposit.module.css";
import { useNavigateOnEvent } from "../../../hooks";
import {
  DRIVER_EXTRAS_EDIT_PROFILE,
  DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
} from "../../../constants/routeConstants";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const BidSecurityDeposit = () => {
  const [paymentLoadingSpinedMessage, setPaymentLoadingSpinedMessage] =
    useState(false);

  // const navigateToDriverHomeViewAllBids = useNavigateOnEvent(
  //   DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
  //   true
  // );
  const navigateToEditProfile = useNavigateOnEvent(
    DRIVER_EXTRAS_EDIT_PROFILE,
    true
  );

  const overdueAmount = useLocation();
  // console.log("BidSecurity.js, overdueAmount", overdueAmount);
  const { state } = overdueAmount;
  // console.log("BidSecurity.js, State ( from navigation ) ", state);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  async function onClickPaymentAndNavigationHandler() {
    // console.log("BidSecurity.js, Payment started..");

    //payment
    // console.log("access token");
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const fetchUrlDriverDetails = `${BASE_URL}${DRIVER_PROFILE_DETAILS}`;
    const fetchOptionsDriverDetails = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const responseProfileDetails = await fetch(
      fetchUrlDriverDetails,
      fetchOptionsDriverDetails
    );
    // console.log("BidSecurity.js, response", responseProfileDetails);

    // console.log("BidSecurity.js, fetch call... ");
    if (!responseProfileDetails.ok) {
      return;
    }

    const dataDriverProfile = await responseProfileDetails.json();
    // console.log("json data await... ", dataDriverProfile);

    if (dataDriverProfile) {
      const res = await loadScript(RAZORPAY_CHECKOUT_V1_JS);
      // console.log("load script ", res);

      if (!res) {
        // setRazorpayLoadError(true);
        // console.log("Razorpay script load error");
        // alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      fetch(`${BASE_URL}${DRIVER_RAZORPAY_BID_SECURITY_CREATE_ORDER}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        method: "POST",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          console.log("BidSecurity.js, Something went wrong - Network error");
          throw response;
        })
        .then((data) => {
          // remember to change the property name checking to a constant
          console.log("BidSecurity.js, create order data..", data);
          if (data) {
            //   setOrderData(data);
            // console.log(data);
            const options = {
              key: RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
              amount: data?.amount,
              currency: "INR",
              name: "Taxiwars",
              description: "Bid Security",
              image: images.splashScreenTaxiwarsLogo,
              order_id: data?.order_id,
              handler: async function (response) {
                // console.log("Data One we sent to handler function ..", data1);
                // console.log(
                //   "RazorPat Payment id",
                //   response.razorpay_payment_id
                // );
                const data1 = {
                  // orderCreationId: orderData?.order_id,
                  payment_id: response.razorpay_payment_id,
                  amount: data?.amount,
                  // razorpayOrderId: response.razorpay_order_id,
                  // razorpaySignature: response.razorpay_signature,
                };
                setPaymentLoadingSpinedMessage(true);
                // const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
                // console.log(
                //   "RazorPat Payment id",
                //   response.razorpay_payment_id
                // );
                fetch(
                  `${BASE_URL}${DRIVER_BID_SECURITY_RAZORPAY_PAYMENT_SUCCESS}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${accessToken}`,
                    },
                    method: "POST",
                    body: JSON.stringify(data1),
                  }
                )
                  .then((response) => {
                    if (response.ok) {
                      return response.json();
                    }
                    console.log(
                      "BidSecurity.js, Something went wrong - Network error"
                    );
                    throw response;
                  })
                  .then((data) => {
                    // remember to change the property name checking to a constant
                    if (data && data.Code === CODE_200) {
                      // setOrderData(data);
                      console.log("BidSecurity.js, Success", data);
                      // TODO navigation
                      // navigateToPickupPoint();
                      // navigateToDriverHomeViewAllBids();
                      toast.success("Payment Success.");
                      navigateToEditProfile();
                      return;
                    } else {
                      console.log("BidSecurity.js, Error", data);
                      // navigateToDriverCompleteProfile();
                    }
                  })
                  .catch((e) => {
                    // setIsNetworkError(true);
                    console.log("BidSecurity.js, Catch error 1 - ", e);
                    // ?

                    // * Remove this in production
                    //   navigateToPickupPoint();
                  });

                // const result = await axios.post(
                //   "http://localhost:5000/payment/success",
                //   data
                // );

                // alert(result.data.msg);
              },
              prefill: {
                name: `${dataDriverProfile?.first_name} ${dataDriverProfile?.last_name}`,
                email: `${dataDriverProfile?.email}`,
                contact: `${dataDriverProfile?.phone_number}`,
              },
              notes: {
                address: `${dataDriverProfile?.street_address_1}`,
                city: `${dataDriverProfile?.city}`,
                state: `${dataDriverProfile?.state}`,
              },

              theme: {
                color: "#000",
              },
            };
            try {
              const paymentObject = new window.Razorpay(options);
              paymentObject.open();
            } catch (error) {
              console.log("BidSecurity.js, Error payment object: ", error);
            }
            // navigateToDriverHomeViewAllBids();
            return;
          } else {
            console.log("BidSecurity.js, Error", data);
            // navigateToDriverCompleteProfile();
          }
        })
        .catch((e) => {
          // setIsNetworkError(true);
          setPaymentLoadingSpinedMessage(false);
          console.log("BidSecurity.js, Catch error 2 - ", e);
        })
        .finally(() => {
          setPaymentLoadingSpinedMessage(false);
          // setIsSending(false);
        });
    }

    // order data destructuring
    // console.log(orderData);
  }
  return (
    <RiderDriverSign back driver>
      <div className={styles.children}>
        <TextHeadingSign displayText="Overdue Payment" />
        <div className={styles.nominalFeeButton}>
          <div className={styles.infoBar}>
            <span>{`₹ ${(state.overdue_amount / 100).toFixed(1)}`}</span>
          </div>
          <Button
            displayText="Proceed to Pay"
            onClickFunction={onClickPaymentAndNavigationHandler}
          />
        </div>
        {paymentLoadingSpinedMessage && (
          <>
            <LoadingSpinner />
            <BottomMessagePara displayText="Please wait while we confirm your payment from our end." />
          </>
        )}
      </div>
    </RiderDriverSign>
  );
};

export default BidSecurityDeposit;
