import { useState, useEffect } from "react";

/**
 * To get location coordinates
 *
 * usage -
 *
 * const { loading, error, data: { latitude, longitude }} = useGeolocation();
 */
const useGeolocation = (options) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState({});

  useEffect(() => {
    const successHandler = (e) => {
      setLoading(false);
      setError(null);
      setData(e.coords);
    };

    const errorhandler = (e) => {
      setError(e);
      setLoading(false);
    };

    navigator.geolocation.getCurrentPosition(
      successHandler,
      errorhandler,
      options
    );

    const id = navigator.geolocation.watchPosition(
      successHandler,
      errorhandler,
      options
    );

    return navigator.geolocation.clearWatch(id);
  }, [options]);

  return { loading, error, data };
};

export { useGeolocation };
