import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    inputNumber: "",
    otp: "",
  },
  reducers: {
    updateInputMobileNumber(state, action) {
      state.inputNumber = action.payload;
      // localStorage.setItem("inputNumber", action.payload);
    },
    otpInputBox(state, action) {
      state.otp = action.payload;
      // localStorage.setItem("otp", action.payload);
    },
    clearOTP(state) {
      state.otp = "";
      // localStorage.clear("otp");
    },
    clearInputNumber(state) {
      state.inputNumber = "";
      // localStorage.clear("inputNumber");
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice;
