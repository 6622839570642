import { createSlice } from "@reduxjs/toolkit";

const userAgentSlice = createSlice({
  name: "userAgent",
  initialState: {
    driver: false,
    rider: false,
  },
  reducers: {
    setUserAgentAsRider(state) {
      state.rider = true;

      state.driver = false;
    },

    setUserAgentAsDriver(state) {
      state.driver = true;

      state.rider = false;
    },
  },
});

export const userAgentActions = userAgentSlice.actions;
export default userAgentSlice;
