import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BackPageHeadingWrapper,
  BidSecurity,
  // Button,
  ErrorInNumber,
  // InputBox,
  InputLabelUserProfileImage,
  NameEmailPhoneLine,
  WalletRidePoints,
  // InputLabelUserProfileImage,
} from "../../../components";
import {
  BASE_URL,
  DRIVER_EARN_TOTAL,
  DRIVER_PROFILE_DETAILS,
} from "../../../constants/apiConstants";
import { driverProfileActions } from "../../../store/slice";
// eslint-disable-next-line
import styles from "./DriverEditProfilePage.module.css";
import { useState } from "react";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  TAXIWARS_EMAIL,
} from "../../../constants/storageConstants";
// import { toast } from "react-toastify";
// import { ONE_SECOND } from "../../../constants/timeConstants";
import { useNavigateOnEvent } from "../../../hooks";
import {
  DRIVER_BID_SECURITY,
  DRIVER_BID_SECURITY_CC_AVENUE,
} from "../../../constants/routeConstants";

const DriverEditProfilePage = () => {
  const dispatch = useDispatch();

  const [isLoadin, setIsLoadin] = useState(false);
  const [isError, setIsError] = useState(false);

  const [driverEarning, setDriverEarning] = useState({});

  const dispatchData = (data) => {
    // console.log(data);
    dispatch(driverProfileActions.updateFirstName(data?.first_name));
    dispatch(driverProfileActions.updateLastName(data?.last_name));
    dispatch(driverProfileActions.updateEmailAddress(data?.email));
    dispatch(driverProfileActions.updateOverdueAmount(data?.due_amount));
    dispatch(driverProfileActions.updatePhoneNumber(data?.phone_number));
    dispatch(driverProfileActions.updateAddressLineOne(data?.street_address_1));
    dispatch(driverProfileActions.updateAddressLineTwo(data?.street_address_2));
    dispatch(driverProfileActions.updateAddressLineCity(data?.city));
    dispatch(driverProfileActions.updateAddressLineState(data?.state));
  };

  const firstName = useSelector((state) => state.driverProfile.firstName);
  const lastName = useSelector((state) => state.driverProfile.lastName);
  const emailAddress = useSelector((state) => state.driverProfile.emailAddress);
  const inputNumber = useSelector((state) => state.driverProfile.phoneNumber);
  const overdueAmount = useSelector(
    (state) => state.driverProfile.overdueAmount
  );

  const navaigateToAddBidSecurity = useNavigateOnEvent(
    DRIVER_BID_SECURITY_CC_AVENUE,
    false,
    { overdue_amount: overdueAmount }
  );

  const loadData = () => {
    setIsLoadin(true);
    setIsError(false);

    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    fetch(`${BASE_URL}${DRIVER_PROFILE_DETAILS}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        dispatchData(data);
      })
      .catch((e) => {
        setIsError(true);
        console.log("DriverEditProfile.js, Error occured", e);
      })
      .finally(() => {
        setIsLoadin(false);
      });
  };

  const getDriverEarning = async () => {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

    const fetchUrl = `${BASE_URL}${DRIVER_EARN_TOTAL}`;
    const fetchOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await fetch(fetchUrl, fetchOptions);

    if (response.ok) {
      const data = await response.json();
      setDriverEarning(data);
    }
  };
  useEffect(() => {
    loadData();
    getDriverEarning();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const addressLineOne = useSelector(
  //   (state) => state.driverProfile.addressLineOne
  // );
  // const addressLineTwo = useSelector(
  //   (state) => state.driverProfile.addressLineTwo
  // );

  // const city = useSelector((state) => state.driverProfile.city);
  // const state = useSelector((state) => state.driverProfile.state);

  // const sendPatchRequest = () => {
  //   setIsSending(true);
  //   setIsSuccess(false);
  //   setIsError(false);
  //   setIsLoadin(false);

  //   const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  //   fetch(`${BASE_URL}${DRIVER_PROFILE_DETAILS}`, {
  //     method: "PATCH",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //     body: JSON.stringify({
  //       first_name: firstName,
  //       last_name: lastName,
  //       email: emailAddress,
  //     }),
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         setIsSending(false);
  //         setIsSuccess(true);
  //         return response.json();
  //       }
  //       throw response;
  //     })
  //     .then((data) => {
  //       console.log("Data received - ", data);
  //     })
  //     .catch((e) => {
  //       console.log("An error occured", e);
  //     });
  // };

  // const sendPatchDataHandler = () => {
  //   // sendPatchRequest();
  //   // Driver not alloed to edit details
  //   toast.error("Not allowed, contact taxiwars help.", {
  //     autoClose: 10 * ONE_SECOND,
  //   });
  // };

  // const doNothingHandler = () => {
  //   // do nothing
  // };

  const addBidSecurityHandler = () => {
    navaigateToAddBidSecurity();
  };

  return (
    <BackPageHeadingWrapper logoHeadingText="View profile">
      <div className={styles.scrollable}>
        <div className={styles.driverEditProfilePage}>
          <div style={{ height: "140px", width: "124px" }}>
            {/* <InputLab elUserProfileImage idAndName="riderProfileImage" /> */}
            <InputLabelUserProfileImage
              openOnChangeBehaviour={false}
              idAndName="riderProfileImage"
            />
          </div>
          {isLoadin && <ErrorInNumber displayText="Loading..." />}
          {isError && (
            <ErrorInNumber displayText="Could not fetch details from server" />
          )}

          <NameEmailPhoneLine
            name={`${firstName || "Full"} ${lastName || "Name"}`}
            email={
              emailAddress !== TAXIWARS_EMAIL
                ? emailAddress
                : "No email provided"
            }
            mobile={inputNumber || "+91 9999999999"}
          />
          {/* <InputBox
            value={firstName}
            idAndName="firstName"
            placeholderText="First Name"
            onChange={doNothingHandler}
          />
          <InputBox
            value={lastName}
            idAndName="lastName"
            placeholderText="Last Name"
            onChange={doNothingHandler}
          />
          <InputBox
            value={inputNumber}
            idAndName="mobile"
            type="tel"
            placeholderText="+91 9999999999"
            onChange={doNothingHandler}
          />
          <InputBox
            value={emailAddress}
            idAndName="emailAddress"
            placeholderText="emailid@email.com"
            onChange={doNothingHandler}
          />
          <InputBox
            value={addressLineOne}
            idAndName="addressLineOne"
            placeholderText="Address Line One"
            onChange={doNothingHandler}
          />

          {addressLineTwo && (
            <InputBox
              value={addressLineTwo}
              idAndName="addressLineTwo"
              placeholderText="Address Line Two"
              onChange={doNothingHandler}
            />
          )}

          <InputBox
            value={city}
            idAndName="emailAddress"
            placeholderText="emailid@email.com"
            onChange={doNothingHandler}
          />

          <InputBox
            value={state}
            idAndName="emailAddress"
            placeholderText="emailid@email.com"
            onChange={doNothingHandler}
          /> */}

          {/* <Button
            displayText="Visit Help for editing info"
            onClickFunction={sendPatchDataHandler}
          /> */}
          <WalletRidePoints
            earnings={driverEarning?.Total_Amount}
            numberOfRides={driverEarning?.Total_Rides}
            points={55}
          />
          <BidSecurity
            amount={overdueAmount}
            // amount={""}
            addBidSecurity={addBidSecurityHandler}
          />
        </div>
      </div>
    </BackPageHeadingWrapper>
  );
};

export default DriverEditProfilePage;
