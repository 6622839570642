import React from "react";
import { images } from "../../images";
// eslint-disable-next-line
import styles from "./TopGreenCheckStatus.module.css";

const TopGreenCheckStatus = ({
  displayText = "prop-TopGreenCheckStatus",
  style,
}) => {
  return (
    <div className={styles.topGreenCheckStatus}>
      <img
        className={styles.greenCircleBlackTick}
        src={images.greenCircleBlackTick}
        alt="green circle with black tick"
      />
      <p style={style} className={styles.displayText}>
        {displayText}
      </p>
    </div>
  );
};

export default TopGreenCheckStatus;
