import React, { useEffect, useState } from "react";
// eslint-disable-next-line
import styles from "./HomescreenGoogleMap.module.css";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { GOOGLE_MAPS_API_KEY } from "../../constants/apiConstants";

const HomescreenGoogleMap = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });
  const [centerCoord, setCenterCoord] = useState({
    loading: "idle", // loading, failed, success
    latitude: 18.578164454287336,
    longitude: 73.90803401551001,
  });

  useEffect(() => {
    setCenterCoord((prev) => {
      return {
        ...prev,
        loading: "loading",
      };
    });

    navigator.geolocation.getCurrentPosition(
      // success
      (e) => {
        console.log("geoloc success", e);
        setCenterCoord((prev) => {
          return {
            ...prev,
            loading: "success",
            latitude: parseFloat(e.coords.latitude),
            longitude: parseFloat(e.coords.longitude),
          };
        });
      },
      // error
      () => {
        console.log("error geoloc");
        setCenterCoord((prev) => {
          return {
            ...prev,
            loading: "failed",
          };
        });
      },
      // optionsgeloc{}
      {
        enableHighAccuracy: true,
        maximumAge: Infinity,
        timeout: 10 * 1000,
      }
    );
  }, []);

  if (!isLoaded) {
    return (
      <div className={styles.homescreenGoogleMap}>
        <div className={styles.loadingState}>
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.homescreenGoogleMap}>
      {(centerCoord.loading === "idle" ||
        centerCoord.loading === "loading") && (
        <div className={styles.loadingState}>
          <LoadingSpinner />
        </div>
      )}
      {centerCoord.loading === "failed" && (
        <div className={styles.loadingState}>
          <p className={styles.failedPara}>
            Please give location permission inside chrome
          </p>
        </div>
      )}
      {centerCoord.loading === "success" && (
        <GoogleMap
          center={{
            lat: parseFloat(centerCoord.latitude),
            lng: parseFloat(centerCoord.longitude),
          }}
          zoom={14}
          mapContainerStyle={{
            height: "100%",
            width: "100%",
            borderRadius: "5px",
          }}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            clickableIcons: false,
            gestureHandling: false,
            // zoom: false,
            zoomControl: false,
            // zoomControlOptions: false,
            // minZoom: 10,
            // maxZoom: 21,
            restriction: {
              latLngBounds: {
                south: 5.4627, // Latitude of the southernmost point of India with an extra margin
                west: 67.1114, // Longitude of the westernmost point of India with an extra margin
                north: 38.0841, // Latitude of the northernmost point of India with an extra margin
                east: 98.3956, // Longitude of the easternmost point of India with an extra margin
              },
              strictBounds: true,
            },
            styles: [
              {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "administrative",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "transit",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              // below options deprecated
              // {
              //   featureType: "water",
              //   elementType: "labels.text.fill",
              //   stylers: [{ visibility: "off" }],
              // },
              // {
              //   featureType: "road",
              //   elementType: "labels.text",
              //   stylers: [{ color: "#000000" }],
              // },
            ],
          }}
        >
          <Marker
            position={{
              lat: parseFloat(centerCoord.latitude),
              lng: parseFloat(centerCoord.longitude),
            }}
          />
        </GoogleMap>
      )}
    </div>
  );
};

export default HomescreenGoogleMap;
