import { useEffect, useState } from "react";

const BottomAd = ({ jsonDataArray }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const rotationInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % jsonDataArray.length);
    }, 2 * 1000); // 1000 = 1 second
    return () => clearInterval(rotationInterval);
  }, []);
  return (
    <div
      style={{
        cursor: "pointer",
        width: "100%",
        height: "80px",
        backgroundColor: "orange",
        position: "absolute",
        bottom: 0,
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          // zIndex: 1,
          position: "absolute",
          // backgroundColor: "green",
          bottom: 0,
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "2rem",
          }}
          onClick={() => {
            window.open(`${jsonDataArray[currentIndex].linkToOpen}`, "_blank");
          }}
        >
          <p
            style={{
              fontSize: "2rem",
              fontWeight: "bolder",
              color: "#000000",
            }}
          >
            {jsonDataArray[currentIndex].lefText}
          </p>
          {/* <img
            style={{
              width: "50%",
              objectFit: "contain",
            }}
            src={jsonDataArray[currentIndex].rightImage}
            alt="ndbi "
          /> */}
        </div>
      </div>
    </div>
  );
};

export default BottomAd;
