import React from "react";
// eslint-disable-next-line
import styles from "./RiderDriverSign.module.css";
import { images } from "../../../images";

const RiderDriverSign = ({ children, driver = false, back = false }) => {
  return (
    <div className={styles.container}>
      <img
        className={styles.logoWithTextRow}
        src={images.logoWithTextRow}
        alt="taxiwars logo with text in a row"
      />
      {children}
      {!driver ? (
        <img
          className={styles.riderDriverSignBottomPicture}
          src={images.riderDriverSignBottomPicture}
          alt="taxi logo"
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default RiderDriverSign;
