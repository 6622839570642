import React from "react";
// eslint-disable-next-line
import styles from "./ErrorInNumber.module.css";

const ErrorInNumber = ({
  displayText = "prop-ErrorInNumber",
  fontSizeLarge = false,
  extraMessage = "",
}) => {
  return (
    <>
      <p
        className={`${styles.errorInNumber} ${
          fontSizeLarge ? styles.fontLarge : ""
        }`}
      >
        {`${displayText}`}
      </p>
      {extraMessage && (
        <span className={styles.extraMessage}>{extraMessage}</span>
      )}
    </>
  );
};

export default ErrorInNumber;
