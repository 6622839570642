import { useEffect } from "react";

const useStopBackgroundLocationUpdate = () => {
  useEffect(() => {
    const portInit = () => {
      if (!navigator.serviceWorker.controller) {
        return;
      }

      const messageChannel = new MessageChannel();
      navigator.serviceWorker.controller.postMessage(
        {
          type: "INIT_PORT",
        },
        [messageChannel.port2]
      );
      messageChannel.port1.onmessage = function (event) {
        if (event.data.type === "PORT_INITIALISED") {
          navigator.serviceWorker.controller.postMessage({
            type: "STOP_BACKGROUND_LOCATION_UPDATE",
          });
        }
      };
    };

    portInit();
  }, []);
};

export { useStopBackgroundLocationUpdate };
