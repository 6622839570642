import React, { useEffect, useState } from "react";
import {
  AnimationLayout,
  Button,
  ErrorInNumber,
  LoginSignSwitchLine,
  RiderDriverSign,
  TextHeadingSign,
  TextParaSign,
} from "../../../components";
import {
  DRIVER_PROFILE_COMPLETE_PROFILE_ROUTE,
  DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
  // RIDER_COMPLETE_PROFILE_ROUTE,
  // RIDER_HOME_POST_A_RIDE__TO_ROUTE,
} from "../../../constants/routeConstants";
import { useLocalStorage, useNavigateOnEvent } from "../../../hooks";
// eslint-disable-next-line
import styles from "./VerifyOtpRiderDriver.module.css";

import OtpInput from "react18-input-otp";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../store/slice";
import {
  BASE_URL,
  DRIVER_GET_OTP,
  DRIVER_LOGIN_VERIFY_WITH_OTP,
  DRIVER_PROFILE_DETAILS,
  RIDER_GET_OTP,
  // RIDER_LOGIN_VERIFY_WITH_OTP,
  // RIDER_PROFILE_DETAILS,
  CODE_200,
  // RIDER_FCM_TOKEN,
  // RIDER_GET_FCM_TOKEN,
  DRIVER_FCM_TOKEN,
  DRIVER_GET_FCM_TOKEN,
} from "../../../constants/apiConstants";
// import { formatPhoneNumber } from "react-phone-number-input";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  START_STOP,
  USER_AGENT,
  USER_AGENT_DRIVER,
  USER_AGENT_RIDER,
  USER_LOCAL_STORAGE,
} from "../../../constants/storageConstants";
import Cookies from "js-cookie";
import { parsePhoneNumber } from "react-phone-number-input";
import { SEVEN_DAYS } from "../../../constants/timeConstants";
import { toast } from "react-toastify";
import { generateIntentLink } from "../../../util";

const VerifyOtpRiderDriver = () => {
  const dispatch = useDispatch();
  const [, setDriverName] = useLocalStorage("driver_name");
  const [userAgent] = useLocalStorage(USER_AGENT);
  const inputNumber = useSelector((state) => state.auth.inputNumber);
  const otp = useSelector((state) => state.auth.otp);
  const fcmToken = useSelector((state) => {
    // console.log(state.fcmToken);
    return state.fcmToken.fcm;
  });

  const [otpNotSentError, setOtpNotSentError] = useState(false);
  const [resendOtpSuccess, setResendOtpSuccess] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isNetworkError, setIsNetworkError] = useState(false);
  const [incorrectOtpError, setIncorrectOtpError] = useState(false);
  // const [waitForResendingOtp, setWaitForResendingOtp] = useState(false);

  const navigateToDriverCompleteProfile = useNavigateOnEvent(
    DRIVER_PROFILE_COMPLETE_PROFILE_ROUTE,
    true
  );
  const navigateToDriverHomeViewAllBids = useNavigateOnEvent(
    DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
    true
  );

  const navigateToDriverHomeViewAllBidsHandler = () => {
    const data = JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE)) || null;
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    if (!data) {
      toast.error("unknown Error");
      return;
    }
    if (true) {
      // acc to backend the detail key in the user details is the user id
      const intentLink = generateIntentLink(
        accessToken,
        data?.details?.toString(),
        data?.phone_number?.toString(),
        START_STOP.start
      );
      window.open(intentLink);
      navigateToDriverHomeViewAllBids();
      return;
    }
    navigateToDriverHomeViewAllBids();
  };

  const handleChange = (enteredOtp) => {
    setIsNetworkError(false);
    setIsSending(false);
    setResendOtpSuccess(false);
    setOtpNotSentError(false);
    setIncorrectOtpError(false);

    dispatch(authActions.otpInputBox(enteredOtp));
  };

  // mobile number parsing
  const [countryCode, setCountryCode] = useState();
  const [onlyNumber, setOnlyNumber] = useState();
  // Mobile number parsing for country code and phone number in locale
  useEffect(() => {
    const parsedValue = parsePhoneNumber(inputNumber || "");
    setCountryCode(parsedValue?.countryCallingCode);
    // const formatNumber = formatPhoneNumber(inputNumber || "");
    setOnlyNumber(parsedValue?.nationalNumber);
    // eslint-disable-next-line
  }, []);

  // const { reset, clear } = useTimeout(
  //   setWaitForResendingOtp(false),
  //   THIRTY_SECONDS
  // );
  // const debouncedCallback = useDebouncedCallback(
  //   setWaitForResendingOtp(false),
  //   THIRTY_SECONDS
  // );
  // resend otp
  const resendOtpHandler = () => {
    // Set false
    setIsNetworkError(false);
    setIsSending(false);
    setResendOtpSuccess(false);
    setOtpNotSentError(false);
    setIncorrectOtpError(false);

    // Set to true
    // setWaitForResendingOtp(true);
    // set the above value to false after THIRTY_SECONDS
    // debouncedCallback();
    // reset();

    if (userAgent && userAgent === USER_AGENT_RIDER) {
      setIsSending(true);
      fetch(`${BASE_URL}${RIDER_GET_OTP}`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          phone_number: onlyNumber,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          if (data && data.Code === CODE_200) {
            // console.log(data);
            setResendOtpSuccess(true);
            return;
          } else {
            setOtpNotSentError(true);
            return;
          }
        })
        .catch((e) => {
          setIsNetworkError(true);
          console.log("OTP error network error", e);
        })
        .finally(() => {
          setIsSending(false);
        });
    }

    if (userAgent && userAgent === USER_AGENT_DRIVER) {
      // resendOtpDriver();
      setIsSending(true);
      fetch(`${BASE_URL}${DRIVER_GET_OTP}`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          phone_number: onlyNumber,
          country_code: countryCode,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          if (data && data.Code === CODE_200) {
            // console.log(data);
            setResendOtpSuccess(true);
            return;
          } else {
            setOtpNotSentError(true);
            return;
          }
        })
        .catch((e) => {
          setIsNetworkError(true);
          console.log("Driver get otp network error", e);
        })
        .finally(() => {
          setIsSending(false);
        });
    }
  };

  // verify otp and navigate
  const checkOtpAndNavigate = () => {
    setIsSending(true);
    fetch(`${BASE_URL}${DRIVER_LOGIN_VERIFY_WITH_OTP}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        phone_number: onlyNumber,
        otp: otp,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        setIncorrectOtpError(true);
        throw response;
      })
      .then((data) => {
        // console.log("Data we receive", data);
        if (data && data.hasOwnProperty(ACCESS_TOKEN_COOKIE_NAME)) {
          // console.log("DRIVER_LOGIN_VERIFY_WITH_OTP data", data);
          Cookies.set(ACCESS_TOKEN_COOKIE_NAME, data.access, {
            expires: SEVEN_DAYS,
          });
          const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
          // console.log(accessToken);
          return fetch(`${BASE_URL}${DRIVER_PROFILE_DETAILS}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            method: "GET",
          })
            .then((response) => {
              if (response.status === 200) {
                // console.log("Fetching driver details");
                return response.json();
              } else if (response.status === 500) {
                // console.log("No driver details found Code 500 ");
                navigateToDriverCompleteProfile();
              }

              throw response;
            })
            .then((data) => {
              // remember to change the property name checking to a constant
              if (
                data &&
                data.hasOwnProperty("first_name")
                // data?.user_id
              ) {
                setDriverName(data?.first_name);
                // console.log(data);
                localStorage.setItem(USER_LOCAL_STORAGE, JSON.stringify(data));
                //fetch call to send fcm token along with id
                return fetch(`${BASE_URL}${DRIVER_GET_FCM_TOKEN}`, {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                  },
                  method: "POST",
                  body: JSON.stringify({
                    user_id: data?.details,
                  }),
                })
                  .then((response) => {
                    if (response.ok) {
                      return response.json();
                    }
                    throw response;
                  })
                  .then((fcmdata) => {
                    // console.log("FCM data", fcmdata);
                    if (Array.isArray(fcmdata) && fcmdata.length > 0) {
                      const tokenObj = fcmdata[0];
                      if (
                        typeof tokenObj === "object" &&
                        tokenObj !== null &&
                        tokenObj.hasOwnProperty("fcm")
                      ) {
                        // const fcmToken = tokenObj.fcm;
                        //response ok -> an fcm present
                        // check if fcm mathces with current fcm
                        // console.log("Fcm token from server", tokenObj);
                        // console.log("Fcm token from browser", fcmToken);
                        if (
                          tokenObj?.fcm === fcmToken &&
                          tokenObj?.fcm.toString().length > 0
                        ) {
                          // console.log("Fcm token mathc from server");
                          navigateToDriverHomeViewAllBidsHandler();
                          return;
                        } else if (fcmToken.toString().length > 0) {
                          // console.log(
                          //   "FCM token does not match from server sending new token to backend"
                          // );
                          // using tokenObj?.user_id to send the token to server
                          // the data above does not has a user_id property
                          // instead has a details property which is an unknown variable
                          return fetch(`${BASE_URL}${DRIVER_FCM_TOKEN}`, {
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${accessToken}`,
                            },
                            method: "POST",
                            body: JSON.stringify({
                              user_id: tokenObj?.user_id,
                              fcm: fcmToken,
                            }),
                          }).then((response) => {
                            if (response.ok) {
                              // console.log(
                              //   "Fcm token sending response.ok = true, Proceeding to driver home"
                              // );
                              navigateToDriverHomeViewAllBidsHandler();
                              return response.json();
                            }
                            throw response;
                          });
                        } else {
                          // console.log("Not sending fcm token...");
                          navigateToDriverHomeViewAllBidsHandler();
                        }
                      }
                    } else if (fcmToken.toString().length > 0) {
                      // console.log(
                      //   "Recieved an empty array, instead got unknown data type - ",
                      //   fcmdata
                      // );

                      // navigateToRiderCompleteProfile();
                      return fetch(`${BASE_URL}${DRIVER_FCM_TOKEN}`, {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${accessToken}`,
                        },
                        method: "POST",
                        body: JSON.stringify({
                          user_id: data?.details,
                          fcm: fcmToken,
                        }),
                      })
                        .then((response) => {
                          if (response.ok) {
                            // console.log(
                            //   "Navigating to post ride to after no fcm reposnse from server but filled field of customer details"
                            // );
                            return response.json();
                          }
                          throw response;
                        })
                        .then((fcm2data) => {
                          // console.log("Second fcm data", fcm2data);
                          navigateToDriverHomeViewAllBidsHandler();
                        });
                    } else {
                      // console.log("Not sending fcm token...");
                      navigateToDriverHomeViewAllBidsHandler();
                    }
                  })
                  .catch((e) => {
                    setIsNetworkError(true);
                    console.log("VerifyOtp", e);
                  })
                  .finally(() => {
                    // setIsLoadingError(false);
                  });
              } else {
                // console.log(
                //   "All use cases went wrong in driver side , fill the profile instead"
                // );
                navigateToDriverCompleteProfile();
              }
            })
            .catch((e) => {
              setIsNetworkError(true);
              console.log("Network error", e);
            })
            .finally(() => {
              setIsSending(false);
            });
        }
      })
      .catch((e) => {
        setIsNetworkError(true);
        console.log("Network error", e);
      })
      .finally(() => {
        setIsSending(false);
      });
    // }
  };

  return (
    <>
      <RiderDriverSign>
        <AnimationLayout>
          <div className={styles.children}>
            <TextHeadingSign displayText="Verify your mobile number" />
            <TextParaSign displayText="Enter the 4-digit code sent to you." />
            <OtpInput
              disabledStyle
              isInputNum
              value={otp}
              onChange={handleChange}
              numInputs={4}
              separator={""}
              className={styles.box}
              inputStyle={styles.inputBox}
            />
            {isSending && (
              <div>
                <ErrorInNumber displayText="Sending..." />
              </div>
            )}
            {incorrectOtpError && (
              <div>
                <ErrorInNumber displayText="OTP not verified" />
              </div>
            )}
            {otpNotSentError && (
              <div>
                <ErrorInNumber displayText="Resend failed" />
              </div>
            )}
            {resendOtpSuccess && (
              <div>
                <ErrorInNumber displayText="OTP Sent" />
              </div>
            )}
            {isNetworkError && (
              <div>
                <ErrorInNumber displayText="Network /server error" />
              </div>
            )}

            {/* {waitForResendingOtp && (
              <LoginSignSwitchLine
                displayQuestion="Wait for 30 seconds for resending OTP"
                displayFunctionText=" "
                pageSwitchFunction={() => {}}
              />
            )} */}

            <LoginSignSwitchLine
              displayQuestion="Resend code via SMS"
              displayFunctionText="Click here."
              pageSwitchFunction={resendOtpHandler}
            />

            <Button
              displayText="Continue"
              onClickFunction={checkOtpAndNavigate}
            />
          </div>
        </AnimationLayout>
      </RiderDriverSign>
    </>
  );
};

export default VerifyOtpRiderDriver;
