import { createSlice } from "@reduxjs/toolkit";

const uiElementSlice = createSlice({
  name: "uiElement",
  initialState: {
    logsDownloadDialogBox: false,
  },
  reducers: {
    openLogsDownloadDialogBox(state) {
      state.logsDownloadDialogBox = true;
    },

    closeLogsDownloadDialogBox(state) {
      state.logsDownloadDialogBox = false;
    },
  },
});

export const uiElementActions = uiElementSlice.actions;
export default uiElementSlice;
