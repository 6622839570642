// import React, { useState } from "react";
// eslint-disable-next-line
import { useLogoutHandler } from "../../hooks";
import styles from "./UpdateRequestModal.module.css";

const UpdateRequestModal = ({
  openBool = false,
  openBoolFunction = () => {},
  playStoreUpdate = false,
}) => {
  const logoutFunction = useLogoutHandler();
  return (
    <>
      {openBool && (
        <div className={styles.customModal}>
          <div className={styles.modalBackdrop}>
            <div className={styles.visibleModal}>
              <h3 className={styles.modalMainHeading}>
                {playStoreUpdate
                  ? "Play store पर जाएं और App अपडेट करें"
                  : "App बंद करें फिर ओपन करें. !"}
              </h3>
              <p className={styles.modalSubHeading}>
                {playStoreUpdate
                  ? "Please update from play store"
                  : "Please close and restart your app."}
              </p>
              {playStoreUpdate && (
                <div
                  onClick={() => {
                    logoutFunction();
                  }}
                  href="https://play.google.com/store/apps/details?id=in.taxiwars.driver.twa"
                  className={styles.playStoreButton}
                  target="_blank"
                  rel="noreferrer"
                >
                  Go to playstore
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateRequestModal;
