export const ONE_MILLISECOND = 1;
export const ONE_SECOND = 1000;
export const HUNDRED_MILLISECONDS = 100;
export const THIRTY_SECONDS = 30 * 1000;
export const SIXTY_SECONDS = 60 * 1000;
export const ONE_MINUTE = 60 * 1000;
export const SEVEN_DAYS_IN_SECONDS = 604800;
export const SEVEN_DAYS = 30;
export const ALL_IN_APP_NOTIFICATION_DEFAULT_TIME = 20000;

// export const LOCATION_UPDATE_TIME_IN_MILLI_SECOND = parseInt(
//   process.env.REACT_APP_LOCATION_UPDATE_TIME_IN_MILLI_SECOND
// );

//TODO Comment after env addition
export const LOCATION_UPDATE_TIME_IN_MILLI_SECOND = 40 * ONE_SECOND;
