import { createSlice } from "@reduxjs/toolkit";

const selectedRide = "selectedRide";
const selectedRideDetails = "selectedRideDetails";

const driverAllRideSlice = createSlice({
  name: "driverAllRide",
  initialState: {
    rides: [],
    pendingRides: [],
    bidConfirmedRides: [],
    selectedRide: localStorage.getItem(selectedRide) || "",
    selectedRideDetails:
      JSON.parse(localStorage.getItem(selectedRideDetails)) || {},
  },
  reducers: {
    /**
     *
     * @param {accepts a list of rides} action
     *
     * action = [];
     */
    updateRides(state, action) {
      state.rides = [...action.payload];
    },

    updatePendingRides(state, action) {
      state.pendingRides = [...action.payload];
    },

    updateBidConfirmedRides(state, action) {
      state.bidConfirmedRides = [...action.payload];
    },

    setSelectedRide(state, action) {
      state.selectedRide = action.payload;

      // persist
      localStorage.setItem(selectedRide, action.payload);
      const selectedRideId = action.payload;
      if (selectedRideId) {
        // Assumption every ride id generated is unique
        // Therefore rides are
        const filteredRide = state.rides.find(
          (ride) => ride.ride_id === selectedRideId
        );

        const filteredRideFromPending = state.pendingRides.find(
          (pendingRide) => pendingRide.ride_id === selectedRideId
        );

        const filteredRideFromBidConfirmed = state.bidConfirmedRides.find(
          (bidConfirmedRide) => bidConfirmedRide.ride_id === selectedRideId
        );

        state.selectedRideDetails =
          filteredRide ||
          filteredRideFromPending ||
          filteredRideFromBidConfirmed ||
          {};

        localStorage.setItem(
          selectedRideDetails,
          JSON.stringify(state.selectedRideDetails)
        );
      }
    },
  },
});

export const driverAllRideActions = driverAllRideSlice.actions;
export default driverAllRideSlice;
