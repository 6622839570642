import React, { useEffect, useState } from "react";
// eslint-disable-next-line
import styles from "./SosButton.module.css";
import { motion, useCycle } from "framer-motion";
import ErrorInNumber from "../ErrorInNumber/ErrorInNumber";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../constants/storageConstants";
import Cookies from "js-cookie";
import {
  BASE_URL,
  CODE_200,
  CODE_300,
  DRIVER_SOS,
} from "../../constants/apiConstants";
import { toast } from "react-toastify";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const SosButton = ({
  styleOptions = {
    leftAligned: false,
    rightAligned: true,
  },
  currentRideId,
}) => {
  const [emergencyNumberExist, setEmrgencyNumberexist] = useState(false);
  const [emergencyNumber, setEmergencyNumber] = useState("");
  const [emergencyNumberInputError, setEmergencyNumberInputError] =
    useState(false);
  const [open, cycleOpen] = useCycle(false, true);

  const sendSosHandlerStepOne = async () => {
    if (!emergencyNumberExist && emergencyNumber < 10) {
      emergencyNumberInputError(true);
      return;
    }

    toast.success(" Sending SOS", {
      toastId: "sos",
      autoClose: false,
      icon: <LoadingSpinner />,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const sendUrl = `${BASE_URL}${DRIVER_SOS}`;

    const newSendOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        sos_num: `${emergencyNumber}`,
        ride_id: currentRideId,
      }),
    };

    const response = await fetch(sendUrl, newSendOptions);

    if (!response.ok) {
      toast.update("sos", {
        render: "Network Error",
        type: toast.TYPE.ERROR,
        autoClose: 10000,
        closeButton: true,
        icon: null,
        // transition: Slide,
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    toast.update("sos", {
      render: "  SOS Sent.",
      type: toast.TYPE.SUCCESS,
      autoClose: 20000,
      closeButton: true,
      icon: null,
      // transition: Slide,
      position: toast.POSITION.TOP_CENTER,
    });
    cycleOpen();

    toast.update("sos", {
      render: "SOS Sent",
      type: toast.TYPE.SUCCESS,
      autoClose: 10000,
      closeButton: true,
      icon: null,
      // transition: Slide,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  useEffect(() => {
    const checkMobileNumber = async () => {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      const sendUrl = `${BASE_URL}${DRIVER_SOS}`;
      const sendOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(sendUrl, sendOptions);
      if (!response.ok) {
        console.log("SosButton.js, response not ok: ", response);
        return;
      }

      const data = await response.json();

      if (data?.code === CODE_300) {
        console.log("SosButton.js, No mobile number present");
        return;
      }

      if (data?.code === CODE_200) {
        setEmergencyNumber(data?.number);
        setEmrgencyNumberexist(true);
      }
    };
    checkMobileNumber();
  }, []);

  return (
    <div
      className={`${styles.sosButton} 
      ${styleOptions?.leftAligned && styles.left}  
      ${styleOptions?.rightAligned && styles.right}`}
    >
      <div className={`${styles.relativeBox}`}>
        {!open && (
          <motion.button
            // onClick={sosButtonOnClick}
            onClick={() => {
              cycleOpen();
            }}
            className={`${styles.floatingActionButton}`}
          >
            {"SOS"}
          </motion.button>
        )}
        {open && (
          <motion.div className={`${styles.sosBox} `}>
            <>
              <div className={styles.hazardSign}>&#9888;</div>
              <p className={styles.onlyForEmergencies}>Only for emergencies</p>
              <p className={styles.headingPara}>
                Taxiwars support team and your emergency contact will receive
                following information.
              </p>
              <ol className={styles.olList}>
                <li>Your ride details</li>
                <li>Rider details</li>
              </ol>
              {emergencyNumberExist && (
                <>
                  <p className={styles.numberPresentText}>
                    Your emergency contact is {emergencyNumber}
                  </p>
                </>
              )}
              {!emergencyNumberExist && (
                <>
                  <p className={styles.headingInfo}>
                    We do not have your emergency contact. Please enter your
                    number
                  </p>
                  <div className={styles.numberInputBoxCombined}>
                    {/* <input
                    className={`${styles.countryCodeInputBox} ${styles.inputBoxCommon}`}
                    type="tel"
                    value={emergencyNumberCountryCode}
                    onChange={(e) => {
                      const code = e.target.value;
                      if (code.length <= 3) {
                        setEmergencyNumberCountryCode(code);
                      } else {
                        // Error handling
                      }
                    }}
                  /> */}
                    <input
                      className={`${styles.numberInputBox} ${styles.inputBoxCommon}`}
                      type="tel"
                      value={emergencyNumber}
                      onChange={(e) => {
                        const number = e.target.value;
                        if (number.length <= 10) {
                          setEmergencyNumberInputError(false);
                          setEmergencyNumber(number);
                        } else {
                          setEmergencyNumberInputError(true);
                        }
                      }}
                    />
                  </div>

                  {emergencyNumberInputError && (
                    <div className={styles.errorText}>
                      <ErrorInNumber displayText="Enter correct number" />
                    </div>
                  )}
                </>
              )}
              <div className={styles.buttonsRow}>
                <div
                  onClick={sendSosHandlerStepOne}
                  className={`${styles.topButton} ${styles.button}`}
                >
                  SEND
                </div>
                <div
                  onClick={cycleOpen}
                  className={`${styles.bottomButton} ${styles.button}`}
                >
                  CANCEL
                </div>
              </div>
            </>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default SosButton;
