import React from "react";
import { images } from "../../images";
// eslint-disable-next-line
import styles from "./RiderNamePickupDropCancelCard.module.css";

const RiderNamePickupDropCancelCard = ({
  fullName,
  pickupAddressText,
  dropAddressText,
  phoneNumber,
  endRide = false,
  isShowAdvance = false,
  scheduleDate = "2023-11-01",
  scheduleTime = "12:45:12",
}) => {
  // const cancelRideButtonHandler = () => {
  //   console.log("Function to be implemented");
  // };
  // function convertDateFormat(scheduleDate) {
  //   const [year, month, day] = scheduleDate.split("-");
  //   if ((year || month || day) === undefined) return;
  //   return `${day}-${month}-${year}`;
  // }
  // function convertTo12HourFormat(time) {
  //   const [hours, minutes, seconds] = time.split(":");
  //   let period = "AM";
  //   let hour = parseInt(hours, 10);

  //   if (hour >= 12) {
  //     period = "PM";
  //     if (hour > 12) {
  //       hour -= 12;
  //     }
  //   }

  //   return `${hour}:${minutes} ${period}`;
  // }

  return (
    <div className={styles.riderNamePickupDropCancelCard}>
      <div className={styles.upperPart}>
        <div className={styles.writtenContent}>
          <div className={styles.riderNameArrivingTime}>
            <p>{fullName}</p>
            {!endRide && <p>Waiting for you at pickup point</p>}
          </div>
          <div className={styles.pickupDropPoint}>
            <p className={styles.pickup}>
              <span className={`${styles.dot} ${styles.green}`}></span>
              {`"Pickup -" ${pickupAddressText?.slice(0, 40)}`}
            </p>
            <p className={styles.drop}>
              <span className={`${styles.dot} ${styles.red}`}></span>
              {`"Drop -" ${dropAddressText?.slice(0, 40)}`}
            </p>
          </div>
        </div>
        <div className={styles.imageContent}>
          <img
            className={styles.userGeneric}
            src={images.userGeneric}
            alt="user profile"
          />
        </div>
      </div>
      <hr className={styles.hrDivider} />
      <div className={styles.lowerPart}>
        <button>
          <a
            style={{ textDecoration: "none", color: "#000" }}
            href={`tel:${phoneNumber}`}
          >
            Customer Mobile - {phoneNumber}{" "}
          </a>
        </button>
        {/* <hr /> */}
        <div></div>
        <div></div>
        {/* <button onClick={cancelRideButtonHandler}>Cancel Ride</button> */}
      </div>
      {/* {isShowAdvance && (
        <div
          style={{
            color: "black",
            fontSize: "1.6rem",
            fontWeight: "bold",
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            gap: "2rem",
          }}
          className=""
        >
          <p className="">Date:&nbsp;&nbsp;{convertDateFormat(scheduleDate)}</p>
          <p className="">
            Time&nbsp;&nbsp;{convertTo12HourFormat(scheduleTime)}
          </p>
        </div>
      )} */}
    </div>
  );
};

export default RiderNamePickupDropCancelCard;
