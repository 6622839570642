import React, { useEffect, useState } from "react";
import { ACCESS_TOKEN_COOKIE_NAME } from "../constants/storageConstants";
import {
  BASE_URL,
  GET_ON_DUTY_STATUS,
  POST_ON_DUTY_STATUS,
} from "../constants/apiConstants";
import Cookies from "js-cookie";

const useOnDutyHook = () => {
  //
  const [isOnDuty, setIsOnDuty] = useState({
    status: "loading",
    value: null,
  });

  // 0 means true
  // 1 means false
  // 2 means recieve status
  const checkDutySatus = async () => {
    setIsOnDuty((prev) => ({ ...prev, status: "loading" }));
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    try {
      const response = await fetch(`${BASE_URL}${GET_ON_DUTY_STATUS}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          is_on_duty_boolean: "2",
        }),
      });

      // console.log("ON DUTY", response);
      if (response.ok) {
        const data = await response.json();
        console.log("ON DUTY data", data);

        if (data?.is_on_duty_boolean === true) {
          setIsOnDuty((prev) => ({ ...prev, value: true, status: "success" }));
          return;
        }

        if (data?.is_on_duty_boolean === false) {
          setIsOnDuty((prev) => ({ ...prev, value: false, status: "success" }));
          return;
        }

        // setIsOnDuty((prev) => ({ ...prev, status: "failed" }));
        return;
      }
      setIsOnDuty((prev) => ({ ...prev, status: "failed" }));
    } catch (error) {
      console.log(error);
      setIsOnDuty((prev) => ({ ...prev, status: "failed" }));
    }
  };

  useEffect(() => {
    checkDutySatus();
  }, []);

  const changeIsOnDuty = async () => {
    // console.log(e.target.checked);
    setIsOnDuty((prev) => ({ ...prev, status: "loading" }));
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const response = await fetch(`${BASE_URL}${POST_ON_DUTY_STATUS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        is_on_duty_boolean: isOnDuty.value === true ? "1" : "0",
      }),
    });

    if (response.ok) {
      setIsOnDuty({
        value: !isOnDuty.value,
        status: "success",
      });
      return;
    }

    setIsOnDuty((prev) => ({ ...prev, status: "failed" }));
  };
  //

  return { isOnDuty, changeIsOnDuty };
};

export { useOnDutyHook };
