import React from "react";
import {
  ArrowBackButton,
  Button,
  ErrorInNumber,
  InputBox,
  InputOptionBoxForCityDriver,
  InputWithLabel,
  RiderDriverSign,
  TextHeadingSign,
} from "../../../components";
// eslint-disable-next-line
import styles from "./DriverVehicleDetails.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  DRIVER_PROFILE_ADDRESS_DETAILS_ROUTE,
  DRIVER_PROFILE_DOCUMENT_UPLOAD_ROUTE,
} from "../../../constants/routeConstants";
import { useNavigateOnEvent } from "../../../hooks";
import { driverProfileActions } from "../../../store/slice";
import { useState } from "react";
import { toast } from "react-toastify";

const DriverVehicleDetails = () => {
  const dispatch = useDispatch();

  const navigateToDocumentUpload = useNavigateOnEvent(
    DRIVER_PROFILE_DOCUMENT_UPLOAD_ROUTE,
    false
  );

  const navigateBackToAddressDetails = useNavigateOnEvent(
    DRIVER_PROFILE_ADDRESS_DETAILS_ROUTE,
    true
  );

  const [isError, setIsError] = useState({
    drivingLicenseNumber: false,
    vehicleRegistrationNumber: false,
    vehicleName: false,
    files: false,
    vehicleType: false,
  });

  const drivingLicenseNumber = useSelector(
    (state) => state.driverProfile.drivingLicenseNumber
  );
  const vehicleRegistrationNumber = useSelector(
    (state) => state.driverProfile.vehicleRegistrationNumber
  );
  const vehicleName = useSelector((state) => state.driverProfile.vehicleName);

  const vehicleType = useSelector((state) => state.driverProfile.vehicleType);

  const vehicleImagesFileLocation = useSelector(
    (state) => state.driverProfile.vehicleImagesFileLocation
  );
  const vehicleImageName = useSelector(
    (state) => state.driverProfile.vehicleImageName
  );

  // const handleVehicleImages = (files) => {
  //   if (Array.isArray(files) && files.length > 0) {
  //     const urls = files.map((eachImage) => {
  //       return URL.createObjectURL(eachImage);
  //     });
  //     dispatch(driverProfileActions.updateVehicleImages(urls));
  //   }
  // };

  const vehicleImageChangeHandler = (e) => {
    // console.log("Vehicle images event files", e.target.files);
    // console.log("Vehicle imge file name - ", e.target.files[0]?.name);
    // console.log(e.target.files.length);
    toast.dismiss();
    if (e.target.files.length === 0) {
      toast.dismiss();
      // toast.info("No file selected");
      return;
    }
    dispatch(
      driverProfileActions.updateVehicleImageName(e.target.files[0]?.name)
    );
    dispatch(
      driverProfileActions.updateVehicleImagesFileLocation(
        URL.createObjectURL(e.target.files[0])
      )
    );
  };

  const drivingLicenseNumberChangeHandler = (e) => {
    dispatch(driverProfileActions.updateDrivingLicenseNumber(e.target.value));
    setIsError((prev) => ({ ...prev, drivingLicenseNumber: false }));
  };

  const vehicleRegistrationNumberCHnagelHandler = (e) => {
    dispatch(
      driverProfileActions.updateVehicleRegistrationNumber(e.target.value)
    );
    setIsError((prev) => ({ ...prev, vehicleRegistrationNumber: false }));
  };

  const vehicleNameChangeHandler = (e) => {
    dispatch(driverProfileActions.updateVehicleName(e.target.value));
    setIsError((prev) => ({ ...prev, vehicleName: false }));
  };

  const vehicleTypeChangeHandler = (e) => {
    dispatch(driverProfileActions.updateVehicleType(e.target.value));
    setIsError((prev) => ({ ...prev, vehicleType: false }));
  };

  const checkValidateAndNavigate = () => {
    setIsError(false);

    // validation
    if (drivingLicenseNumber.trim().length === 0) {
      setIsError((prev) => ({ ...prev, drivingLicenseNumber: true }));
      return;
    }
    if (vehicleRegistrationNumber.trim().length === 0) {
      setIsError((prev) => ({ ...prev, vehicleRegistrationNumber: true }));
      return;
    }
    if (vehicleName.trim().length === 0) {
      setIsError((prev) => ({ ...prev, vehicleName: true }));
      return;
    }

    if (vehicleType.trim().length === 0) {
      setIsError((prev) => ({ ...prev, vehicleType: true }));
      return;
    }
    if (!vehicleImagesFileLocation) {
      setIsError((prev) => ({ ...prev, files: true }));
      return;
    }

    // console.log(
    //   "Vehicle details complete, - navigating to upload document screen"
    // );
    navigateToDocumentUpload();
  };
  return (
    <>
      <RiderDriverSign back driver>
        <div className={styles.children}>
          <TextHeadingSign displayText="Your Vehicle Details" />
          {/* Generic Photo upload */}
          <InputBox
            type="text"
            idAndName="drivingLicenseNumber"
            placeholderText="Enter Driving License Number"
            value={drivingLicenseNumber}
            onChange={drivingLicenseNumberChangeHandler}
          />
          {isError.drivingLicenseNumber && (
            <ErrorInNumber displayText="Driving license cannot be empty" />
          )}
          <InputBox
            type="text"
            idAndName="vehicleRegistration"
            placeholderText="Enter vehicle registration number"
            value={vehicleRegistrationNumber}
            onChange={vehicleRegistrationNumberCHnagelHandler}
          />

          {isError.vehicleRegistrationNumber && (
            <ErrorInNumber displayText="Vehicle number cannot be empty" />
          )}
          <InputBox
            type="text"
            idAndName="vehicleName"
            placeholderText="Enter vehicle Name"
            value={vehicleName}
            onChange={vehicleNameChangeHandler}
          />
          {isError.vehicleName && (
            <ErrorInNumber displayText="Vehicle name cannot be empty" />
          )}
          <InputOptionBoxForCityDriver
            disabledOption="Select Vehcile Type"
            idAndName="vehicleDetails"
            onChange={vehicleTypeChangeHandler}
            // defaultValue={{}}
            options={["Auto", "Taxi-Mini", "Taxi-Sedan", "Taxi-SUV"]}
          />
          {isError.vehicleType && (
            <ErrorInNumber displayText="Please select vehicle type" />
          )}
          <InputWithLabel
            labelDisplayText="Vehicle image"
            forIdAndName="vehicleImages"
            value={vehicleImageName}
            handleChange={vehicleImageChangeHandler}
          />
          {isError.files && (
            <ErrorInNumber displayText="Please select an image" />
          )}

          <Button
            displayText="Continue"
            onClickFunction={checkValidateAndNavigate}
          />
        </div>
        <ArrowBackButton onClickFunction={navigateBackToAddressDetails} />
      </RiderDriverSign>
    </>
  );
};

export default DriverVehicleDetails;
