import { useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GOOGLE_MAPS_API_KEY } from "../../../constants/apiConstants";
import { useNavigateOnEvent } from "../../../hooks";
import {
  ADV_DRIVER_BID_ACCEPTED_START_RIDE_ROUTE,
  ADV_DRIVER_PLACE_A_BID_ROUTE,
  ADV_DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE,
} from "../../../constants/routeConstants";
import { driverAllRideAdvActions } from "../../../store/slice";
import { images } from "../../../images";
import styles from "./SingleBidDetailCard.module.css";
import CurrentStateTimePriceBids from "./CurrentStateTimePriceBids";
import DistancePickupTimeOriginDestination from "./DistancePickupTimeOriginDestination";

const SingleBidDetailCard = ({
  rideId,
  customerId,
  distance = "NA",
  duration = "NA",
  originAddress,
  removeGoToDetailsArrow = false,
  rideTime = "23:54:05",
  originAddressText = "NA",
  destinationAddressText = "NA",
  bidPlaced = false,
  bidAcceptedBool = false,
  numberOfBids = 1,
  driverBidAmount = 50,
  driverCurrentLocation,
  rideInProgressBool = false,
  rideActiveBool = false,
  rideInPendingBool = false,
  boolUpliftDirectionResponse = false,
  upliftDirectionResponse,
  upliftDirectionServiceState,
  scheduleTime = "",
  scheduleDate = "",
  scheduleTimeN = "",
}) => {
  const dispatch = useDispatch();

  const [pickupDistance, setPickupDistance] = useState("");
  const [pickupTime, setPickupTime] = useState("");

  const [directionResponseResult, setDirectionResponseResult] = useState("");

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const [driectionServiceState, setDriectionServiceState] = useState();

  useEffect(() => {
    if (isLoaded) {
      // eslint-disable-next-line no-undef
      const directionService = () => new google.maps.DirectionsService();
      setDriectionServiceState(directionService);
    }
  }, [isLoaded]);

  useEffect(() => {
    const [pickupLat, pickupLng] = originAddress.split(",");

    const latLng = { lat: parseFloat(pickupLat), lng: parseFloat(pickupLng) };

    const calculateRoute = async () => {
      if (!driectionServiceState) return;
      const resultsDistanceDuration = await driectionServiceState.route({
        origin: {
          lat: driverCurrentLocation.latitude,
          lng: driverCurrentLocation.longitude,
        },
        destination: latLng,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      });
      // console.log(resultsDistanceDuration);
      if (boolUpliftDirectionResponse) {
        setDirectionResponseResult(resultsDistanceDuration);
      }
      setPickupDistance(
        resultsDistanceDuration.routes[0].legs[0].distance.text
      );
      setPickupTime(resultsDistanceDuration.routes[0].legs[0].duration.text);
    };

    calculateRoute();
  }, [driectionServiceState, driverCurrentLocation, originAddress]);

  const navigateToMeetAtPickupPoint = useNavigateOnEvent(
    ADV_DRIVER_BID_ACCEPTED_START_RIDE_ROUTE
  );

  const navigateToInProgressEndRide = useNavigateOnEvent(
    ADV_DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE
  );

  const [displayTime, setDisplayTime] = useState("");
  useEffect(() => {
    if (scheduleTime === "now") {
      const time = new Date();
      const options = { hour: "numeric", minute: "numeric", hour12: true };
      const formattedTime = time.toLocaleTimeString("en-US", options);

      setDisplayTime(formattedTime);
      return;
    }
    const now = new Date();
    const [hours, minutes, seconds] = scheduleTime.split(":");
    const time = new Date();
    time.setHours(hours);
    time.setMinutes(minutes);
    time.setSeconds(seconds);
    //comparing
    const isToday =
      now.getDate() === time.getDate() &&
      now.getMonth() === time.getMonth() &&
      now.getFullYear() === time.getFullYear();
    //formatting time
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedTime = time.toLocaleTimeString("en-US", options);
    // if today
    if (isToday) {
      console.log("SingleBidDetailCard.js, Formatted time", formattedTime);
      setDisplayTime(formattedTime);
    }
  }, [scheduleTime]);

  // const navigateToMeetAtPickupPointHandler = () => {
  //   dispatch(driverAllRideAdvActions.setSelectedRide(rideId));
  //   navigateToMeetAtPickupPoint();
  // };

  const navigateToMeetAtPickupPointHandler = useCallback(() => {
    dispatch(driverAllRideAdvActions.setSelectedRide(rideId));
    navigateToMeetAtPickupPoint();
  }, [dispatch, rideId]);

  // const navigateToInProgressEndRideHandler = () => {
  //   dispatch(driverAllRideAdvActions.setSelectedRide(rideId));
  //   navigateToInProgressEndRide();
  // };

  const navigateToInProgressEndRideHandler = useCallback(() => {
    dispatch(driverAllRideAdvActions.setSelectedRide(rideId));
    navigateToInProgressEndRide();
  }, [dispatch, rideId, navigateToInProgressEndRide]);

  //
  const onClickHandler = () => {
    console.log("SingleBidDetailCard.js, Value of bidAcceptedBool: ");
    console.log(bidAcceptedBool);
    console.log("SingleBidDetailCard.js, Value of rideInProgressBool: ");
    console.log(rideInProgressBool);

    if (bidAcceptedBool && !rideInProgressBool) {
      console.log("Navigate to meet at pickup point screen...");
      navigateToMeetAtPickupPointHandler();
      return;
    }

    if (!bidAcceptedBool && rideInProgressBool) {
      console.log("SingleBidDetailCard.js, Navigate to In progress screen...");
      navigateToInProgressEndRideHandler();
      return;
    }

    if (rideActiveBool || rideInPendingBool) {
      // we removed this to prevent in progress going back to pending
      // advised to have this implemented in backend
      // so do not uncomment this
      // console.log(
      //   "SingleBidDetailCard.js, Navigate to View ride details screen..."
      // );
      // dispatch(driverAllRideAdvActions.setSelectedRide(rideId));
      // navigateToViewRideDetails();
    }
  };

  useEffect(() => {
    if (bidAcceptedBool && !rideInProgressBool) {
      console.log(
        "SingleBidDetailCard.js, Automatic Navigate to meet at pickup point screen..."
      );
      dispatch(driverAllRideAdvActions.updateBidConfirmedRides([]));
      navigateToMeetAtPickupPointHandler();
      // if (!navigator.serviceWorker.controller) {
      //   dispatch(driverAllRideAdvActions.updateBidConfirmedRides([]));
      //   navigateToMeetAtPickupPointHandler();
      //   return;
      // }

      // const messageChannel = new MessageChannel();
      // navigator.serviceWorker.controller.postMessage(
      //   {
      //     type: "INIT_PORT",
      //   },
      //   [messageChannel.port2]
      // );

      // messageChannel.port1.onmessage = function (event) {
      //   console.log("event in main thread", event);
      //   if (event.data.type === "PORT_INITIALISED") {
      //     navigator.serviceWorker.controller.postMessage({
      //       type: "START_BACKGROUND_LOCATION_UPDATE",
      //     });
      //     dispatch(driverAllRideAdvActions.updateBidConfirmedRides([]));
      //     navigateToMeetAtPickupPointHandler();
      //   }
      // };

      return;
    }

    // redundant , possible need while scheduling
    // if (!bidAcceptedBool && rideInProgressBool) {
    //   console.log("Navigate to In progress screen...");
    //   navigateToInProgressEndRideHandler();
    //   return;
    // }
  }, [
    bidAcceptedBool,
    rideInProgressBool,
    dispatch,
    navigateToMeetAtPickupPointHandler,
  ]);

  useEffect(() => {
    if (boolUpliftDirectionResponse && directionResponseResult) {
      upliftDirectionResponse(directionResponseResult);
    }
  }, [
    boolUpliftDirectionResponse,
    upliftDirectionResponse,
    directionResponseResult,
  ]);

  useEffect(() => {
    if (boolUpliftDirectionResponse && driectionServiceState) {
      upliftDirectionServiceState(driectionServiceState);
    }
  }, [
    driectionServiceState,
    upliftDirectionServiceState,
    boolUpliftDirectionResponse,
  ]);

  return (
    <div
      className={`${styles.singleBidDetailCard} ${
        bidPlaced && styles.bidPlacedWaiting
      } ${bidAcceptedBool && styles.bidAccepted} ${
        rideInProgressBool && styles.bidAccepted
      } ${rideActiveBool && styles.animationFlash}
          `}
      onClick={onClickHandler}
    >
      {bidPlaced && (
        <p
          style={{
            fontSize: "2.2rem",
            textAlign: "center",
            color: "white",
            backgroundColor: "#fc8018",
            borderTopLeftRadius: "3px",
            borderTopRightRadius: "3px",
            marginBottom: "0.4rem",
            padding: "0.1rem 0",
            paddingTop: "0.5rem",
            fontWeight: "bold",
          }}
        >
          Wait for confirmation
        </p>
      )}
      <div
        style={{
          display: "flex",
        }}
      >
        <CurrentStateTimePriceBids
          rideId={rideId}
          rideTime={displayTime}
          scheduleDate={scheduleDate}
          scheduleTime={scheduleTimeN}
          bidPlacedWaiting={bidPlaced}
          numberOfBids={numberOfBids}
          driverBidAmount={driverBidAmount}
          bidAccepted={bidAcceptedBool || rideInProgressBool}
          rideActiveBool={rideActiveBool}
        />
        <DistancePickupTimeOriginDestination
          rideId={rideId}
          customerId={customerId}
          originAddress={originAddressText}
          destinationAddress={destinationAddressText}
          removeGoToDetailsArrow={removeGoToDetailsArrow}
          pickupPoint={pickupDistance}
          pickupTime={pickupTime}
          totalRideDistance={distance}
        />
        {/* <div></div> */}
      </div>
    </div>
  );
};

export default SingleBidDetailCard;
