// eslint-disable-next-line
import styles from "./OnDutyOffDuty.module.css";
import { LoadingSpinner } from "../../components";

const OnDutyOffDuty = ({ isOnDuty, changeIsOnDuty }) => {
  if (isOnDuty?.status === "loading") {
    return <LoadingSpinner />;
  }

  if (isOnDuty?.status === "failed") {
    return (
      <>
        {/* <p className={`${styles.onDutyText} ${styles.onDutyFailedText}`}>404</p> */}
      </>
    );
  }

  if (isOnDuty?.status === "success") {
    return (
      <div className={styles.onDutyOffDuty}>
        <p className={styles.onDutyText}>{`${
          isOnDuty.value ? "On Duty" : "Off Duty"
        } `}</p>
        <label className={styles.switch}>
          <input
            className={`${styles.switchInput} ${
              isOnDuty.value
                ? styles.switchInputBgGreen
                : styles.switchInputBgRed
            }  ${isOnDuty.value ? styles.switchInputCheckedState : ""}`}
            value={isOnDuty.value}
            // onChange={changeIsOnDuty}
            onClick={changeIsOnDuty}
            type="checkbox"
          />

          <span className={`${styles.slider} ${styles.round}`}></span>
        </label>
      </div>
    );
  }
};

export default OnDutyOffDuty;
