import Cookies from "js-cookie";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ArrowBackButton,
  Button,
  ErrorInNumber,
  InputBox,
  InputWithLabel,
  RiderDriverSign,
  TextHeadingSign,
} from "../../../components";
import {
  BASE_URL,
  DRIVER_CAR_DETAILS,
  DRIVER_FCM_TOKEN,
  DRIVER_GET_FCM_TOKEN,
  DRIVER_PROFILE_DETAILS,
} from "../../../constants/apiConstants";
import {
  useGeolocation,
  useLocalStorage,
  useNavigateOnEvent,
} from "../../../hooks";
// eslint-disable-next-line
import styles from "./DriverUploadDocuments.module.css";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  DRIVER_NAME,
  START_STOP,
  USER_LOCAL_STORAGE,
} from "../../../constants/storageConstants";
import { driverProfileActions } from "../../../store/slice";

import {
  DRIVER_PROFILE_ADDRESS_DETAILS_ROUTE,
  DRIVER_PROFILE_COMPLETE_PROFILE_ROUTE,
  DRIVER_PROFILE_VEHICLE_DETAILS_ROUTE,
  DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
} from "../../../constants/routeConstants";
import { useMemo } from "react";
import { useEffect } from "react";
import { ONE_MINUTE } from "../../../constants/timeConstants";

//image comrpression library
import imageCompression from "browser-image-compression";
import { toast } from "react-toastify";
import { generateIntentLink } from "../../../util";

const DriverUploadDocuments = () => {
  const [location, setLocation] = useState({});
  const [referralCode, setReferralCode] = useState("");

  const options = useMemo(
    () => ({
      enableHighAccuracy: true,
      maximumAge: 1 * 1000,
      timeout: 5 * 1000,
    }),
    []
  );

  const {
    loading: loadingGeoLoc,
    error: errorGeoLoc,
    data: dataGeoLoc,
  } = useGeolocation(options);

  useEffect(() => {
    if (loadingGeoLoc) return;
    if (errorGeoLoc) return;

    setLocation(dataGeoLoc);
    // console.log(dataGeoLoc);
  }, [loadingGeoLoc, errorGeoLoc, dataGeoLoc]);
  const dispatch = useDispatch();

  const [, setDriverName] = useLocalStorage(DRIVER_NAME);
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

  const navigateToDriverCompleteProfile = useNavigateOnEvent(
    DRIVER_PROFILE_COMPLETE_PROFILE_ROUTE,
    true
  );

  const navigateToAddressDetails = useNavigateOnEvent(
    DRIVER_PROFILE_ADDRESS_DETAILS_ROUTE,
    true
  );

  const navigateToVehicleDetails = useNavigateOnEvent(
    DRIVER_PROFILE_VEHICLE_DETAILS_ROUTE,
    true
  );

  const navigateToDriverHomeViewAllBids = useNavigateOnEvent(
    DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
    true
  );

  const navigateToDriverHomeViewAllBidsHandler = () => {
    const data = JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE)) || null;
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    // if (!data) {
    //   toast.error("No data found");
    //   return;
    // }
    if (true) {
      // acc to backend the detail key in the user details is the user id
      const intentLink = generateIntentLink(
        accessToken,
        data?.details?.toString(),
        data?.phone_number?.toString() || "",
        START_STOP.start
      );
      window.open(intentLink);
      navigateToDriverHomeViewAllBids();
      return;
    }
    navigateToDriverHomeViewAllBids();
  };

  const navigateBackToVehicleDetails = useNavigateOnEvent(
    DRIVER_PROFILE_VEHICLE_DETAILS_ROUTE,
    true
  );

  const [isError, setIsError] = useState({
    drivingLicenseImage: false,
    rcCardImage: false,
    idProofImage: false,
  });

  const [isSending, setSendingData] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [tapUploadDocumentCount, setTapUploadDocumentCount] = useState(0);

  const driverPhotoName = useSelector(
    (state) => state.driverProfile.driverPhotoName
  );
  const driverPhotoFileLocation = useSelector(
    (state) => state.driverProfile.driverPhotoFileLocation
  );

  const firstName = useSelector((state) => state.driverProfile.firstName);
  const lastName = useSelector((state) => state.driverProfile.lastName);
  const emailAddress = useSelector((state) => state.driverProfile.emailAddress);
  const helpNumber = useSelector((state) => state.driverProfile.helpNumber);
  const addressLineOne = useSelector(
    (state) => state.driverProfile.addressLineOne
  );
  const addressLineTwo = useSelector(
    (state) => state.driverProfile.addressLineTwo
  );
  const city = useSelector((state) => state.driverProfile.city);
  const state = useSelector((state) => state.driverProfile.state);

  const drivingLicenseNumber = useSelector(
    (state) => state.driverProfile.drivingLicenseNumber
  );
  const vehicleRegistrationNumber = useSelector(
    (state) => state.driverProfile.vehicleRegistrationNumber
  );

  const vehicleName = useSelector((state) => state.driverProfile.vehicleName);

  const vehicleType = useSelector((state) => state.driverProfile.vehicleType);

  const vehicleImageName = useSelector(
    (state) => state.driverProfile.vehicleImageName
  );
  const vehicleImagesFileLocation = useSelector(
    (state) => state.driverProfile.vehicleImagesFileLocation
  );

  const drivingLicenseImageName = useSelector(
    (state) => state.driverProfile.drivingLicenseImageName
  );
  const drivingLicenseProofFileLocation = useSelector(
    (state) => state.driverProfile.drivingLicenseProofFileLocation
  );

  const rcCardImageName = useSelector(
    (state) => state.driverProfile.rcCardImageName
  );

  const rcCardProofFileLocation = useSelector(
    (state) => state.driverProfile.rcCardProofFileLocation
  );

  const idProofImageName = useSelector(
    (state) => state.driverProfile.idProofImageName
  );
  const idProofFileLocation = useSelector(
    (state) => state.driverProfile.idProofFileLocation
  );

  const fcmToken = useSelector((state) => {
    // console.log(state.fcmToken);
    return state.fcmToken.fcm;
  });

  const drivingLicenseChangeHander = (e) => {
    // console.log(e.target.files.length);

    toast.dismiss();
    if (e.target.files.length === 0) {
      toast.dismiss();
      // toast.info("No file selected");
      return;
    }
    setIsError(false);
    setNetworkError(false);
    setErrorMessage("");
    setSendingData(false);
    // console.log("Driver License files log", e.target.files);

    dispatch(
      driverProfileActions.updateDrivingLicenseImageName(
        e.target.files[0]?.name
      )
    );
    dispatch(
      driverProfileActions.updateDrivingLicenseProofFileLocation(
        URL.createObjectURL(e.target.files[0])
      )
    );
  };

  const rcCardChangeHander = (e) => {
    // console.log(e.target.files.length);

    toast.dismiss();
    if (e.target.files.length === 0) {
      toast.dismiss();
      // toast.info("No file selected");
      return;
    }
    setIsError(false);
    setNetworkError(false);
    setErrorMessage("");
    setSendingData(false);
    // console.log("Driver License files log", e.target.files);

    dispatch(
      driverProfileActions.updateRCCardImageName(e.target.files[0]?.name)
    );
    dispatch(
      driverProfileActions.updateRCCardProofFileLocation(
        URL.createObjectURL(e.target.files[0])
      )
    );
  };

  const idProofChangeHandler = (e) => {
    // console.log(e.target.files.length);

    toast.dismiss();
    if (e.target.files.length === 0) {
      toast.dismiss();
      // toast.info("No file selected");
      return;
    }
    setIsError(false);
    setNetworkError(false);
    setErrorMessage("");
    setSendingData(false);
    // console.log("IdProof files log", e.target.files);

    dispatch(
      driverProfileActions.updateIdProofImageName(e.target.files[0]?.name)
    );
    dispatch(
      driverProfileActions.updateIdProofFileLocation(
        URL.createObjectURL(e.target.files[0])
      )
    );
  };

  const compressImageAndCreateFileForUpload = async (
    requestName,
    fileLocationURLObject,
    fileName,
    options
  ) => {
    try {
      // console.log(
      //   `${requestName} Creating response from file location URL Object...`
      // );

      const response = await fetch(fileLocationURLObject);

      // console.log(`${requestName} Response created.`);
      // console.log(`${requestName} Creating blob object from response...`);

      const blobObject = await response.blob();

      // console.log(`${requestName} Blob object created.`);
      // console.log(
      //   `${requestName} Creating non compressed image file from blob object...`
      // );

      const nonCompressedImage = new File([blobObject], fileName, {
        type: blobObject.type,
      });

      // console.log(`${requestName} Non compressed image file created.`);
      // console.log(`${requestName} `, nonCompressedImage);
      // console.log(`${requestName} Non compressed image file details `);
      // console.log(`${requestName} name - `, nonCompressedImage.name);
      // console.log(
      //   `${requestName} size - `,
      //   nonCompressedImage.size / 1024,
      //   `KiloBytes`
      // );
      // console.log(`${requestName} type - `, nonCompressedImage.type);
      // console.log(`${requestName} Compressing image...`);

      const compressedImage = await imageCompression(
        nonCompressedImage,
        options
      );

      // console.log(`${requestName} Image compression complete.`);
      // console.log(`${requestName} Compressed image - `, compressedImage);

      // console.log(`${requestName} Creating file from compressed image...`);

      const compressedImageFile = new File([compressedImage], fileName, {
        type: blobObject.type,
      });

      // console.log(`${requestName} Compressed image file created...`);
      // console.log(`${requestName} Compressed image file details`);
      // console.log(`${requestName} name - `, compressedImageFile.name);
      // console.log(
      // `${requestName} size - `,
      // compressedImageFile.size / 1024,
      // `KiloBytes`
      // );
      // console.log(`${requestName} type - `, compressedImageFile.type);

      return compressedImageFile;
    } catch (error) {
      console.error(
        `${requestName} Error occurred while compressing image:`,
        error
      );
      throw error;
    }
  };

  const sendDataToServer = async (requestName, fetchUrl, fetchOptions) => {
    // console.log(`Sending ${requestName} fetch request...`);
    try {
      const response = await fetch(fetchUrl, fetchOptions);

      // console.log("Request sent.");
      // console.log("Checking resposnse...");
      if (response.ok) {
        setErrorMessage(`${requestName} response ok`);
        // console.log("Response ok.");
        // console.log("Getting data from response...");
        const data = await response.json();
        // console.log(`Data for ${requestName} received from response.json() - `);
        // console.log(` ${requestName} car_id - `, data.car_id);
        // console.log(` ${requestName} car_name - `, data.car_name);
        // console.log(` ${requestName} car_photo - `, data.car_photo);
        // console.log(` ${requestName} id - `, data.id);
        // console.log(
        // ` ${requestName} registration_number - `,
        // data.registration_number
        // );
        return data;
      } else {
        setErrorMessage(`${requestName} response not ok`);
        // console.log("Response not ok.");
        throw response;
      }
    } catch (error) {
      setTapUploadDocumentCount(0);
      setErrorMessage(`${requestName} error occured`);
      // console.log(`An error occured while sending ${requestName} `);
      throw error;
    }
  };

  const sendButtonDataHandler = async () => {
    setTapUploadDocumentCount(1);
    // console.log("----------------------------------------------------------");
    // console.log("Validating data...");
    setSendingData(true);
    setErrorMessage("Validating data...");
    setNetworkError(false);

    // console.log("Checking basic details...");
    if (
      firstName.trim().length === 0 ||
      lastName.trim().length === 0
      // emailAddress.trim().length === 0 ||
      // . !driverPhoto
    ) {
      // console.log("Please enter basic details. Navigating...");
      setTapUploadDocumentCount(0);
      navigateToDriverCompleteProfile();
      return;
    }

    // console.log("Basic details passed.");
    // console.log("Checking address details...");

    if (
      addressLineOne.trim().length === 0
      // addressLineTwo.trim().length === 0 ||
      // city.trim().length === 0 ||
      // state.trim().length === 0
    ) {
      // console.log("Please enter address details. Navigating...");
      setTapUploadDocumentCount(0);
      navigateToAddressDetails();
      return;
    }
    // console.log("Address details passed.");
    // console.log("Checking Vehicle details...");

    if (
      drivingLicenseNumber.trim().length === 0 ||
      vehicleRegistrationNumber.trim().length === 0 ||
      vehicleName.trim().length === 0 ||
      vehicleType.trim().length === 0 ||
      !vehicleImagesFileLocation
    ) {
      // console.log("Please enter vehicle details. Navigating...");
      setTapUploadDocumentCount(0);
      navigateToVehicleDetails();
      return;
    }

    // console.log("Vehicle details passed.");
    // console.log("Checking driving license details...");

    if (!drivingLicenseProofFileLocation) {
      // console.log("Please select driving license photo.");
      setTapUploadDocumentCount(0);
      setSendingData(false);
      setErrorMessage("Validation error - No driving license");
      setIsError((prev) => ({ ...prev, drivingLicenseImage: true }));
      return;
    }

    // console.log("Driving license file selected.");
    // console.log("Checking Id proof file...");

    if (!rcCardProofFileLocation) {
      // console.log("Please select driving license photo.");
      setTapUploadDocumentCount(0);
      setSendingData(false);
      setErrorMessage("Validation error - No RC Card");
      setIsError((prev) => ({ ...prev, rcCardImage: true }));
      return;
    }

    // console.log("rc Card file selected.");
    // console.log("Checking RC Card proof file...");

    if (!idProofFileLocation) {
      // console.log("Please select id proof photo.");
      setTapUploadDocumentCount(0);
      setSendingData(false);
      setErrorMessage("Validation error - No ID proof");
      setIsError((prev) => ({ ...prev, idProofImage: true }));
      return;
    }

    // console.log("Id proof file selected.");
    // console.log("Validation complete ✔");

    // setErrorMessage("Validation complete...");
    // setErrorMessage("Filling form data...");

    // console.log("Appending driver details to form data..");

    const formData = new FormData();

    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", emailAddress || "support@taxiwars.in");
    formData.append("help_number", helpNumber);
    formData.append("street_address_1", addressLineOne);
    formData.append("street_address_2", addressLineTwo || "NA");
    formData.append("city", city);
    formData.append("state", state || "Other State");
    formData.append("driver_lat", location?.latitude?.toString?.() || "0.05");
    formData.append("driver_lng", location?.longitude?.toString?.() || "0.05");
    formData.append("referral_code", referralCode || "organic");

    // ------------------------------------------------
    // Compressing driver license image
    setErrorMessage("Compressing license image...");

    // console.log("Compressing driver license image...");
    const compressionOptionsForDrivingLicenseImage = {
      maxSizeMB: 0.768,
      maxWidthOrHeight: 600,
    };
    const compressedDrivingicenseImage =
      await compressImageAndCreateFileForUpload(
        "_driving license_",
        drivingLicenseProofFileLocation,
        drivingLicenseImageName,
        compressionOptionsForDrivingLicenseImage
      );
    // console.log("Driver license image compression complete.");
    // console.log("Adding it to form data...");

    formData.append("driving_lisence", compressedDrivingicenseImage);
    // console.log("Driver license added to form data.");

    setErrorMessage("License image compressed...");
    // ------------------------------------------------

    // ------------------------------------------------
    // Compressing driver license image
    setErrorMessage("Compressing license image...");

    // console.log("Compressing rcCard image...");
    const compressionOptionsForRCCardImage = {
      maxSizeMB: 0.768,
      maxWidthOrHeight: 600,
    };
    const compressedRCCardImage = await compressImageAndCreateFileForUpload(
      "_rc card_",
      rcCardProofFileLocation,
      idProofImageName,
      compressionOptionsForRCCardImage
    );
    // console.log("Driver rcCard image compression complete.");
    // console.log("Adding it to form data...");

    formData.append("rc", compressedRCCardImage);
    // console.log("Driver rcCard added to form data.");

    setErrorMessage("rc image compressed...");
    // ------------------------------------------------

    // ------------------------------------------------
    // compressing id proof image
    setErrorMessage("Compressing Id proof image...");

    // console.log("Compressing Id proof image... ");
    const compressionOptionsForIdProofImage = {
      maxSizeMB: 0.768,
      maxWidthOrHeight: 600,
    };
    const compressedIdProofImage = await compressImageAndCreateFileForUpload(
      "_id proof_",
      idProofFileLocation,
      idProofImageName,
      compressionOptionsForIdProofImage
    );
    // console.log("Id proof image compression complete");
    // console.log("Now adding it to form data...");
    formData.append("aadhar_card", compressedIdProofImage);
    // console.log("Id proof added to form data.");

    setErrorMessage("Id proof image compressed...");
    // ------------------------------------------------

    // console.log("Checking for driver profile image...");
    if (driverPhotoName && driverPhotoFileLocation) {
      // console.log("Driver profile photo present...");
      // ------------------------------------------------
      // compressing driver profile photo
      setErrorMessage("Compressing driver profile image... ");

      // console.log("Compressing driver profile image... ");
      const compressionOptionsForDriverProfilePictureImage = {
        maxSizeMB: 0.256,
        maxWidthOrHeight: 300,
      };
      const compressedDriverProfileImage =
        await compressImageAndCreateFileForUpload(
          "_driver photo_",
          driverPhotoFileLocation,
          driverPhotoName,
          compressionOptionsForDriverProfilePictureImage
        );
      // console.log("Driver photo image compression complete.");
      // console.log("Now adding it to form data...");
      formData.append("driver_photo", compressedDriverProfileImage);
      // console.log("Driver profile photo added to form data.");

      setErrorMessage("Driver profile image compressed...");
      // ------------------------------------------------
    } else {
      // console.log("Driver profile image not selected...");
      // console.log("Proceeding without the driver profile image...");
    }

    // console.log("Driver profile data sending to server - ", formData);
    // for (let entry of formData.entries()) {
    //   console.log(entry);
    // }

    setErrorMessage("Uploading profile data to server...");
    const urlForDriverProfileFetchRequest = `${BASE_URL}${DRIVER_PROFILE_DETAILS}`;
    const optionsForDriverProfileFetchRequest = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
      timeout: 5 * ONE_MINUTE,
    };

    const dataFromPostProfile = await sendDataToServer(
      "_profile_",
      urlForDriverProfileFetchRequest,
      optionsForDriverProfileFetchRequest
    );

    setErrorMessage("Profile data uploaded...");

    // console.log("Saving driver name to local storage...");
    setDriverName(dataFromPostProfile?.first_name);

    localStorage.setItem(
      USER_LOCAL_STORAGE,
      JSON.stringify(dataFromPostProfile)
    );
    // console.log("Driver name saved to local storage.");

    // ------------------------------------------------

    // console.log("Now creating form data for vehicle details...");

    setErrorMessage("Filling vehicle form data...");

    const vehicleFormData = new FormData();
    vehicleFormData.append("registration_number", vehicleRegistrationNumber);
    vehicleFormData.append("car_name", vehicleName);
    vehicleFormData.append("vehicle_type", vehicleType);

    // ------------------------------------------------
    // vehicle image compression
    setErrorMessage("Compressing vehcile image...");

    // console.log("Compressing vehicle image... ");
    const compressionOptionForVehicleImages = {
      maxSizeMB: 0.256,
      maxWidthOrHeight: 300,
    };

    const compressedVehicleImage = await compressImageAndCreateFileForUpload(
      "_vehicle image_",
      vehicleImagesFileLocation,
      vehicleImageName,
      compressionOptionForVehicleImages
    );

    // console.log("Vehicle image compression complete.");
    // console.log("Now adding it to form data...");
    vehicleFormData.append("car_photo", compressedVehicleImage);
    // console.log("Vehicle image added to form data.");

    setErrorMessage("Vehicle image compressed...");
    // ------------------------------------------------

    setErrorMessage("uploading  vehcile details...");
    const urlForVehicleDataFetchRequest = `${BASE_URL}${DRIVER_CAR_DETAILS}`;
    const optionsForVehicleDataFetchRequest = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: vehicleFormData,
      timeout: 5 * ONE_MINUTE,
    };

    const dataFromVehiclePost = await sendDataToServer(
      "_vehicle_",
      urlForVehicleDataFetchRequest,
      optionsForVehicleDataFetchRequest
    );

    setErrorMessage("Vehicle details uploaded...");

    if (dataFromVehiclePost && dataFromPostProfile) {
      setErrorMessage("Sending fcm token...");

      // console.log("sending fcm token...");
      // setSendingData(false);

      // navigateToDriverHomeViewAllBidsHandler();

      const responseFcmPost = await fetch(`${BASE_URL}${DRIVER_FCM_TOKEN}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        method: "POST",
        body: JSON.stringify({
          user_id: dataFromPostProfile?.details,
          fcm: fcmToken || "",
        }),
      });

      console.log("responseFcmPost", responseFcmPost);

      if (responseFcmPost.ok) {
        navigateToDriverHomeViewAllBidsHandler();
        console.log("Inside navigate");
      } else {
        setErrorMessage("Token error");
      }

      setSendingData(false);
    } else {
      setTapUploadDocumentCount(0);
      setErrorMessage("Vehcile upload error");
      // console.log("Vehicle post return data not received.");
      // console.log("An error occured while sending vehicle data");
    }
  };

  return (
    <>
      <RiderDriverSign back driver>
        <div className={styles.children}>
          <TextHeadingSign displayText="Upload Documents" />
          <InputWithLabel
            labelDisplayText="Driving Licence"
            forIdAndName="drivingLicense"
            value={drivingLicenseImageName}
            handleChange={drivingLicenseChangeHander}
          />
          {isError.rcCardImage && (
            <ErrorInNumber displayText="Please select driving license" />
          )}
          <InputWithLabel
            labelDisplayText="RC Card"
            forIdAndName="rcCard"
            value={rcCardImageName}
            handleChange={rcCardChangeHander}
          />
          {isError.rcCardImage && (
            <ErrorInNumber displayText="Please select rc card" />
          )}
          <InputWithLabel
            labelDisplayText="ID Proof (Aadhaar Card or PAN)"
            forIdAndName="idProof"
            value={idProofImageName}
            handleChange={idProofChangeHandler}
          />
          <InputBox
            type="text"
            idAndName="referralCode"
            placeholderText="Referral code ( Optional )"
            value={referralCode}
            onChange={(e) => {
              const referText = e.target.value;
              setReferralCode(referText);
            }}
          />
          {isError.idProofImage && (
            <ErrorInNumber displayText="Please select ID proof image" />
          )}
          {isSending && <ErrorInNumber displayText="Sending..." />}
          {errorMessage && <ErrorInNumber displayText={errorMessage} />}
          {networkError && <ErrorInNumber displayText="Network error" />}

          {tapUploadDocumentCount === 0 && (
            <Button
              displayText="Submit"
              onClickFunction={sendButtonDataHandler}
            />
          )}
        </div>
        <ArrowBackButton onClickFunction={navigateBackToVehicleDetails} />
      </RiderDriverSign>
    </>
  );
};

export default DriverUploadDocuments;
