import React, { useEffect, useState } from "react";
// eslint-disable-next-line
import styles from "./ScheduledRide.module.css";
import Cookies from "js-cookie";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../../constants/storageConstants";
import {
  BASE_URL,
  GET_SCHEDULED_RIDE_DETAILS,
} from "../../../constants/apiConstants";
import { PastRideCard } from "../../../components";

const ScheduledRide = () => {
  const [isScheduledRide, setIsScheduledRide] = useState(true);
  useEffect(() => {
    const getScheduledRide = async () => {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      const getScheduledUrl = `${BASE_URL}${GET_SCHEDULED_RIDE_DETAILS}`;
      const getScheduledRideOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(getScheduledUrl, getScheduledRideOptions);

      if (!response.ok) {
        setIsScheduledRide(false);
        throw response;
      }

      const data = await response.json();
      console.log("Scheduled", data);

      if (
        data?.Message === "No current ride." ||
        data?.Message === "Converted to Bid Confirmed"
      ) {
        setIsScheduledRide(false);
        return;
      }

      setIsScheduledRide(data);
    };

    try {
      getScheduledRide();
    } catch (error) {
      console.log(error);
    }
    //
  }, []);
  return (
    <div className={styles.scheduledRide}>
      {!isScheduledRide && (
        <div className={styles.noRides}>No Scheduled Ride</div>
      )}
      {isScheduledRide && (
        <PastRideCard
          rideAmount={isScheduledRide?.total_fair}
          time={isScheduledRide?.schedule_time}
          pickupAddressText={isScheduledRide?.pickup_address_text}
          dropAddressText={isScheduledRide?.drop_address_text}
          customerName={isScheduledRide?.customer_name}
          customerPhoneNumber={isScheduledRide?.customer_phone}
        />
      )}
    </div>
  );
};

export default ScheduledRide;
