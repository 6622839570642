import Cookies from "js-cookie";
import React, { useState } from "react";
import { Button, RiderDriverSign, TextHeadingSign } from "../../../components";
import {
  BASE_URL,
  CCAVENUE_BASE_URL_LIVE,
} from "../../../constants/apiConstants";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../../constants/storageConstants";
// eslint-disable-next-line
import styles from "./BidSecurityDepositCcAvenue.module.css";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const BidSecurityDepositCcAvenue = () => {
  const overdueAmount = useLocation();
  // console.log("BidSecurity.js, overdueAmount", overdueAmount);
  const { state } = overdueAmount;
  // console.log("BidSecurity.js, State ( from navigation ) ", state);

  const loadIframe = (src) => {
    return new Promise((resolve) => {
      const iframe = document.createElement("iframe");
      iframe.src = src;
      iframe.onload = () => {
        resolve(true);
      };
      iframe.onerror = () => {
        resolve(false);
      };
      iframe.style.position = "fixed";
      iframe.style.top = "0";
      //   iframe.style.left = "0";
      //   iframe.style.transform = "translateX(50%)";
      //   iframe.style.right = "0";
      iframe.style.border = `none`;
      iframe.style.padding = `5px`;
      //   iframe.style.paddingTop = `5px`;
      iframe.style.borderRadius = `25px`;
      //   iframe.style.overflow = "hidden";
      iframe.style.width = `min(425px, 100vw)`;
      iframe.style.height = "100%";
      iframe.style.zIndex = "9999"; // Set a high z-index value
      iframe.style.background = "white";
      document.body.appendChild(iframe);
    });
  };

  async function onClickPaymentAndNavigationHandler() {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const fetchEncryptedDataUrl = `${BASE_URL}${"/cc/ccavRequestHandlerdriver"}`;
    const fetchEncryptedDataOptions = {
      method: "POST",
      //   method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        // ride_id: createdRideId,
        // bid_id: `${selectedBidDetails.bidId}`,
        // amount: 1000,
      }),
    };

    const encDataResponse = await fetch(
      fetchEncryptedDataUrl,
      fetchEncryptedDataOptions
    );

    if (!encDataResponse.ok) {
      toast.error("Response not okay");
      console.log("something wen twrong");
      return;
    }

    const encData = await encDataResponse.json();
    console.log("encData", encData);

    try {
      const ifr = `${CCAVENUE_BASE_URL_LIVE}/transaction/transaction.do?command=initiateTransaction&merchant_id=2492482&encRequest=${encData.encryption}&access_code=${encData.accessCode}`;
      // const iframeSrc = "https://www.google.com/webhp?igu=1";
      const success = await loadIframe(ifr);
      console.log(success);
      if (success) {
        console.log("Iframe loaded successfully");
      } else {
        console.error("Error loading iframe");
      }
    } catch (error) {
      console.error("Error loading iframe:", error);
    }
  }
  return (
    <RiderDriverSign back driver>
      <div className={styles.children}>
        <TextHeadingSign displayText="Overdue Payment" />
        <div className={styles.nominalFeeButton}>
          <div className={styles.infoBar}>
            <span>{`₹ ${(state.overdue_amount / 100).toFixed(1)}`}</span>
          </div>
          <Button
            displayText="Proceed to Pay"
            onClickFunction={onClickPaymentAndNavigationHandler}
          />
        </div>
      </div>
    </RiderDriverSign>
  );
};

export default BidSecurityDepositCcAvenue;
