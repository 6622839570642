import { createSlice } from "@reduxjs/toolkit";

const driverProfileSlice = createSlice({
  name: "driverProfile",
  initialState: {
    firstName: "",
    lastName: "",
    emailAddress: "",
    helpNumber: "",
    driverImage: "",
    phoneNumber: "",
    overdueAmount: "",

    addressLineOne: "",
    addressLineTwo: "",
    city: "Pune",
    state: "Maharashtra",

    drivingLicenseNumber: "",

    vehicleRegistrationNumber: "",
    vehicleName: "",
    vehicleType: "",
    vehicleImageName: "",
    vehicleImagesFileLocation: "",

    drivingLicenseImageName: "",
    drivingLicenseProofFileLocation: "",

    rcCardImageName: "",
    rcCardProofFileLocation: "",

    idProofImageName: "",
    idProofFileLocation: "",

    driverPhotoName: "",
    driverPhotoFileLocation: "",
  },

  reducers: {
    updateFirstName(state, action) {
      state.firstName = action.payload;
    },
    updateLastName(state, action) {
      state.lastName = action.payload;
    },
    updateEmailAddress(state, action) {
      state.emailAddress = action.payload;
    },
    updateHelpNumber(state, action) {
      state.helpNumber = action.payload;
    },
    updateDriverImage(state, action) {
      state.driverImage = action.payload;
    },
    updatePhoneNumber(state, action) {
      state.phoneNumber = action.payload;
    },
    updateOverdueAmount(state, action) {
      state.overdueAmount = action.payload;
    },
    updateAddressLineOne(state, action) {
      state.addressLineOne = action.payload;
    },
    updateAddressLineTwo(state, action) {
      state.addressLineTwo = action.payload;
    },
    updateAddressLineCity(state, action) {
      state.city = action.payload;
    },
    updateAddressLineState(state, action) {
      state.state = action.payload;
    },
    updateDrivingLicenseNumber(state, action) {
      state.drivingLicenseNumber = action.payload;
    },
    updateVehicleRegistrationNumber(state, action) {
      state.vehicleRegistrationNumber = action.payload;
    },
    updateVehicleName(state, action) {
      state.vehicleName = action.payload;
    },

    updateVehicleType(state, action) {
      state.vehicleType = action.payload;
    },

    updateVehicleImageName(state, action) {
      state.vehicleImageName = action.payload;
    },
    updateVehicleImagesFileLocation(state, action) {
      state.vehicleImagesFileLocation = action.payload;
    },
    updateDrivingLicenseImageName(state, action) {
      state.drivingLicenseImageName = action.payload;
    },
    updateDrivingLicenseProofFileLocation(state, action) {
      state.drivingLicenseProofFileLocation = action.payload;
    },
    updateRCCardImageName(state, action) {
      state.rcCardImageName = action.payload;
    },
    updateRCCardProofFileLocation(state, action) {
      state.rcCardProofFileLocation = action.payload;
    },
    updateIdProofImageName(state, action) {
      state.idProofImageName = action.payload;
    },
    updateIdProofFileLocation(state, action) {
      state.idProofFileLocation = action.payload;
    },

    updateDriverPhotoName(state, action) {
      state.driverPhotoName = action.payload;
    },

    updateDriverPhotoFileLocation(state, action) {
      state.driverPhotoFileLocation = action.payload;
    },
  },
});

export const driverProfileActions = driverProfileSlice.actions;
export default driverProfileSlice;
