import { images } from "../../../images";
import styles from "./CurrentStateTimePriceBids.module.css";

const CurrentStateTimePriceBids = ({
  bidPlacedWaiting = false,
  bidAccepted = false,
  rideTime,
  numberOfBids,
  driverBidAmount,
  rideActiveBool = false,
  scheduleDate = "",
  scheduleTime = "",
}) => {
  function convertDateFormat(scheduleDate) {
    if (scheduleDate === "") return "";

    try {
      const [year, month, day] = scheduleDate.split("-");
      if ((year || month || day) === undefined) return "";
      return `${day}-${month}-${year}`;
    } catch (error) {
      console.log(error);
      return "";
    }
  }

  function convertTo12HourFormat(scheduleTime) {
    if (scheduleTime === "") return "";
    try {
      const [hours, minutes, seconds] = scheduleTime.split(":");
      let period = "AM";
      let hour = parseInt(hours, 10);

      if (hour >= 12) {
        period = "PM";
        if (hour > 12) {
          hour -= 12;
        }
      }
      return `${hour}:${minutes} ${period}`;
    } catch (error) {
      console.log(error);
      return "";
    }
  }

  return (
    <div className={styles.currentStateTimePriceBids}>
      <div className={styles.loadingStatusTime}>
        {/* {bidPlacedWaiting && (
          <img
            className={styles.hourGlass}
            src={images.hourGlass}
            alt="hour glass"
          />
        )} */}
        {bidAccepted && (
          <img
            className={styles.flatGreenCircleBlackTick}
            src={images.flatGreenCircleBlackTick}
            alt="black check in green circle"
          />
        )}
        <p className={styles.time}>
          {convertTo12HourFormat(scheduleTime) || ""}
        </p>
        <p className={styles.time}>{convertDateFormat(scheduleDate) || ""}</p>
      </div>
      <div
        // className={`${styles.priceBids} ${
        //   bidPlacedWaiting && styles.bidPlacedWaiting
        // }`}
        className={`${styles.priceBids} ${
          bidPlacedWaiting && styles.bidPlacedWaiting
        } ${bidAccepted && styles.bidAccepted} ${
          rideActiveBool && styles.backgroundColorAnimation
        }`}
      >
        <span>
          {`${
            bidPlacedWaiting || bidAccepted
              ? "₹" + parseFloat(driverBidAmount / 100).toFixed(1)
              : ""
          }`}
        </span>
        <span>({numberOfBids} bids)</span>
      </div>
    </div>
  );
};

export default CurrentStateTimePriceBids;
