import { useNavigate } from "react-router-dom";
import {
  usePreventBackBehaviour,
  useStopBackgroundLocationUpdate,
} from "../../../hooks";
import styles from "./AdvRideDetailsEndRide.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  DRIVER_RIDE_END_OVERDUE_SCREEN,
  DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
} from "../../../constants/routeConstants";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  LoadingSpinner,
  MenuTextUserWrapper,
  PlaceBidButton,
  RiderNamePickupDropCancelCard,
  SosButton,
  TopGreenCheckStatus,
  YourEarningCommission,
} from "../../../components";
import Cookies from "js-cookie";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  PACKAGE_12_HOUR,
} from "../../../constants/storageConstants";
import {
  ADV_DRIVER_END_RIDE,
  ADV_DRIVER_VIEW_ACCEPTED_CUSTOMER_BID,
  BASE_URL,
} from "../../../constants/apiConstants";
import {
  driverAllRideAdvActions,
  driverCurrentRideAdvActions,
} from "../../../store/slice";
const RideDetailsEndRide = () => {
  usePreventBackBehaviour();
  const navigate = useNavigate();
  useStopBackgroundLocationUpdate();

  //use fetch rider details
  //
  const dispatch = useDispatch();

  const [rideDetails, setRideDetails] = useState({});
  const selectedRideId = useSelector(
    (state) => state.driverAllRideAdv.selectedRide
  );

  useEffect(() => {
    (() => {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      fetch(`${BASE_URL}${ADV_DRIVER_VIEW_ACCEPTED_CUSTOMER_BID}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          ride_id: selectedRideId,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          console.log("use-driver-fetch-ride-details.js, Data received", data);
          const [lat, lng] = data?.ride_details?.pickup_address
            .split(",")
            .map(parseFloat);
          const [dlat, dlng] = data?.ride_details?.drop_address
            .split(",")
            .map(parseFloat);

          setRideDetails({
            customerId: data?.ride_details?.customer,
            fullName: data?.name,
            pickupAddressLat: lat,
            pickupAddressLng: lng,
            dropAddressLat: dlat,
            dropAddressLng: dlng,
            pickupAddressText: data?.ride_details?.pickup_address_text,
            dropAddressText: data?.ride_details?.drop_address_text,
            rideDistance: data?.ride_details?.distance,
            phoneNumber: data?.phone_number,
            // amount: data?.ride_details?.amount,
            amount: data?.amount_payable,
            paymentType: data?.payment_type,
            totalAmount: data?.total_amount,
            taxiwarsCommissionAmount: data?.taxiwars_commission_amount,
            commissionCollectedByDriver: data?.commission_collected_by_driver,
            scheduleDate: data?.schedule_date,
            scheduleTime: data?.schedule_time,
            returnType: data?.return_type,
          });

          dispatch(
            driverCurrentRideAdvActions.setCurrentRideDetails({
              customerId: data?.ride_details?.customer,
              fullName: data?.name,
              pickupAddressLat: lat,
              pickupAddressLng: lng,
              dropAddressLat: dlat,
              dropAddressLng: dlng,
              pickupAddressText: data?.ride_details?.pickup_address_text,
              dropAddressText: data?.ride_details?.drop_address_text,
              phoneNumber: data?.phone_number,
              // amount: data?.ride_details?.amount,
              amount: data?.amount_payable,
              paymentType: data?.payment_type,
              totalAmount: data?.total_amount,
              taxiwarsCommissionAmount: data?.taxiwars_commission_amount,
              commissionCollectedByDriver: data?.commission_collected_by_driver,
              scheduleDate: data?.schedule_date,
              scheduleTime: data?.schedule_time,
              returnType: data?.return_type,
            })
          );
          dispatch(
            driverCurrentRideAdvActions.setCurrentRideId(selectedRideId)
          );
        })
        .catch((e) => {
          console.log(
            "use-driver-fetch-ride-details.js, Error occured in getting accepted ride details",
            e
          );
        });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const windowOpenGoogleMapHandler = (lat, lng) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&travelmode=driving`;
    window.open(url, "_blank");
  };
  //
  //use fetch rider details
  //   const { rideDetails, startRideHandler, windowOpenGoogleMapHandler } =
  //     useDriverFetchRideDetails();
  // the above hook was replaced

  const amount = useSelector(
    (state) => state.driverCurrentRideAdv.currentRideDetails?.amount
  );

  const paymentType = useSelector(
    (state) => state.driverCurrentRideAdv.currentRideDetails?.paymentType
  );

  // const navToOverduePageAfterRideEnd = useNavigateOnEvent(
  //   DRIVER_RIDE_END_OVERDUE_SCREEN
  // );
  const [endRideButtonCount, setEndRideButtonCount] = useState(0);

  const onEndRideHandler = () => {
    localStorage.setItem("returnButton", false);

    if (endRideButtonCount === 1) return;
    setEndRideButtonCount(1);
    // console.log("Start ride function");
    toast.info("  Ending ride...", {
      toastId: "ride",
      icon: <LoadingSpinner />,
      autoClose: false,
    });
    // console.log("Notification loading");
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    fetch(`${BASE_URL}${ADV_DRIVER_END_RIDE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: selectedRideId,
      }),
    })
      .then((response) => {
        if (response.ok) {
          // navigateToDriverHome();
          // console.log("Email sent!!");

          return response.json();
        }
        throw response;
      })
      .then((data) => {
        // console.log("email data", data);
        // console.log("Notification success");
        toast.update("ride", {
          render: "Ride Completed",
          type: toast.TYPE.SUCCESS,
          autoClose: 20000,
          closeButton: true,
          icon: null,
        });
        // navigateToDriverHome();
        // navToOverduePageAfterRideEnd();
        dispatch(driverAllRideAdvActions.updateBidConfirmedRides([]));
        const amountInRs = (parseFloat(amount) / 100).toFixed(1);
        navigate(
          `${DRIVER_RIDE_END_OVERDUE_SCREEN}/?collectAmount=${amountInRs}`
        );
      })
      .catch((e) => {
        setEndRideButtonCount(0);
        console.log("RideDetailsEndRide.js, Error ending ride", e);
      });
  };

  const [returnButton, setReturnButton] = useState(
    localStorage.getItem("returnButton") || false
  );

  useEffect(() => {
    const timerId = setTimeout(() => {
      localStorage.setItem("returnButton", true);
      setReturnButton(true);
      console.log(returnButton);
    }, 10 * 60 * 1000);

    return () => clearTimeout(timerId);
  }, [returnButton]);

  return (
    <MenuTextUserWrapper logoHeadingText="Ride details">
      <div className={styles.rideDetailsEndRide}>
        <TopGreenCheckStatus displayText="Your ride is in progress." />

        <RiderNamePickupDropCancelCard
          fullName={rideDetails?.fullName || "NA"}
          pickupAddressText={rideDetails?.pickupAddressText || "NA"}
          dropAddressText={rideDetails?.dropAddressText || "NA"}
          phoneNumber={rideDetails?.phoneNumber || "NA"}
          endRide
          scheduleDate={rideDetails?.scheduleDate}
          scheduleTime={rideDetails?.scheduleTime}
        />

        <p className={styles.message}>Collect your payment after ride ends.</p>
        <Button
          displayText="Go to maps"
          onClickFunction={() => {
            windowOpenGoogleMapHandler(
              rideDetails?.dropAddressLat,
              rideDetails?.dropAddressLng
            );
          }}
        />
        {rideDetails?.returnType && returnButton && (
          <Button
            displayText="Go Back to Pickup point"
            onClickFunction={() => {
              windowOpenGoogleMapHandler(
                rideDetails?.pickupAddressLat,
                rideDetails?.pickupAddressLng
              );
            }}
          />
        )}
        <PlaceBidButton
          disabledState
          onClickFunction={onEndRideHandler}
          displayText="End Ride"
          value={`₹ ${
            paymentType === "Cash"
              ? (parseFloat(amount) / 100).toFixed(1)
              : (parseFloat(amount) / 100).toFixed(1)
          }`}
        />
        <YourEarningCommission
          amountPayable={rideDetails?.amount}
          commissionCollectedByDriver={rideDetails?.commissionCollectedByDriver}
          taxiwarsCommissionAmount={rideDetails?.taxiwarsCommissionAmount}
          totalAmount={rideDetails?.totalAmount}
        />
      </div>
      {rideDetails?.returnType ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0rem",
            color: "white",
          }}
        >
          <p
            style={{
              fontSize: "1.8rem",
              textAlign: "center",
              marginTop: "3rem",
            }}
          >
            {`${PACKAGE_12_HOUR} (2 x ${rideDetails?.rideDistance}) + 10 = ${
              2 * rideDetails?.rideDistance.split(" ")[0]
            } Kms considered`}
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              // color: "red",
              textAlign: "center",
              marginTop: "3rem",
              // width: "75%",
            }}
          >
            *Extra Hrs Mini- ₹200/hr, Sedan- ₹215/hr, Suv- ₹260/hr
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              textAlign: "center",
              marginTop: "3rem",
            }}
          >
            *Extra kms Mini- ₹10/km, Sedan- ₹12/km, Suv- ₹14/km
          </p>
        </div>
      ) : (
        <></>
      )}
      <BackToHome />
      <SosButton currentRideId={selectedRideId} />
    </MenuTextUserWrapper>
  );
};

export default RideDetailsEndRide;

const BackToHome = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        zIndex: 5,
        position: "absolute",
        bottom: "calc(4rem + 75px)",
        right: "3rem",
        fontSize: "1.8rem",
      }}
      className={`${styles.floatingAction} ${styles.right}`}
    >
      <button
        onClick={() => {
          navigate(`${DRIVER_VIEW_RIDES_MY_BIDS_ROUTE}`);
          // navigate(`${}`);
        }}
        style={{
          border: "none",
          background: "none",
          // padding: 0,
          margin: 0,
          font: "inherit",
          cursor: "pointer",
          // outline: "inherit",

          /* Custom */
          backgroundColor: "#419c00",
          padding: "1.6rem",
          outline: "none",
          borderRadius: "30px",
          minWidth: "100px",
          color: "#fff",
          fontWeight: " bold",
          // -webkit-tap-highlight-color: transparent;
          /* transition: background-color 0.3s ease-in; */
          userSelect: "none",
        }}
        className={styles.floatingActionButton}
      >
        <p
          style={{
            fontSize: "1.6rem",
          }}
          className=""
        >
          Home
        </p>
        <p
          style={{
            fontSize: "1.4rem",
          }}
        >
          {/* (Outstation) */}
        </p>
      </button>
    </div>
  );
};
