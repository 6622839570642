import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useGeolocation,
  useLocalStorage,
  useNavigateOnEvent,
  usePreventBackBehaviour,
  useStopBackgroundLocationUpdate,
} from "../../../hooks";
import styles from "./AdvViewRidesWithMyBids.module.css";
import {
  BottomAd,
  Button,
  FloatingActionButton,
  HomescreenGoogleMap,
  LoadingSpinner,
} from "../../../components";
import Cookies from "js-cookie";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  HOURS_12,
  SUPPORT_MOBILE_NUMBERS,
  bottomAd,
} from "../../../constants/storageConstants";
import {
  ADV_DRIVER_GET_BID_CONFIRMED_RIDES,
  ADV_DRIVER_GET_PENDING_RIDES,
  ADV_DRIVER_IN_PROGRESS_RIDES,
  ADV_DRIVER_VIEW_ALL_BIDS,
  ADV_DRIVER_VIEW_ALL_RIDES,
  ADV_NEW_FAIR_VIEW,
  BASE_URL,
  CODE_200,
  GOOGLE_MAPS_API_KEY,
  RIDE_STATUS_ACTIVE,
  RIDE_STATUS_BID_CONFIRMED,
  RIDE_STATUS_CANCELLED,
  RIDE_STATUS_IN_PROGRESS,
  RIDE_STATUS_PENDING,
} from "../../../constants/apiConstants";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useJsApiLoader } from "@react-google-maps/api";
import { images } from "../../../images";
import {
  LOCATION_UPDATE_TIME_IN_MILLI_SECOND,
  ONE_SECOND,
  THIRTY_SECONDS,
} from "../../../constants/timeConstants";
import {
  ADV_DRIVER_BID_ACCEPTED_START_RIDE_ROUTE,
  ADV_DRIVER_PLACE_A_BID_ROUTE,
  ADV_DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE,
  ADV_DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
  DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
} from "../../../constants/routeConstants";
import { driverAllRideAdvActions } from "../../../store/slice";
import { useNavigate } from "react-router-dom";
import SingleBidDetailCard from "../../../components/Adv/SingleBidDetailCard/SingleBidDetailCard";

const AdvViewRidesWithMyBids = () => {
  usePreventBackBehaviour();
  useStopBackgroundLocationUpdate();
  const navigate = useNavigate();

  const navigateToInProgressEndRideAdv = useNavigateOnEvent(
    ADV_DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE
  );
  const [location, setLocation] = useState();

  // const [showFloatingActionButton, setShowFloatingActionButton] =
  //   useState(false);

  // const [dataForInProgressRide, setDataForInProgressRide] = useState("");

  const options = useMemo(
    () => ({
      enableHighAccuracy: true,
      maximumAge: 1 * 1000,
      timeout: LOCATION_UPDATE_TIME_IN_MILLI_SECOND,
    }),
    []
  );

  const {
    loading: loadingGeoLoc,
    error: errorGeoLoc,
    data: dataGeoLoc,
  } = useGeolocation(options);

  useEffect(() => {
    if (loadingGeoLoc) return;
    if (errorGeoLoc) return;

    // console.log("Data current location - ");
    // console.log(dataGeoLoc);

    setLocation({
      latitude: dataGeoLoc.latitude,
      longitude: dataGeoLoc.longitude,
    });
  }, [loadingGeoLoc, errorGeoLoc, dataGeoLoc]);

  const dispatch = useDispatch();

  const rideList = useSelector((state) => state.driverAllRideAdv.rides);

  const pendingRideList = useSelector(
    (state) => state.driverAllRideAdv.pendingRides
  );

  const bidConfirmedRideList = useSelector(
    (state) => state.driverAllRideAdv.bidConfirmedRides
  );

  const [driverName] = useLocalStorage("driver_name");

  //to run only once
  useEffect(() => {
    // Active Rides
    const updateRides = () => {
      // console.log("Fetch request sent..");
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      fetch(` ${BASE_URL}${ADV_DRIVER_VIEW_ALL_RIDES}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          // console.log("Driver view al rides result - ", result);
          if (!Array.isArray(result)) {
            dispatch(driverAllRideAdvActions.updateRides([]));
            return;
          }
          dispatch(driverAllRideAdvActions.updateRides(result));
        })
        .catch((error) => console.log("ViewRidesWithMyBids.js, error", error));
    };
    // pending rides
    const updateRidesPending = () => {
      // console.log("Fetch request for pending rides sent..");
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      fetch(` ${BASE_URL}${ADV_DRIVER_GET_PENDING_RIDES}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          // console.log("Driver view pending rides response - ");
          // console.log(response);
          return response.json();
        })
        .then((result) => {
          // console.log("Driver view pending rides result - ");
          // console.log(result);
          if (!Array.isArray(result)) {
            dispatch(driverAllRideAdvActions.updatePendingRides([]));
            return;
          }
          dispatch(driverAllRideAdvActions.updatePendingRides(result));
        })
        .catch((error) =>
          console.log("ViewRidesWithMyBids.js, Pending Rides error", error)
        );
    };

    // bid confirmed
    const updateRidesBidConfirmed = () => {
      // console.log("Fetch request for pending rides sent..");
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      fetch(` ${BASE_URL}${ADV_DRIVER_GET_BID_CONFIRMED_RIDES}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          // console.log("response - ", response);
          return response.json();
        })
        .then((result) => {
          // console.log("Driver view bid confirmed rides result - ", result);
          if (!Array.isArray(result)) {
            dispatch(driverAllRideAdvActions.updateBidConfirmedRides([]));
            return;
          }
          dispatch(driverAllRideAdvActions.updateBidConfirmedRides(result));
        })
        .catch((error) =>
          console.log(
            "ViewRidesWithMyBids.js, Bid Confirmed Rides error",
            error
          )
        );
    };
    updateRidesPending();
    updateRides();
    updateRidesBidConfirmed();

    const interval = setInterval(() => {
      updateRidesPending();
      updateRides();
      updateRidesBidConfirmed();
      // can be changed to any time we want
    }, ONE_SECOND * 15);

    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    const inProgressRideFetchCall = async () => {
      // console.log("Calling in progress call....");
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

      const fetchURL = `${BASE_URL}${ADV_DRIVER_IN_PROGRESS_RIDES}`;

      const fetchOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(fetchURL, fetchOptions);

      if (!response.ok) {
        // console.log("Vireposnse not ok...");
        return;
      }

      const data = await response.json();

      // console.log("Data received .. ");
      // console.log(data);

      // console.log("data");
      // console.log(data);
      if (data && data.hasOwnProperty("ride_id")) {
        // setShowFloatingActionButton(true);
        // setDataForInProgressRide(data?.ride_id);
        // console.log("Automatic navigation to in progress screen...");
        dispatch(driverAllRideAdvActions.setSelectedRide(data?.ride_id));
        navigateToInProgressEndRideAdv();
      } else {
        // setShowFloatingActionButton(false);
      }
    };

    inProgressRideFetchCall();

    const intervalInprogress = setInterval(() => {
      inProgressRideFetchCall();
    }, THIRTY_SECONDS);

    return () => clearInterval(intervalInprogress);
  }, [dispatch]);

  const floatingButtonOnClickHandler = () => {
    // nav;
  };

  //   const { isOnDuty, changeIsOnDuty } = useOnDutyHook();

  // const isOnDuty = { value: true, status: "success" };
  // const changeIsOnDuty = () => {};

  // const ridesA = [
  //   {
  //     ride_id: 3365,
  //     customer_id: 58,
  //     pickup_address: "26.4305271,80.299717",
  //     drop_address: "26.4378764,80.3358456",
  //     pickup_address_text:
  //       "C7JX+6W6, Barra 6, Barra World Bank, Barra, Kanpur, Uttar Pradesh 208027, India",
  //     drop_address_text:
  //       "South X Mall Kidwai Nagar Bypass Road, O Block, Marble Market, Kidwai Nagar, Kanpur, Uttar Prad",
  //     ride_date: "2023-10-28",
  //     distance: "6.2 km",
  //     ride_status: "Active",
  //     ride_time: "13:33:31",
  //     bid_count: 0,
  //     payment_id: "",
  //     amount: "",
  //     driver_ride_start: null,
  //     driver_ride_end: null,
  //     driver_bid_amount: null,
  //     schedule_time: "13:33:31",
  //     schedule_date: "2023-11-21",
  //     return_type: true,
  //   },
  //   {
  //     ride_id: 3365,
  //     customer_id: 58,
  //     pickup_address: "26.4305271,80.299717",
  //     drop_address: "26.4378764,80.3358456",
  //     pickup_address_text:
  //       "C7JX+6W6, Barra 6, Barra World Bank, Barra, Kanpur, Uttar Pradesh 208027, India",
  //     drop_address_text:
  //       "South X Mall Kidwai Nagar Bypass Road, O Block, Marble Market, Kidwai Nagar, Kanpur, Uttar Prad",
  //     ride_date: "2023-10-28",
  //     distance: "6.2 km",
  //     ride_status: "Active",
  //     ride_time: "13:33:31",
  //     bid_count: 0,
  //     payment_id: "",
  //     amount: "",
  //     driver_ride_start: null,
  //     driver_ride_end: null,
  //     driver_bid_amount: null,
  //     schedule_time: "13:33:31",
  //     schedule_date: "2023-11-21",
  //     return_type: false,
  //   },
  //   {
  //     ride_id: 3365,
  //     customer_id: 58,
  //     pickup_address: "26.4305271,80.299717",
  //     drop_address: "26.4378764,80.3358456",
  //     pickup_address_text:
  //       "C7JX+6W6, Barra 6, Barra World Bank, Barra, Kanpur, Uttar Pradesh 208027, India",
  //     drop_address_text:
  //       "South X Mall Kidwai Nagar Bypass Road, O Block, Marble Market, Kidwai Nagar, Kanpur, Uttar Prad",
  //     ride_date: "2023-10-28",
  //     distance: "6.2 km",
  //     ride_status: "Active",
  //     ride_time: "13:33:31",
  //     schedule_time: "13:33:31",
  //     schedule_date: "2023-11-21",
  //     bid_count: 0,
  //     payment_id: "",
  //     amount: "",
  //     driver_ride_start: null,
  //     driver_ride_end: null,
  //     driver_bid_amount: null,
  //     return_type: true,
  //   },
  // ];
  // pending

  // const ridesA = [
  //   {
  //     ride_id: 3365,
  //     customer_id: 58,
  //     pickup_address: "26.4305271,80.299717",
  //     drop_address: "26.4378764,80.3358456",
  //     pickup_address_text:
  //       "C7JX+6W6, Barra 6, Barra World Bank, Barra, Kanpur, Uttar Pradesh 208027, India",
  //     drop_address_text:
  //       "South X Mall Kidwai Nagar Bypass Road, O Block, Marble Market, Kidwai Nagar, Kanpur, Uttar Prad",
  //     ride_date: "2023-10-28",
  //     distance: "6.2 km",
  //     ride_status: "Pending",
  //     ride_time: "13:33:31",
  //     bid_count: 1,
  //     payment_id: "",
  //     amount: "",
  //     driver_ride_start: null,
  //     driver_ride_end: null,
  //     driver_bid_amount: 13900,
  //     schedule_time: "13:39:31",
  //     schedule_date: "2023-11-25",
  //     // schedule_time: "",
  //     // schedule_date: "",
  //   },
  // ];

  return (
    <div className={styles.viewRidesWithMyBids}>
      <div
        style={{
          width: "94%",
          display: "flex",
          justifyContent: "space-between",
          margin: "0 auto",
          marginBottom: "35px",
        }}
      >
        <p
          style={{
            fontSize: "2.8rem",
            fontWeight: "600",
            backgroundColor: "green",
            width: "100%",
            textAlign: "center",
            padding: "0.5rem 0",
            borderRadius: "5px",
          }}
        >
          Advance Rides
        </p>
      </div>

      <div className={styles.bidContainer}>
        {!loadingGeoLoc &&
          Array.isArray(bidConfirmedRideList) &&
          bidConfirmedRideList.length > 0 &&
          bidConfirmedRideList.map(
            (eachRide) =>
              !(eachRide?.ride_status === RIDE_STATUS_CANCELLED) && (
                <SingleBidDetailCard
                  scheduleTime={eachRide?.schedule_time}
                  scheduleDate={eachRide?.schedule_date}
                  key={eachRide?.ride_id}
                  rideId={eachRide?.ride_id}
                  customerId={eachRide?.customer_id}
                  distance={eachRide?.distance}
                  originAddress={eachRide?.pickup_address}
                  destinationAddress={eachRide?.drop_address}
                  originAddressText={eachRide?.pickup_address_text}
                  destinationAddressText={eachRide?.drop_address_text}
                  rideTime={eachRide?.ride_time}
                  bidPlaced={eachRide?.ride_status === RIDE_STATUS_PENDING}
                  bidAcceptedBool={
                    eachRide?.ride_status === RIDE_STATUS_BID_CONFIRMED
                  }
                  numberOfBids={
                    eachRide?.bid_count === 0 ? "No" : eachRide?.bid_count
                  }
                  driverBidAmount={eachRide?.driver_bid_amount}
                  removeGoToDetailsArrow={
                    eachRide?.ride_status === RIDE_STATUS_BID_CONFIRMED ||
                    eachRide?.ride_status === RIDE_STATUS_IN_PROGRESS ||
                    eachRide?.ride_status === RIDE_STATUS_PENDING ||
                    eachRide?.ride_status === RIDE_STATUS_ACTIVE
                  }
                  rideInProgressBool={
                    eachRide?.ride_status === RIDE_STATUS_IN_PROGRESS
                  }
                  driverCurrentLocation={location}
                  rideActiveBool={eachRide?.ride_status === RIDE_STATUS_ACTIVE}
                  rideInPendingBool={
                    eachRide?.ride_status === RIDE_STATUS_PENDING
                  }
                />
              )
          )}
        {!loadingGeoLoc &&
          Array.isArray(pendingRideList) &&
          pendingRideList.length > 0 &&
          pendingRideList.map(
            (eachRide) =>
              !(eachRide?.ride_status === RIDE_STATUS_CANCELLED) && (
                <SingleBidDetailCard
                  scheduleTime={eachRide?.schedule_time}
                  scheduleDate={eachRide?.schedule_date}
                  scheduleTimeN={eachRide?.schedule_time}
                  key={eachRide?.ride_id}
                  rideId={eachRide?.ride_id}
                  customerId={eachRide?.customer_id}
                  distance={eachRide?.distance}
                  originAddress={eachRide?.pickup_address}
                  destinationAddress={eachRide?.drop_address}
                  originAddressText={eachRide?.pickup_address_text}
                  destinationAddressText={eachRide?.drop_address_text}
                  rideTime={eachRide?.ride_time}
                  bidPlaced={eachRide?.ride_status === RIDE_STATUS_PENDING}
                  bidAcceptedBool={
                    eachRide?.ride_status === RIDE_STATUS_BID_CONFIRMED
                  }
                  numberOfBids={
                    eachRide?.bid_count === 0 ? "No" : eachRide?.bid_count
                  }
                  driverBidAmount={eachRide?.driver_bid_amount}
                  removeGoToDetailsArrow={
                    eachRide?.ride_status === RIDE_STATUS_BID_CONFIRMED ||
                    eachRide?.ride_status === RIDE_STATUS_IN_PROGRESS ||
                    eachRide?.ride_status === RIDE_STATUS_PENDING ||
                    eachRide?.ride_status === RIDE_STATUS_ACTIVE
                  }
                  rideInProgressBool={
                    eachRide?.ride_status === RIDE_STATUS_IN_PROGRESS
                  }
                  driverCurrentLocation={location}
                  rideActiveBool={eachRide?.ride_status === RIDE_STATUS_ACTIVE}
                  rideInPendingBool={
                    eachRide?.ride_status === RIDE_STATUS_PENDING
                  }
                />
              )
          )}
        {!loadingGeoLoc &&
          Array.isArray(rideList) &&
          rideList.length > 0 &&
          rideList.map(
            (eachRide) =>
              !(eachRide?.ride_status === RIDE_STATUS_CANCELLED) && (
                <SingleBidDetailCardPlaceBid
                  scheduleTime={eachRide?.schedule_time}
                  scheduleDate={eachRide?.schedule_date}
                  key={eachRide?.ride_id}
                  rideId={eachRide?.ride_id}
                  customerId={eachRide?.customer_id}
                  distance={eachRide?.distance}
                  originAddress={eachRide?.pickup_address}
                  destinationAddress={eachRide?.drop_address}
                  originAddressText={eachRide?.pickup_address_text}
                  destinationAddressText={eachRide?.drop_address_text}
                  rideTime={eachRide?.ride_time}
                  bidPlaced={eachRide?.ride_status === RIDE_STATUS_PENDING}
                  bidAcceptedBool={
                    eachRide?.ride_status === RIDE_STATUS_BID_CONFIRMED
                  }
                  numberOfBids={
                    eachRide?.bid_count === 0 ? "No" : eachRide?.bid_count
                  }
                  driverBidAmount={eachRide?.driver_bid_amount}
                  removeGoToDetailsArrow={
                    eachRide?.ride_status === RIDE_STATUS_BID_CONFIRMED ||
                    eachRide?.ride_status === RIDE_STATUS_IN_PROGRESS ||
                    eachRide?.ride_status === RIDE_STATUS_PENDING ||
                    eachRide?.ride_status === RIDE_STATUS_ACTIVE
                  }
                  rideInProgressBool={
                    eachRide?.ride_status === RIDE_STATUS_IN_PROGRESS
                  }
                  driverCurrentLocation={location}
                  rideActiveBool={eachRide?.ride_status === RIDE_STATUS_ACTIVE}
                  rideInPendingBool={
                    eachRide?.ride_status === RIDE_STATUS_PENDING
                  }
                  showSuggestionAcceptRideView={true}
                  returnTrip={eachRide?.return_type || false}
                />
              )
          )}
        {Array.isArray(rideList) && rideList.length !== 0 && (
          <>
            <div
              style={{
                height: "120px", //
              }}
            ></div>
          </>
        )}
        {/* {Array.isArray(rideList) && rideList.length === 0 && ( */}
        {/* <> */}
        {/* <ErrorInNumber displayText="No active rides in this area" /> */}
        {/* {<HomescreenGoogleMap />} */}
        {/* </> */}
        {/* )} */}
        {
          <FloatingActionButton
            // styleOptions={{ rightAligned: true }}
            externalStyles={{
              bottom: "95px",
              right: "15px",
            }}
            displayText="View Current Rides"
            floatingButtonOnClick={() => {
              //
              navigate(`${DRIVER_VIEW_RIDES_MY_BIDS_ROUTE}`);
            }}
          />
        }
      </div>
      <BottomAd jsonDataArray={bottomAd} />
    </div>
  );
};

export default AdvViewRidesWithMyBids;

const ADV_THRESHOLD_AMOUNT_SHOW_OVERDUE = 1000; //! 1000 (paise) = ₹10

const OverduePopupAdv = () => {
  const [overdueAmount, setOverdueAmount] = useState({
    status: "loading",
    dueAmount: 0,
  });

  // useEffect(() => {
  //   (async () => {
  //     setOverdueAmount((prev) => {
  //       return { ...prev, status: "loading" };
  //     });
  //     try {
  //       const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  //       const resposne = await fetch(
  //         `${BASE_URL}${ADV_OVERDUE_POPUP_DRIVER_PROFILE_DETAILS}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${accessToken}`,
  //           },
  //         }
  //       );

  //       const data = await resposne.json();

  //       const amount = data?.due_amount;

  //       setOverdueAmount((prev) => {
  //         return { ...prev, status: "success", dueAmount: amount };
  //       });
  //     } catch (error) {
  //       setOverdueAmount((prev) => {
  //         return { ...prev, status: "failed" };
  //       });
  //     }
  //   })();
  // }, []);
  // the above function will not be used now since the overdue is linked to same

  //   const navaigateToAddBidSecurity = useNavigateOnEvent(
  //     ADV_DRIVER_BID_SECURITY_CC_AVENUE,
  //     false,
  //     { overdue_amount: overdueAmount.dueAmount }
  //   );

  const not_available = isNaN(parseFloat(overdueAmount.dueAmount).toFixed(1));
  const zero_amount = parseFloat(overdueAmount.dueAmount).toFixed(1) === "0.0";

  return (
    <>
      {overdueAmount.status === "success" &&
        !zero_amount &&
        parseInt(overdueAmount.dueAmount) >=
          ADV_THRESHOLD_AMOUNT_SHOW_OVERDUE && (
          <div className={styles.overduePopup}>
            <div className={styles.modalBackdrop}>
              <div className={styles.visibleModal}>
                <h1
                  style={{
                    textAlign: "center",
                    margin: "0",
                    marginTop: "1rem",
                    marginBottom: "0.6rem",
                    fontSize: "3rem",
                    fontWeight: "700",
                    textTransform: "uppercase",
                    letterSpacing: "0.025px",
                  }}
                >
                  Overdue Amount
                </h1>
                <p
                  className={`${styles.amountDisplay} ${
                    !zero_amount ? styles.amountRed : ""
                  }`}
                >
                  {not_available
                    ? "NA"
                    : !zero_amount
                    ? `-₹${parseFloat(overdueAmount.dueAmount / 100).toFixed(
                        1
                      )}`
                    : "0"}
                </p>
                {!zero_amount && (
                  <p
                    style={{
                      marginTop: "1.2rem",
                    }}
                    className={`${styles.addMoneyText}`}
                  >
                    Thanks for completing ride
                  </p>
                )}
                {!zero_amount && (
                  <p className={`${styles.addMoneyText}`}>
                    Please clear your dues to continue receiving rides
                  </p>
                )}
                {!zero_amount && (
                  <div
                    style={{
                      marginTop: "1.4rem",
                    }}
                  >
                    <Button
                      style={{
                        fontSize: "1.8rem",
                        padding: "1rem 0",
                      }}
                      displayText="Add Money"
                      //   onClickFunction={navaigateToAddBidSecurity}
                    />
                  </div>
                )}
                {
                  <a
                    className={`${styles.addMoneyText}`}
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      marginTop: "1.2rem",
                      letterSpacing: "0.025px",
                    }}
                    href={`tel:${SUPPORT_MOBILE_NUMBERS[0]}`}
                  >
                    Support WhatsApp no-{SUPPORT_MOBILE_NUMBERS[0]}
                  </a>
                }
              </div>
            </div>
          </div>
        )}
    </>
  );
};

const SingleBidDetailCardPlaceBid = ({
  rideId,
  customerId,
  distance = "NA",
  duration = "NA",
  originAddress,
  removeGoToDetailsArrow = false,
  rideTime = "23:54:05",
  originAddressText = "NA",
  destinationAddressText = "NA",
  bidPlaced = false,
  bidAcceptedBool = false,
  numberOfBids = 1,
  driverBidAmount = 50,
  driverCurrentLocation,
  rideInProgressBool = false,
  rideActiveBool = false,
  rideInPendingBool = false,
  boolUpliftDirectionResponse = false,
  upliftDirectionResponse,
  upliftDirectionServiceState,
  showSuggestionAcceptRideView = false,
  scheduleTime = "23:54:05",
  scheduleDate,
  returnTrip = false,
}) => {
  const dispatch = useDispatch();
  const navigateToDriverViewRides = useNavigateOnEvent(
    ADV_DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
    false
  );

  const [pickupDistance, setPickupDistance] = useState("");
  const [pickupTime, setPickupTime] = useState("");

  const [directionResponseResult, setDirectionResponseResult] = useState("");

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const [driectionServiceState, setDriectionServiceState] = useState();

  useEffect(() => {
    if (isLoaded) {
      // eslint-disable-next-line no-undef
      const directionService = () => new google.maps.DirectionsService();
      setDriectionServiceState(directionService);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (!originAddress) {
      navigateToDriverViewRides();
      return;
    }

    const [pickupLat, pickupLng] = originAddress.split(",");

    const latLng = { lat: parseFloat(pickupLat), lng: parseFloat(pickupLng) };

    const calculateRoute = async () => {
      if (!driectionServiceState) return;
      const resultsDistanceDuration = await driectionServiceState.route({
        origin: {
          lat: driverCurrentLocation.latitude,
          lng: driverCurrentLocation.longitude,
        },
        destination: latLng,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      });
      // console.log(resultsDistanceDuration);
      if (boolUpliftDirectionResponse) {
        setDirectionResponseResult(resultsDistanceDuration);
      }
      setPickupDistance(
        resultsDistanceDuration.routes[0].legs[0].distance.text
      );
      setPickupTime(resultsDistanceDuration.routes[0].legs[0].duration.text);
    };

    calculateRoute();
  }, [driectionServiceState, driverCurrentLocation, originAddress]);

  const navigateToMeetAtPickupPoint = useNavigateOnEvent(
    ADV_DRIVER_BID_ACCEPTED_START_RIDE_ROUTE
  );

  const navigateToInProgressEndRide = useNavigateOnEvent(
    ADV_DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE
  );

  const navigateToViewRideDetails = useNavigateOnEvent(
    ADV_DRIVER_PLACE_A_BID_ROUTE,
    false,
    { eta_time: pickupTime, eta_distance: pickupDistance }
  );
  // calculating display date
  const [displayTime, setDisplayTime] = useState("");
  useEffect(() => {
    if (scheduleTime === "now") {
      const time = new Date();
      const options = { hour: "numeric", minute: "numeric", hour12: true };
      const formattedTime = time.toLocaleTimeString("en-US", options);

      setDisplayTime(formattedTime);
      return;
    }
    const now = new Date();
    const [hours, minutes, seconds] = scheduleTime.split(":");
    const time = new Date();
    time.setHours(hours);
    time.setMinutes(minutes);
    time.setSeconds(seconds);
    //comparing
    const isToday =
      now.getDate() === time.getDate() &&
      now.getMonth() === time.getMonth() &&
      now.getFullYear() === time.getFullYear();
    //formatting time
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedTime = time.toLocaleTimeString("en-US", options);
    // if today
    if (isToday) {
      console.log(
        "SingleBidDetailCardPlaceBid.js, Formatted time",
        formattedTime
      );
      setDisplayTime(formattedTime);
    }
  }, [scheduleTime]);

  const navigateToMeetAtPickupPointHandler = useCallback(() => {
    dispatch(driverAllRideAdvActions.setSelectedRide(rideId));
    navigateToMeetAtPickupPoint();
  }, [dispatch, rideId, navigateToMeetAtPickupPoint]);

  // const navigateToInProgressEndRideHandler = () => {
  //   dispatch(driverAllRideAdvActions.setSelectedRide(rideId));
  //   navigateToInProgressEndRide();
  // };

  const navigateToInProgressEndRideHandler = useCallback(() => {
    dispatch(driverAllRideAdvActions.setSelectedRide(rideId));
    navigateToInProgressEndRide();
  }, [dispatch, rideId, navigateToInProgressEndRide]);

  //
  const onClickHandler = (viewDetailsBool = false) => {
    console.log("ff");
    console.log(showSuggestionAcceptRideView, viewDetailsBool);
    if (showSuggestionAcceptRideView && !viewDetailsBool) return;

    console.log("SingleBidDetailCardPlaceBid.js, Value of bidAcceptedBool: ");
    console.log(bidAcceptedBool);
    console.log(
      "SingleBidDetailCardPlaceBid.js, Value of rideInProgressBool: "
    );
    console.log(rideInProgressBool);

    if (bidAcceptedBool && !rideInProgressBool) {
      console.log("Navigate to meet at pickup point screen...");
      navigateToMeetAtPickupPointHandler();
      return;
    }

    if (!bidAcceptedBool && rideInProgressBool) {
      console.log(
        "SingleBidDetailCardPlaceBid.js, Navigate to In progress screen..."
      );
      navigateToInProgressEndRideHandler();
      return;
    }

    if (rideActiveBool || rideInPendingBool) {
      console.log(
        "SingleBidDetailCardPlaceBid.js, Navigate to View ride details screen..."
      );
      dispatch(driverAllRideAdvActions.setSelectedRide(rideId));
      navigateToViewRideDetails();
    }
  };

  useEffect(() => {
    if (bidAcceptedBool && !rideInProgressBool) {
      console.log(
        "SingleBidDetailCardPlaceBid.js, Automatic Navigate to meet at pickup point screen..."
      );
      if (!navigator.serviceWorker.controller) {
        dispatch(driverAllRideAdvActions.updateBidConfirmedRides([]));
        navigateToMeetAtPickupPointHandler();
        return;
      }

      const messageChannel = new MessageChannel();
      navigator.serviceWorker.controller.postMessage(
        {
          type: "INIT_PORT",
        },
        [messageChannel.port2]
      );

      messageChannel.port1.onmessage = function (event) {
        console.log("event in main thread", event);
        if (event.data.type === "PORT_INITIALISED") {
          navigator.serviceWorker.controller.postMessage({
            type: "START_BACKGROUND_LOCATION_UPDATE",
          });
          dispatch(driverAllRideAdvActions.updateBidConfirmedRides([]));
          navigateToMeetAtPickupPointHandler();
        }
      };

      return;
    }
  }, [bidAcceptedBool, rideInProgressBool]);

  useEffect(() => {
    if (boolUpliftDirectionResponse && directionResponseResult) {
      upliftDirectionResponse(directionResponseResult);
    }
  }, [
    boolUpliftDirectionResponse,
    upliftDirectionResponse,
    directionResponseResult,
  ]);

  useEffect(() => {
    if (boolUpliftDirectionResponse && driectionServiceState) {
      upliftDirectionServiceState(driectionServiceState);
    }
  }, [
    driectionServiceState,
    upliftDirectionServiceState,
    boolUpliftDirectionResponse,
  ]);

  return (
    <div
      className={` ${styles.singleBidDetailCardPlaceBid} ${
        !showSuggestionAcceptRideView ? styles.activateTopBottomPadding : ""
      }  ${bidPlaced && styles.bidPlacedWaiting} ${
        bidAcceptedBool && styles.bidAccepted
      } ${rideInProgressBool && styles.bidAccepted} ${
        rideActiveBool && styles.animationFlash
      }
        `}
      onClick={() => {
        onClickHandler();
      }}
    >
      {/* <CurrentStateTimePriceBids
          rideId={rideId}
          rideTime={displayTime}
          bidPlacedWaiting={bidPlaced}
          numberOfBids={numberOfBids}
          driverBidAmount={driverBidAmount}
          bidAccepted={bidAcceptedBool || rideInProgressBool}
          rideActiveBool={rideActiveBool}
        /> */}
      {/* <div></div> */}
      <DistancePickupTimeOriginDestinationPlaceBid
        scheduleTime={scheduleTime}
        scheduleDate={scheduleDate}
        rideId={rideId}
        customerId={customerId}
        originAddress={originAddressText}
        destinationAddress={destinationAddressText}
        removeGoToDetailsArrow={removeGoToDetailsArrow}
        pickupPoint={pickupDistance}
        pickupTime={pickupTime}
        totalRideDistance={distance}
        returnTrip={returnTrip}
      />
      <div></div>
      {showSuggestionAcceptRideView && (
        <SuggestionAcceptActiveRideView
          // onViewDetailsClickHandler={() => {
          //   onClickHandler(true);
          // }}
          rideId={rideId}
          etaTime={pickupTime}
          etaDistance={pickupDistance}
          returnTrip={returnTrip}
        />
      )}
    </div>
  );
};

const DistancePickupTimeOriginDestinationPlaceBid = ({
  rideId,
  customerId,
  removeGoToDetailsArrow = false,
  originAddress,
  destinationAddress,
  totalRideDistance,
  pickupPoint,
  pickupTime,
  scheduleDate,
  scheduleTime,
  returnTrip = false,
}) => {
  function convertDateFormat(scheduleDate) {
    const [year, month, day] = scheduleDate.split("-");
    if ((year || month || day) === undefined) return;
    return `${day}-${month}-${year}`;
  }
  function convertTo12HourFormat(time) {
    const [hours, minutes, seconds] = time.split(":");
    let period = "AM";
    let hour = parseInt(hours, 10);

    if (hour >= 12) {
      period = "PM";
      if (hour > 12) {
        hour -= 12;
      }
    }

    return `${hour}:${minutes} ${period}`;
  }
  const dispatch = useDispatch();

  const navigateToViewRideDetails = useNavigateOnEvent(
    ADV_DRIVER_PLACE_A_BID_ROUTE
  );

  const onViewDetailBidHandler = () => {
    dispatch(driverAllRideAdvActions.setSelectedRide(rideId));
    navigateToViewRideDetails();
  };
  return (
    <div className={styles.distancePickupTimeOriginDestination}>
      <div className={styles.upper}>
        <p
          style={{
            fontSize: "2.2rem",
          }}
          className={styles.totalRideDistance}
        >
          {returnTrip ? (
            <span
              style={{
                fontSize: "1.6rem",
              }}
            >
              One way kms:{" "}
              <span
                style={{
                  fontSize: "2.4rem",
                }}
              >
                {totalRideDistance}
              </span>
            </span>
          ) : (
            `Total ride distance : ${totalRideDistance}`
          )}

          <span
            style={{
              fontSize: "2.2rem",
              color: "",
            }}
          >
            {returnTrip ? `, Return trip, ${HOURS_12}` : ""}
          </span>
        </p>
        <p
          className={`${styles.totalRideDistance} ${styles.flashingScheduleTime}`}
        >
          Date: {convertDateFormat(scheduleDate)}&nbsp;&nbsp;,&nbsp;&nbsp;
          Time:&nbsp;
          {convertTo12HourFormat(scheduleTime)}
        </p>
        <div className={styles.pickupPointAwayTime}>
          <p className={styles.pickupPoint}>Pickup Point: {pickupPoint}</p>
          <hr className={styles.hrLine} />
          <p className={styles.pickupTime}>{pickupTime} away</p>
        </div>
        <hr className={styles.longHrLine} />
      </div>
      <div className={styles.lower}>
        <div className={styles.left}>
          <p className={styles.adedressParaPickup}>
            <span className={`${styles.dot} ${styles.green}`}></span>
            {originAddress && originAddress}
          </p>
          <p className={styles.adedressPara}>
            <span className={`${styles.dot} ${styles.red}`}></span>
            {destinationAddress && destinationAddress}
          </p>
        </div>
        <div className={styles.right} onClick={onViewDetailBidHandler}>
          {!removeGoToDetailsArrow && (
            <img
              className={styles.arrowBackWhite}
              src={images.arrowBackWhite}
              alt="arrow right"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const SuggestionAcceptActiveRideView = ({
  // onViewDetailsClickHandler,
  rideId,
  etaTime,
  etaDistance,
  returnTrip,
}) => {
  const [suggestedData, setSuggestedData] = useState({
    incrementAmount: 0,
    decrementAmount: 0,
    upperLimit: 0,
    lowerLimit: 0,
    suggestedAmount: 0,
  });

  useEffect(() => {
    (async () => {
      try {
        setPlacingBid("loading");
        const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
        const url = `${BASE_URL}${ADV_NEW_FAIR_VIEW}`;
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            ride_id: rideId,
          }),
        };

        const response = await fetch(url, options);

        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setSuggestedData({
            decrementAmount: data?.decrement_amount,
            incrementAmount: data?.increment_amount,
            lowerLimit: data?.lower_limit,
            upperLimit: data?.upper_limit,
            suggestedAmount: data?.suggestion_amount,
          });
          setBidAmount(data?.suggestion_amount);
        }
      } catch (error) {
      } finally {
        setPlacingBid("idle");
      }
    })();
  }, []);

  const [bidAmount, setBidAmount] = useState(
    () => suggestedData.suggestedAmount
  );

  const [placingBid, setPlacingBid] = useState("idle"); // idle | loading | failed | success

  // const incrementAmountHandler = () => {
  //   setBidAmount((prev) => {
  //     if (prev + suggestedData.incrementAmount > suggestedData.upperLimit) {
  //       return prev;
  //     }

  //     if (prev + suggestedData.incrementAmount <= suggestedData.upperLimit) {
  //       return prev + suggestedData.incrementAmount;
  //     }
  //   });
  // };

  // const decrementAmountHandler = () => {
  //   setBidAmount((prev) => {
  //     if (prev - suggestedData.decrementAmount < suggestedData.lowerLimit) {
  //       return prev;
  //     }

  //     if (prev - suggestedData.decrementAmount >= suggestedData.lowerLimit) {
  //       return prev - suggestedData.decrementAmount;
  //     }
  //   });
  // };

  const onAccept = async () => {
    setPlacingBid("loading");
    try {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      const url = `${BASE_URL}${ADV_DRIVER_VIEW_ALL_BIDS}`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          ride: rideId,
          amount: (parseInt(bidAmount) * 100).toString(),
          eta_time: `${etaTime} `,
          eta_distance: `${etaDistance}`,
        }),
      };

      const response = await fetch(url, options);

      if (response.ok) {
        const data = await response.json();
        if (data && data?.code === CODE_200) {
          setPlacingBid("success");
        }
      }
    } catch (error) {
      setPlacingBid("failed");
      console.log(error);
      toast.error("Error occured");
    }
  };

  return (
    <div className={styles.suggestionAcceptActiveRideView}>
      <hr className={styles.longHrLine} />
      <div className={styles.suggestedDataAcceptLine}>
        <div className={styles.suggestedAmountsExtras}>
          {placingBid !== "failed" &&
            placingBid !== "success" &&
            placingBid !== "loading" && (
              <>
                {/* <button
                  onClick={decrementAmountHandler}
                  className={`${styles.buttonGeneral} ${
                    styles.incrementDecrementAmountButton
                  } ${
                    bidAmount - suggestedData.decrementAmount <
                    suggestedData.lowerLimit
                      ? styles.disabledButton
                      : ""
                  }`}
                >
                  {`-${suggestedData.decrementAmount}`}
                </button> */}
                <span
                  className={`${styles.buttonGeneral} ${styles.suggestedAmount}`}
                >
                  {`₹${bidAmount}`}&nbsp;
                  <span
                    style={{
                      fontSize: "2.2rem",
                    }}
                  >
                    {" "}
                    + Toll extra
                  </span>
                </span>
                {/* <button
                  onClick={incrementAmountHandler}
                  className={`${styles.buttonGeneral} ${
                    styles.incrementDecrementAmountButton
                  } ${
                    bidAmount + suggestedData.incrementAmount >
                    suggestedData.upperLimit
                      ? styles.disabledButton
                      : ""
                  }`}
                >
                  {`+${suggestedData.incrementAmount}`}
                </button> */}
              </>
            )}
          {placingBid === "loading" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
              }}
              className={`${styles.buttonGeneral} ${styles.acceptPlaceBidButton}`}
            >
              <LoadingSpinner />
            </div>
          )}
          {placingBid === "success" && (
            <p
              style={{ color: "black" }}
              className={`${styles.buttonGeneral} ${styles.acceptPlaceBidButton}`}
            >
              Bid Placed
            </p>
          )}
          {placingBid === "idle" && (
            <button
              onClick={onAccept}
              className={`${styles.buttonGeneral} ${styles.acceptPlaceBidButton}`}
            >
              Accept
            </button>
          )}
        </div>
      </div>
      {returnTrip ? (
        <React.Fragment>
          <p
            style={{
              fontSize: "1.8rem",
              // color: "red",
              textAlign: "center",
              width: "75%",
            }}
          >
            *Extra Hrs Mini- ₹200/hr, Sedan- ₹215/hr, Suv- ₹260/hr
          </p>
          <p
            style={{
              fontSize: "1.8rem",
              // color: "red",
            }}
          >
            *Extra Mini- ₹10/km, Sedan- ₹12/km, Suv- ₹14/km
          </p>
        </React.Fragment>
      ) : (
        <></>
      )}
      {/* <button
          onClick={onViewDetailsClickHandler}
          className={`${styles.viewDetailsButton}`}
        >
          Enter Amount
        </button> */}
    </div>
  );
};
