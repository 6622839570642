import React from "react";
import { images } from "../../images";
// eslint-disable-next-line
import styles from "./DistancePickupTimeOriginDestinationPlaceBid.module.css";
import { useDispatch } from "react-redux";
import { driverAllRideActions } from "../../store/slice";
import { useNavigateOnEvent } from "../../hooks";
import { DRIVER_PLACE_A_BID_ROUTE } from "../../constants/routeConstants";

const DistancePickupTimeOriginDestination = ({
  rideId,
  customerId,
  removeGoToDetailsArrow = false,
  originAddress,
  destinationAddress,
  totalRideDistance,
  pickupPoint,
  pickupTime,
  commissionRate,
}) => {
  const dispatch = useDispatch();

  const navigateToViewRideDetails = useNavigateOnEvent(
    DRIVER_PLACE_A_BID_ROUTE
  );

  const onViewDetailBidHandler = () => {
    dispatch(driverAllRideActions.setSelectedRide(rideId));
    navigateToViewRideDetails();
  };

  return (
    <div className={styles.distancePickupTimeOriginDestination}>
      <div className={styles.upper}>
        <div className={styles.distance}>
          <p className={styles.totalRideDistance}>
            Ride distance: {totalRideDistance}
          </p>
          {commissionRate <= 0 && (
            <p className={styles.commission}>0 Commission Trip</p>
          )}
        </div>
        <div className={styles.pickupPointAwayTime}>
          <p className={styles.pickupPoint}>Pickup Point: {pickupPoint}</p>
          <hr className={styles.hrLine} />
          <p className={styles.pickupTime}>{pickupTime} away</p>
        </div>
        <hr className={styles.longHrLine} />
      </div>
      <div className={styles.lower}>
        <div className={styles.left}>
          <p className={styles.adedressParaPickup}>
            <span className={`${styles.dot} ${styles.green}`}></span>
            {originAddress && originAddress}
          </p>
          <p className={styles.adedressPara}>
            <span className={`${styles.dot} ${styles.red}`}></span>
            {destinationAddress && destinationAddress}
          </p>
        </div>
        <div className={styles.right} onClick={onViewDetailBidHandler}>
          {!removeGoToDetailsArrow && (
            <img
              className={styles.arrowBackWhite}
              src={images.arrowBackWhite}
              alt="arrow right"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DistancePickupTimeOriginDestination;
