import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { ACCESS_TOKEN_COOKIE_NAME } from "../constants/storageConstants";
import {
  BASE_URL,
  DRIVER_START_RIDE,
  DRIVER_VIEW_ACCEPTED_CUSTOMER_BID,
} from "../constants/apiConstants";
import { useNavigateOnEvent } from "./use-navigateOnEvent";
import { DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE } from "../constants/routeConstants";
import { driverCurrentRideActions } from "../store/slice";

const useDriverFetchRideDetails = () => {
  const dispatch = useDispatch();

  const navigateToEndRideScreen = useNavigateOnEvent(
    DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE,
    true
  );
  const [rideDetails, setRideDetails] = useState({});
  const selectedRideId = useSelector(
    (state) => state.driverAllRide.selectedRide
  );
  // const selectedRideId = 4;

  const fetchRideDetails = () => {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    fetch(`${BASE_URL}${DRIVER_VIEW_ACCEPTED_CUSTOMER_BID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: selectedRideId,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        console.log("use-driver-fetch-ride-details.js, Data received", data);
        const [lat, lng] = data?.ride_details?.pickup_address
          .split(",")
          .map(parseFloat);
        const [dlat, dlng] = data?.ride_details?.drop_address
          .split(",")
          .map(parseFloat);

        setRideDetails({
          customerId: data?.ride_details?.customer,
          fullName: data?.name,
          pickupAddressLat: lat,
          pickupAddressLng: lng,
          dropAddressLat: dlat,
          dropAddressLng: dlng,
          pickupAddressText: data?.ride_details?.pickup_address_text,
          dropAddressText: data?.ride_details?.drop_address_text,
          phoneNumber: data?.phone_number,
          // amount: data?.ride_details?.amount,
          amount: data?.amount_payable,
          paymentType: data?.payment_type,
          totalAmount: data?.total_amount,
          taxiwarsCommissionAmount: data?.taxiwars_commission_amount,
          commissionCollectedByDriver: data?.commission_collected_by_driver,
        });

        dispatch(
          driverCurrentRideActions.setCurrentRideDetails({
            customerId: data?.ride_details?.customer,
            fullName: data?.name,
            pickupAddressLat: lat,
            pickupAddressLng: lng,
            dropAddressLat: dlat,
            dropAddressLng: dlng,
            pickupAddressText: data?.ride_details?.pickup_address_text,
            dropAddressText: data?.ride_details?.drop_address_text,
            phoneNumber: data?.phone_number,
            // amount: data?.ride_details?.amount,
            amount: data?.amount_payable,
            paymentType: data?.payment_type,
            totalAmount: data?.total_amount,
            taxiwarsCommissionAmount: data?.taxiwars_commission_amount,
            commissionCollectedByDriver: data?.commission_collected_by_driver,
          })
        );
        dispatch(driverCurrentRideActions.setCurrentRideId(selectedRideId));
      })
      .catch((e) => {
        console.log(
          "use-driver-fetch-ride-details.js, Error occured in getting accepted ride details",
          e
        );
      });
  };

  useEffect(() => {
    fetchRideDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const windowOpenGoogleMapHandler = (lat, lng) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&travelmode=driving`;
    window.open(url, "_blank");
  };

  const startRideHandler = () => {
    // windowOpenGoogleMapHandler(
    //   rideDetails?.dropAddressLat,
    //   rideDetails?.dropAddressLng
    // );

    // console.log("Start ride function");

    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    fetch(`${BASE_URL}${DRIVER_START_RIDE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: selectedRideId,
      }),
    }).then((response) => {
      if (response.ok) {
        navigateToEndRideScreen();
      }
    });
  };

  return { rideDetails, startRideHandler, windowOpenGoogleMapHandler };
};

export { useDriverFetchRideDetails };
