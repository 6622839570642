import React from "react";
// eslint-disable-next-line
import styles from "./LoginSignSwitchLine.module.css";

const LoginSignSwitchLine = ({
  displayQuestion = "prop-displayQuestion?",
  displayFunctionText = "prop-Click Here",
  pageSwitchFunction = () => {
    console.warn("TODO - Function not connected");
  },
}) => {
  return (
    <>
      <p className={styles.loginSignSwitchLine}>
        {displayQuestion}
        <button
          className={styles.loginSignSwitchLineFunctionButton}
          onClick={pageSwitchFunction}
        >
          {displayFunctionText}
        </button>
      </p>
    </>
  );
};

export default LoginSignSwitchLine;
