import {
  useEffect,
  // useMemo,
  useState,
} from "react";
import {
  //   useGeolocation,
  useInterval,
  useNavigateOnEvent,
  usePreventBackBehaviour,
} from "../../../hooks";
import styles from "./AdvBidAcceptedMeetAtPickupPoint.module.css";
import {
  ADV_DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE,
  ADV_DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
  DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
} from "../../../constants/routeConstants";
import Cookies from "js-cookie";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  PACKAGE_12_HOUR,
} from "../../../constants/storageConstants";
import {
  ADV_DRIVER_GET_RIDE_STATUS,
  ADV_DRIVER_START_RIDE,
  ADV_DRIVER_VIEW_ACCEPTED_CUSTOMER_BID,
  BASE_URL,
  RIDE_STATUS_CANCELLED,
} from "../../../constants/apiConstants";
import {
  //   LOCATION_UPDATE_TIME_IN_MILLI_SECOND,
  THIRTY_SECONDS,
} from "../../../constants/timeConstants";
import {
  Button,
  ErrorInNumber,
  LogsBottomModal,
  MenuTextUserWrapper,
  RiderNamePickupDropCancelCard,
  SosButton,
  TopGreenCheckStatus,
} from "../../../components";
import { images } from "../../../images";
import OtpInput from "react18-input-otp";
import { useDispatch, useSelector } from "react-redux";
import { driverCurrentRideAdvActions } from "../../../store/slice";
import { useNavigate } from "react-router-dom";

const AdvBidAcceptedMeetAtPickupPoint = () => {
  usePreventBackBehaviour();

  const [isRideCancelledDialogOpen, setIsRideCancelledDialogOpen] =
    useState(false);

  //use fetch rider details
  //
  const dispatch = useDispatch();

  const navigateToEndRideScreen = useNavigateOnEvent(
    ADV_DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE,
    true
  );

  const [rideDetails, setRideDetails] = useState({});
  const selectedRideId = useSelector(
    (state) => state.driverAllRideAdv.selectedRide
  );
  // const selectedRideId = 4;

  useEffect(() => {
    (() => {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      fetch(`${BASE_URL}${ADV_DRIVER_VIEW_ACCEPTED_CUSTOMER_BID}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          ride_id: selectedRideId,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          console.log("use-driver-fetch-ride-details.js, Data received", data);
          const [lat, lng] = data?.ride_details?.pickup_address
            .split(",")
            .map(parseFloat);
          const [dlat, dlng] = data?.ride_details?.drop_address
            .split(",")
            .map(parseFloat);

          setRideDetails({
            customerId: data?.ride_details?.customer,
            fullName: data?.name,
            pickupAddressLat: lat,
            pickupAddressLng: lng,
            dropAddressLat: dlat,
            dropAddressLng: dlng,
            pickupAddressText: data?.ride_details?.pickup_address_text,
            dropAddressText: data?.ride_details?.drop_address_text,
            rideDistance: data?.ride_details?.distance,
            phoneNumber: data?.phone_number,
            // amount: data?.ride_details?.amount,
            amount: data?.amount_payable,
            paymentType: data?.payment_type,
            totalAmount: data?.total_amount,
            taxiwarsCommissionAmount: data?.taxiwars_commission_amount,
            commissionCollectedByDriver: data?.commission_collected_by_driver,
            scheduleDate: data?.schedule_date,
            scheduleTime: data?.schedule_time,
            returnType: data?.return_type,
          });

          dispatch(
            driverCurrentRideAdvActions.setCurrentRideDetails({
              customerId: data?.ride_details?.customer,
              fullName: data?.name,
              pickupAddressLat: lat,
              pickupAddressLng: lng,
              dropAddressLat: dlat,
              dropAddressLng: dlng,
              pickupAddressText: data?.ride_details?.pickup_address_text,
              dropAddressText: data?.ride_details?.drop_address_text,
              phoneNumber: data?.phone_number,
              // amount: data?.ride_details?.amount,
              amount: data?.amount_payable,
              paymentType: data?.payment_type,
              totalAmount: data?.total_amount,
              taxiwarsCommissionAmount: data?.taxiwars_commission_amount,
              commissionCollectedByDriver: data?.commission_collected_by_driver,
              scheduleDate: data?.schedule_date,
              scheduleTime: data?.schedule_time,
              returnType: data?.return_type,
            })
          );
          dispatch(
            driverCurrentRideAdvActions.setCurrentRideId(selectedRideId)
          );
        })
        .catch((e) => {
          console.log(
            "use-driver-fetch-ride-details.js, Error occured in getting accepted ride details",
            e
          );
        });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const windowOpenGoogleMapHandler = (lat, lng) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&travelmode=driving`;
    window.open(url, "_blank");
  };
  //
  //use fetch rider details
  //   const { rideDetails, startRideHandler, windowOpenGoogleMapHandler } =
  //     useDriverFetchRideDetails();
  // the above hook was replaced due to

  const navigateToDriverHome = useNavigateOnEvent(
    ADV_DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
    true
  );

  const currentRideId = useSelector(
    (state) => state.driverCurrentRideAdv.currentRideId
  );

  const [setOtpLengthError, setSetOtpLengthError] = useState(false);

  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  const httpPolling = () => {
    fetch(`${BASE_URL}${ADV_DRIVER_GET_RIDE_STATUS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: currentRideId,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((rideStatus) => {
        console.log("Current ride Status", rideStatus);
        // dispatch(
        //   riderCurrentRideActions.setCurrentRideStatus(rideStatus?.Ride_Status)
        // );
        // dispatch(driverCurrentRideAdvActions.)
        if (rideStatus?.Ride_Status === RIDE_STATUS_CANCELLED) {
          //
          // toast.info("Ride was cancelled by the user");
          setIsRideCancelledDialogOpen(true);
        }
      })
      .catch((e) => {
        console.log("Error in retrieving ride status", e);
      });
  };

  useInterval(httpPolling, THIRTY_SECONDS);

  const [otpNumber, setOtpNumber] = useState("");

  const handleOtpChangeForStartRide = (startRideOtp) => {
    setSetOtpLengthError(false);
    setOtpNumber(startRideOtp);
    // console.log("startRideOtp", startRideOtp);
  };

  const startRideHandlerWithOtp = async () => {
    // api call to checjk for correct otp
    if (otpNumber.length !== 4) {
      setSetOtpLengthError(true);
      return;
    }

    console.log("Data sent for start ride: ");
    console.log("currentRideId - otpNumber");
    console.log(currentRideId, "-", otpNumber);
    const response = await fetch(`${BASE_URL}${ADV_DRIVER_START_RIDE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: currentRideId,
        ride_otp: parseInt(otpNumber),
      }),
    });

    if (response.ok) {
      navigateToEndRideScreen();
      //   const data = await response.json();
      return;
    }

    setSetOtpLengthError(true);

    //
  };

  //   const [location, setLocation] = useState();

  //   const options = useMemo(
  //     () => ({
  //       enableHighAccuracy: true,
  //       maximumAge: 1 * 1000,
  //       timeout: LOCATION_UPDATE_TIME_IN_MILLI_SECOND,
  //     }),
  //     []
  //   );

  //   const {
  //     loading: loadingGeoLoc,
  //     error: errorGeoLoc,
  //     data: dataGeoLoc,
  //   } = useGeolocation(options);

  //   useEffect(() => {
  //     if (loadingGeoLoc) return;
  //     if (errorGeoLoc) return;

  //     console.log("BidAcceptedMeetAtPickupPoint.js, Current Driver location: ");
  //     console.log(dataGeoLoc);

  //     setLocation({
  //       latitude: dataGeoLoc.latitude,
  //       longitude: dataGeoLoc.longitude,
  //     });
  //   }, [loadingGeoLoc, errorGeoLoc, dataGeoLoc]);

  // useEffect(() => {
  //   const sendLocationUpdates = () => {
  //     console.log("BidAcceptedMeetAtPickupPoint.js, Sending location...");
  //     const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  //     fetch(` ${BASE_URL}${DRIVER_SEND_CURRENT_LOCATION}`, {
  //       method: "PATCH",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //       body: JSON.stringify({
  //         driver_lat: location?.latitude.toString(),
  //         driver_lng: location?.longitude.toString(),
  //       }),
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           console.log(
  //             "BidAcceptedMeetAtPickupPoint.js, Location updated at server..."
  //           );
  //           return response.json();
  //         }
  //         throw response;
  //       })

  //       .catch((error) =>
  //         console.log("BidAcceptedMeetAtPickupPoint.js, error", error)
  //       );
  //   };

  //   if (location) {
  //     sendLocationUpdates();
  //   }

  //   const intervalLocation = setInterval(() => {
  //     if (location) {
  //       sendLocationUpdates();
  //     }
  //     // can be changed to any time we want
  //   }, LOCATION_UPDATE_TIME_IN_MILLI_SECOND);

  //   return () => clearInterval(intervalLocation);
  // }, [dispatch, location]);
  function convertDateFormat(scheduleDate) {
    const [year, month, day] = scheduleDate.split("-");
    if ((year || month || day) === undefined) return;
    return `${day}-${month}-${year}`;
  }
  function convertTo12HourFormat(time) {
    const [hours, minutes, seconds] = time.split(":");
    let period = "AM";
    let hour = parseInt(hours, 10);

    if (hour >= 12) {
      period = "PM";
      if (hour > 12) {
        hour -= 12;
      }
    }

    return `${hour}:${minutes} ${period}`;
  }

  return (
    <MenuTextUserWrapper logoHeadingText="Congratulations! You won the bid">
      <div className={styles.bidAcceptedMeetAtPickupPoint}>
        <LogsBottomModal
          // para={"This ride is not eligible for refund"}
          heading="This ride has been cancelled by the user"
          // heading="Cancel Ride ?"
          // downloadButtonText={"Yes"}
          // cancelButtonText={"No"}
          openLogDialogBox={isRideCancelledDialogOpen}
          // downloadLogsButton={() => {
          //   cancelButtonOnClickFunctionHandler();
          // }}
          cancelButton={() => {
            setIsRideCancelledDialogOpen(false);
            navigateToDriverHome();
          }}
          para={"Cancel Ride ?"}
          // heading="Cancel Ride ?"
          cancelledRide
        />
        <TopGreenCheckStatus displayText="Meet rider at pickup point." />
        <div className="">
          {
            <div
              style={{
                color: "white",
                fontSize: "1.8rem",
                fontWeight: "bold",
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                gap: "2rem",
              }}
              className={styles.flashingScheduleTime}
            >
              <p className="">
                Date:&nbsp;&nbsp;
                {convertDateFormat(rideDetails?.scheduleDate || "2023-11-01")}
              </p>
              <p className="">
                Time&nbsp;&nbsp;
                {convertTo12HourFormat(rideDetails?.scheduleTime || "12:45:12")}
              </p>
            </div>
          }
        </div>
        <RiderNamePickupDropCancelCard
          fullName={rideDetails?.fullName || "NA"}
          pickupAddressText={rideDetails?.pickupAddressText || "NA"}
          dropAddressText={rideDetails?.dropAddressText || "NA"}
          phoneNumber={rideDetails?.phoneNumber || "NA"}
          // scheduleDate={rideDetails?.scheduleDate}
          // scheduleTime={rideDetails?.scheduleTime}
          isShowAdvance
        />
        <Button
          displayText="पिकअप प्वाइंट का रास्ता"
          onClickFunction={() => {
            windowOpenGoogleMapHandler(
              rideDetails?.pickupAddressLat,
              rideDetails?.pickupAddressLng
            );
          }}
          imageAvailble={images.googleMaps}
          leftImageAvailable={images.click}
          customFont
        />
        <p className={styles.enterOtpMessage}>
          Please ask the customer for OTP to start ride
        </p>
        <OtpInput
          disabledStyle
          isInputNum
          value={otpNumber}
          onChange={handleOtpChangeForStartRide}
          numInputs={4}
          separator={""}
          className={styles.box}
          inputStyle={styles.inputBox}
        />
        {setOtpLengthError && <ErrorInNumber displayText="Incorrect OTP" />}
        <Button
          displayText="Start Ride"
          onClickFunction={startRideHandlerWithOtp}
        />
        {rideDetails?.returnType ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0rem",
              color: "white",
            }}
          >
            <p
              style={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "3rem",
              }}
            >
              {`${PACKAGE_12_HOUR} (2 x ${rideDetails?.rideDistance}) + 10 = ${
                2 * rideDetails?.rideDistance.split(" ")[0]
              } Kms considered`}
            </p>
            <p
              style={{
                fontSize: "1.8rem",
                // color: "red",
                textAlign: "center",
                // width: "75%",
                marginTop: "3rem",
              }}
            >
              *Extra Hrs Mini- ₹200/hr, Sedan- ₹215/hr, Suv- ₹260/hr
            </p>
            <p
              style={{
                fontSize: "1.8rem",
                textAlign: "center",
                marginTop: "3rem",
              }}
            >
              *Extra kms Mini- ₹10/km, Sedan- ₹12/km, Suv- ₹14/km
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
      <BackToHome />
      <SosButton currentRideId={currentRideId} />
    </MenuTextUserWrapper>
  );
};

export default AdvBidAcceptedMeetAtPickupPoint;

const BackToHome = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        zIndex: 5,
        position: "absolute",
        bottom: "calc(4rem + 75px)",
        right: "3rem",
        fontSize: "1.8rem",
      }}
      className={`${styles.floatingAction} ${styles.right}`}
    >
      <button
        onClick={() => {
          navigate(`${DRIVER_VIEW_RIDES_MY_BIDS_ROUTE}`);
          // navigate(`${}`);
        }}
        style={{
          border: "none",
          background: "none",
          // padding: 0,
          margin: 0,
          font: "inherit",
          cursor: "pointer",
          // outline: "inherit",

          /* Custom */
          backgroundColor: "#419c00",
          padding: "1.6rem",
          outline: "none",
          borderRadius: "30px",
          minWidth: "100px",
          color: "#fff",
          fontWeight: " bold",
          // -webkit-tap-highlight-color: transparent;
          /* transition: background-color 0.3s ease-in; */
          userSelect: "none",
        }}
        className={styles.floatingActionButton}
      >
        <p
          style={{
            fontSize: "1.6rem",
          }}
          className=""
        >
          Home
        </p>
        <p
          style={{
            fontSize: "1.4rem",
          }}
        >
          {/* (Outstation) */}
        </p>
      </button>
    </div>
  );
};
