import { createSlice } from "@reduxjs/toolkit";

const driverAllBidSlice = createSlice({
  name: "driverAllBid",
  initialState: {
    bids: [],
  },
  reducers: {
    addAllBids(state, action) {
      state.bids = [...action.payload];
    },
  },
});

export const driverAllBidActions = driverAllBidSlice.actions;
export default driverAllBidSlice;
