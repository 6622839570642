import { useState } from "react";
import { NULL, UNDEFINED } from "../constants/storageConstants";

/**
 * Custom React hook for setting a value to local storage
 * @param {string} key The key to use for storing the value in local storage
 * @returns {[string, function]}  A tuple containing the current value and a function to set the value
 */
const useLocalStorage = (key) => {
  const [value, setValue] = useState(() => {
    const getVal = window.localStorage.getItem(key);
    if (
      getVal &&
      getVal != null &&
      getVal !== NULL &&
      getVal !== undefined &&
      getVal !== UNDEFINED &&
      getVal !== ""
    ) {
      return getVal;
    }
  });

  const setLocalStorage = (newValue) => {
    if (
      newValue &&
      newValue != null &&
      newValue !== NULL &&
      newValue !== undefined &&
      newValue !== UNDEFINED &&
      newValue !== ""
    ) {
      try {
        // console.log("useLocalStorage - Setting value...");
        window.localStorage.setItem(key, newValue);
        // console.log("useLocalStorage - Value set as: ", newValue);
        setValue(newValue);
      } catch (error) {
        console.log("useLocalStorage - Error: ", error);
      }
    } else {
      console.log("useLocalStorage - Value is not valid: ", newValue);
    }
  };

  return [value, setLocalStorage];
};

export { useLocalStorage };
