import React from "react";
// eslint-disable-next-line
import styles from "./BidSecurity.module.css";
import { Button } from "../../components";

const BidSecurity = ({ amount = "0", addBidSecurity = () => {} }) => {
  // const critical = parseFloat(amount) < 0;
  const not_available = isNaN(parseFloat(amount).toFixed(1));
  const zero_amount = parseFloat(amount).toFixed(1) === "0.0";
  console.log("zero_amount: ");
  console.log(zero_amount);
  console.log("parseFloat(amount).toFixed(1):");
  console.log(parseFloat(amount).toFixed(1));

  return (
    <div
      className={`${styles.bidSecurity} ${
        zero_amount && styles.bideSecurityZeroCenter
      }`}
    >
      <div className={styles.topRow}>
        <div className={styles.topRowLeft}>
          <p className={`${styles.bidSecurityText}`}>
            {/* {critical ? "Overdue" : "No Overdue"} */}
            {"Overdue"}
          </p>
          <p
            className={`${styles.amountDisplay} ${
              !zero_amount ? styles.amountRed : ""
            }`}
          >
            {not_available
              ? "NA"
              : !zero_amount
              ? `-${parseFloat(amount / 100).toFixed(1)}`
              : "0"}
          </p>
        </div>
        {!zero_amount && (
          <div className={styles.addAmountButtonWidth}>
            <Button displayText="Add Money" onClickFunction={addBidSecurity} />
            <div></div>
          </div>
        )}
      </div>

      {!zero_amount && (
        <p className={`${styles.addMoneyText}`}>
          Please clear your dues to continue receiving rides
        </p>
      )}
    </div>
  );
};

export default BidSecurity;
