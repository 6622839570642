import { createSlice } from "@reduxjs/toolkit";

const driverCurrentRideSlice = createSlice({
  name: "driverCurrentRide",
  initialState: {
    currentRideId: "",
    currentRideDetails: {},
  },
  reducers: {
    setCurrentRideId(state, action) {
      state.currentRideId = action.payload;
    },
    setCurrentRideDetails(state, action) {
      state.currentRideDetails = action.payload;
    },
  },
});

export const driverCurrentRideActions = driverCurrentRideSlice.actions;
export default driverCurrentRideSlice;
