import React from "react";
import {
  BackPageHeadingWrapper,
  Button,
  ErrorInNumber,
  InputBox,
  InputBoxLargeMessage,
  LoadingSpinner,
} from "../../../components";
// eslint-disable-next-line
import styles from "./DriverHelp.module.css";
import {
  BASE_URL,
  DRIVER_GET_HELP,
  DRIVER_PROFILE_DETAILS,
} from "../../../constants/apiConstants";
import Cookies from "js-cookie";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../../constants/storageConstants";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { DRIVER_VIEW_RIDES_MY_BIDS_ROUTE } from "../../../constants/routeConstants";
import { useNavigateOnEvent } from "../../../hooks";

const DriverHelp = () => {
  const navigateToDriverHome = useNavigateOnEvent(
    DRIVER_VIEW_RIDES_MY_BIDS_ROUTE
  );
  const [helpMessage, setHelpMessage] = useState("");
  const [value, setValue] = useState({});
  const [error, setError] = useState("");

  const loadData = () => {
    const accessToken = Cookies.get("access");

    fetch(`${BASE_URL}${DRIVER_PROFILE_DETAILS}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setValue(data);
      })
      .catch((e) => {
        console.log("Driverhelp.js, Error occured", e);
      })
      .finally(() => {
        // console.log("Finally");
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const submitButtonHandler = () => {
    // return ;
    if (!helpMessage) {
      setError("Please type your message");
      return;
    }
    toast.success("Sending message...", {
      toastId: "message",
      autoClose: false,
      icon: <LoadingSpinner />,
      hideProgressBar: false,
      closeOnClick: true,
      // pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    fetch(`${BASE_URL}${DRIVER_GET_HELP}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ message: helpMessage }),
    })
      .then((response) => {
        if (response.ok) {
          toast.update("message", {
            render: "Message Sent ",
            type: toast.TYPE.SUCCESS,
            autoClose: 20000,
            closeButton: true,
            icon: null,
            // transition: Slide,
            position: toast.POSITION.TOP_CENTER,
          });
          navigateToDriverHome();
          return response.json();
        }
        throw response;
      })
      .catch((error) => {
        toast.update("message", {
          render: "Something went wrong",
          type: toast.TYPE.ERROR,
          autoClose: 20000,
          closeButton: true,
          icon: null,
          // transition: Slide,
          position: toast.POSITION.TOP_CENTER,
        });
        navigateToDriverHome();
        console.log("Error in help : ", error);
        setError("Something went wrong !");
      });
  };

  const helpMessageOnChangeHandler = (e) => {
    setHelpMessage(e.target.value);
    setError("");
  };

  const onChangeHandlerDoNothingFunction = (e) => {
    // do nothing
  };
  return (
    <BackPageHeadingWrapper logoHeadingText="Help">
      <div className={styles.driverHelp}>
        <InputBox
          idAndName="name"
          placeholderText="Enter your name"
          value={value?.first_name}
          onChange={onChangeHandlerDoNothingFunction}
        />
        <InputBox
          idAndName="emailAdress"
          type="email"
          placeholderText="Enter email address"
          value={value?.email}
          onChange={onChangeHandlerDoNothingFunction}
        />
        <InputBox
          idAndName="phoneNumebr"
          type="tel"
          placeholderText="Enter phone number"
          value={value?.phone_number}
          onChange={onChangeHandlerDoNothingFunction}
        />
        <InputBoxLargeMessage
          value={helpMessage}
          onChange={helpMessageOnChangeHandler}
        />
        {error && <ErrorInNumber displayText={error} />}
        <Button displayText="Submit" onClickFunction={submitButtonHandler} />
      </div>
    </BackPageHeadingWrapper>
  );
};

export default DriverHelp;
