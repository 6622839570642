import React from "react";
// eslint-disable-next-line
import styles from "./WalletRidePoints.module.css";
import { images } from "../../images";

const WalletRidePoints = ({ earnings, numberOfRides, points }) => {
  return (
    <div className={styles.walletRidePoints}>
      <div className={styles.topRow}>
        <div className={styles.topBlocks}>
          <div className={styles.insideBlockTopRow}>
            <img
              className={styles.icons}
              src={images.walletVector}
              alt="wallet"
            />
            <p>Money Earned</p>
          </div>
          <p className={styles.amountDisplay}>
            {earnings == null ? "0" : (earnings / 100).toFixed(1)}
          </p>
        </div>
        <div className={styles.topBlocks}>
          <div className={styles.insideBlockTopRow}>
            <img className={styles.icons} src={images.carVector} alt="car" />
            <p>Your Ride</p>
          </div>
          <p className={styles.amountDisplay}>{numberOfRides}</p>
        </div>
      </div>
      <div className={styles.bottomRow}>
        <div className={`${styles.topBlocks} ${styles.bgColorOverride}`}>
          <div className={styles.insideBlockTopRow}>
            <img
              className={styles.icons}
              src={images.clockVector}
              alt="wallet"
            />
            <p>Points</p>
          </div>
          <p className={styles.amountDisplay}>
            {points.toString().padStart(6, "0")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WalletRidePoints;
