import React from "react";
// eslint-disable-next-line
import styles from "./TermsNCondition.module.css";
import { useState } from "react";
import { BackPageHeadingWrapper } from "../../../components";
import { useEffect } from "react";
import {
  BASE_URL,
  TERMS_AND_CONDITIONS,
} from "../../../constants/apiConstants";

const TermsNCondition = () => {
  const [terms, setTerms] = useState();

  useEffect(() => {
    fetch(`${BASE_URL}${TERMS_AND_CONDITIONS}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        // console.log("Refund Policy data", data);
        setTerms(data);
      })
      .catch((e) => {
        console.log("TNC Error fetching terms and condition", e);
      });
  }, []);

  return (
    <BackPageHeadingWrapper logoHeadingText="Terms and Conditions">
      <div className={styles.scrollable}>
        <div className={styles.termsNCondition}>
          {terms &&
            typeof terms === "object" &&
            Object.entries(terms).map(([key, value], index) => (
              <>
                <h2>
                  {index + 1}
                  {". "}
                  {key}
                </h2>
                {typeof value === "string" && <p>{value}</p>}
                {typeof value === "object" && (
                  <>
                    {Object.entries(value).map(([key, value]) => (
                      <>
                        <div key={key}>
                          <h3>{key}</h3>
                          <p>{value}</p>
                        </div>
                      </>
                    ))}
                  </>
                )}
              </>
            ))}
        </div>
      </div>
    </BackPageHeadingWrapper>
  );
};

export default TermsNCondition;
