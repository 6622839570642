import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { BackPageHeadingWrapper, ErrorInNumber } from "../../../components";
import {
  BASE_URL,
  RIDER_PAST_RIDES,
  RIDER_ADV_PAST_RIDES,
  RIDE_STATUS_CANCELLED,
} from "../../../constants/apiConstants";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../../constants/storageConstants";
import styles from "./RiderPastRides.module.css";
import ActualPastRideCard from "../../../components/ActualPastRideCard/ActualPastRideCard";

const RiderPastRides = () => {
  const [data, setData] = useState([]);
  const [advData, setAdvData] = useState([]);
  const [type, setType] = useState("Spot");
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

  const fetchData = async (url, setDataCallback) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const jsonData = await response.json();
      setDataCallback(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(`${BASE_URL}${RIDER_PAST_RIDES}`, setData);
    fetchData(`${BASE_URL}${RIDER_ADV_PAST_RIDES}`, setAdvData);
  }, []);

  return (
    <BackPageHeadingWrapper logoHeadingText="Past rides">
      <div className={styles.buttonContainer}>
        <button
          className={`${styles.button} ${type === "Spot" && styles.active}`}
          onClick={() => setType("Spot")}
        >
          Spot rides
        </button>
        <button
          className={`${styles.button} ${type === "Adv" && styles.active}`}
          onClick={() => setType("Adv")}
        >
          Adv rides
        </button>
      </div>
      <div className={styles.riderPastRides}>
        {type === "Spot" ? (
          <SpotRides data={data} />
        ) : (
          <AdvRides data={advData} />
        )}
      </div>
    </BackPageHeadingWrapper>
  );
};

const SpotRides = ({ data }) => {
  // Check if data is not an object or it doesn't have ride_history or it's empty
  if (!data || !data.ride_history || data.ride_history.length === 0) {
    return (
      <div className={styles.riderPastRides}>
        <ErrorInNumber displayText="No rides to display" />
      </div>
    );
  }

  return (
    <div className={styles.riderPastRides}>
      <div className={styles.background}>
        {data.ride_history.map((eachRide) => (
          <ActualPastRideCard
            key={eachRide.id}
            cancelled={eachRide.ride_status === RIDE_STATUS_CANCELLED}
            rideAmount={eachRide?.total_amount}
            date={eachRide?.date}
            pickupAddressText={eachRide?.pickup_address_text}
            dropAddressText={eachRide?.drop_address_text}
            rideTime={eachRide.ride_time}
            commission={eachRide.admin_taxiwars_commission}
          />
        ))}
      </div>
    </div>
  );
};

const AdvRides = ({ data }) => {
  // Check if data is not an object or it doesn't have adv_ride_history or it's empty
  if (!data || !data.adv_ride_history || data.adv_ride_history.length === 0) {
    return (
      <div className={styles.riderPastRides}>
        <ErrorInNumber displayText="No rides to display" />
      </div>
    );
  }

  return (
    <div className={styles.riderPastRides}>
      <div className={styles.background}>
        {data.adv_ride_history.map((eachRide) => (
          <ActualPastRideCard
            key={eachRide.id}
            cancelled={eachRide.ride_status === RIDE_STATUS_CANCELLED}
            rideAmount={eachRide?.total_amount}
            date={eachRide?.date}
            pickupAddressText={eachRide?.pickup_address_text}
            dropAddressText={eachRide?.drop_address_text}
            rideTime={eachRide.ride_time}
            commission={eachRide.admin_taxiwars_commission}
          />
        ))}
      </div>
    </div>
  );
};


export default RiderPastRides;
