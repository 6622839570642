// export const GOOGLE_MAPS_API_KEY = "Create_Key_Not_Found_Error";
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const BASE_URL = process.env.REACT_APP_SERVER_URL;
export const WEB_SOCKET_BASE_URL_RIDER =
  process.env.REACT_APP_WEB_SOCKET_BASE_URL_RIDER;

export const RIDER_SIGN_UP = "/customer-signup/";
export const RIDER_GET_OTP = "/otp/";
export const RIDER_LOGIN_VERIFY_WITH_OTP = "/login/";
export const RIDER_PROFILE_DETAILS = "/customer_details/";
export const RIDER_CREATE_RIDE = "/ride/";

export const RIDER_FCM_TOKEN = "/fcm/";
export const RIDER_GET_FCM_TOKEN = "/getfcm/";

export const RIDER_RAZORPAY_PAYMENT_CREATE_ORDER = "/confirmbid/";
export const RIDER_RAZORPAY_PAYMENT_SUCCESS = "/payments/";

export const RIDER_GET_ACCEPTED_RIDE_DETAILS = "/accepted-driver/";

export const RIDER_GET_HELP = "/help/";

export const RIDER_PAST_RIDES = "/driver/ride_history/";
export const RIDER_ADV_PAST_RIDES = "/driver/adv_ride_history/";

export const RIDER_START_RIDE = "/customer-start-ride/";

export const DRIVER_SIGN_UP = "/driver/signup/";
export const DRIVER_GET_OTP = "/driver/otp/";
export const DRIVER_LOGIN_VERIFY_WITH_OTP = "/driver/login/";
export const DRIVER_PROFILE_DETAILS = "/driver/details/";
export const DRIVER_CAR_DETAILS = "/driver/car/";
export const DRIVER_RC_UPLOAD = "/driver/rc_upload/";
export const DRIVER_RC_CHECK = "/driver/rc_check/";

export const DRIVER_VIEW_ALL_RIDES = "/driver/rides/";
export const DRIVER_VIEW_ALL_BIDS = "/driver/bid/";

export const DRIVER_RAZORPAY_BID_SECURITY_CREATE_ORDER = "/driver/createorder/";
export const DRIVER_BID_SECURITY_RAZORPAY_PAYMENT_SUCCESS =
  "/driver/security-deposit/";

export const DRIVER_VIEW_ACCEPTED_CUSTOMER_BID = "/driver/accepted-customer/";

export const DRIVER_FCM_TOKEN = "/driver/fcmdriver/";
export const DRIVER_GET_FCM_TOKEN = "/driver/getfcm-driver/";

export const DRIVER_GET_HELP = "/driver/driver-help/";

export const DRIVER_START_RIDE = "/driver/start-ride/";
export const DRIVER_END_RIDE = "/driver/end-ride/";

export const DRIVER_GET_RIDE_STATUS = "/driver/driver-ride-status/";

export const DRIVER_SEND_CURRENT_LOCATION = "/driver/driver-location-update/";

export const DRIVER_IN_PROGRESS_RIDES = "/driver/In-Progress/";

export const CODE_502 = "502";
export const CODE_500 = "500";
export const CODE_200 = "200";
export const CODE_300 = "300";
export const CODE_400 = "400";

export const RAZORPAY_CHECKOUT_V1_JS =
  "https://checkout.razorpay.com/v1/checkout.js";

export const RAZORPAY_KEY_ID = process.env.REACT_APP_RAZORPAY_KEY_ID;
// export const RAZORPAY_KEY_ID = "rzp_test_fhPo9Odgfta9R9";

export const RIDE_STATUS_BID_CONFIRMED = "Bid Confirmed";
export const RIDE_STATUS_IN_PROGRESS = "In-Progress";
export const RIDE_STATUS_RATING = "Rating";
export const RIDE_STATUS_COMPLETED = "Completed";
export const RIDE_STATUS_CANCELLED = "Cancelled";
export const RIDE_STATUS_ACTIVE = "Active";
export const RIDE_STATUS_PENDING = "Pending";

export const TERMS_AND_CONDITIONS = "/termsandconditons/";
export const REFUND_POLICY = "/refundpolicy/";
export const RIDE_STATUS_GET = "/ride-status/";
export const SEND_RATING = "/driver-rating/";
export const RIDE_DETAILS_COMPLETED = "/ride-completed-details/";

export const DRIVER_GET_PENDING_RIDES = "/driver/Pending/";
export const DRIVER_GET_BID_CONFIRMED_RIDES = "/driver/Bid-Confirmed/";
export const DRIVER_EARN_TOTAL = "/driver/ride-earn-total/";

export const DRIVER_SOS = "/driver/sos-driver-help/";

export const SUGGESTED_FAIR = "/driver/fair/";

export const GET_DRIVER_VEHICLE_TYPE = "/driver/get-vehicle-type/";

export const GET_SCHEDULED_RIDE_DETAILS = "/driver/Sceduled/";

export const GET_ON_DUTY_STATUS = "/driver/driver-availability/";
export const POST_ON_DUTY_STATUS = "/driver/driver-availability/";

export const NEW_FAIR_VIEW = "/driver/new-fairview/";

export const CCAVENUE_BASE_URL_LIVE = "https://secure.ccavenue.com";
export const CCAVENUE_BASE_URL_TEST = "https://test.ccavenue.com";
export const CC_AVENUE_STATUS_AFTER_UPI = "/driver/cc/status";

export const ANALYTICS_LAST_APP_OPEN_DATE_TIME = "/driver/driver-expiry/";

// advanced booking

// export const ADV_OVERDUE_POPUP_DRIVER_PROFILE_DETAILS = "/not-connected/";

export const ADV_NEW_FAIR_VIEW = "/adv_booking_driver/adv_fair/";
// POST
// body
// send ride_id
//
// response same as before

export const ADV_DRIVER_VIEW_ALL_BIDS = "/adv_booking_driver/adv_bid/";
// POST
// body
// ride: rideId,
// amount: (parseInt(bidAmount) * 100).toString(),
// eta_time: `${etaTime} `,
// eta_distance: `${etaDistance}`,
//
// response same as before

export const ADV_DRIVER_VIEW_ALL_RIDES = "/adv_booking_driver/adv_rides/";
// GET
//
// response as before

export const ADV_DRIVER_GET_PENDING_RIDES = "/adv_booking_driver/adv_pending/";
// GET
//
// response as before

export const ADV_DRIVER_GET_BID_CONFIRMED_RIDES =
  "/adv_booking_driver/adv_bid-confirmed/";
// GET
//
// response as before

export const ADV_DRIVER_IN_PROGRESS_RIDES =
  "/adv_booking_driver/adv_in-progress/";
// same as before

export const ADV_DRIVER_VIEW_ACCEPTED_CUSTOMER_BID =
  "/adv_booking_driver/adv_accepted-customer/";
// same as before

export const ADV_DRIVER_GET_RIDE_STATUS =
  "/adv_booking_driver/adv_driver_ride_status/";
// same as before

export const ADV_DRIVER_END_RIDE = "/adv_booking_driver/adv_end_ride/";
// same as before

export const ADV_DRIVER_START_RIDE = "/adv_booking_driver/adv_ride_start/";

export const API_FLASHING_URL = "/adv_booking_driver/adv_flashing_url/";
