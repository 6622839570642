import React from "react";
// eslint-disable-next-line
import styles from "./NameEmailPhoneLine.module.css";

const NameEmailPhoneLine = ({ name = "Full name", email, mobile }) => {
  return (
    <div className={styles.nameEmailPhoneLine}>
      <div className={`${styles.itemLeft} ${styles.commonVerticleGap}`}>
        <p className={`${styles.label} ${styles.idLabel}`}>ID</p>
        <p className={styles.label}>Email</p>
        <p className={styles.label}>Mobile</p>
      </div>
      <div className={`${styles.itemCenter} ${styles.commonVerticleGap}`}>
        <p className={styles.dot}>&#10240;</p>
        <p className={styles.dot}>:</p>
        <p className={styles.dot}>:</p>
      </div>
      <div className={`${styles.itemRight} ${styles.commonVerticleGap}`}>
        <p className={`${styles.value} ${styles.boldName}`}> {name}</p>
        <p className={styles.value}>{email}</p>
        <p className={styles.value}>{mobile}</p>
      </div>
    </div>
  );
};

export default NameEmailPhoneLine;
