import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  DriverRmhScreenWrapper,
  LogsBottomModal,
  ScreenWrapper,
  UpdateRequestModal,
} from "./components";
import {
  AUTH_SIGN_UP_ROUTE,
  AUTH_VERIFY_OTP_ROUTE,
  // ROOT_ROUTE,
  SPLASH_SCREEN_ROUTE,
  DRIVER_BID_ACCEPTED_START_RIDE_ROUTE,
  DRIVER_EXTRAS_HELP,
  // DRIVER_EXTRAS_PAST_RIDES,
  // DRIVER_EXTRAS_PAST_RIDE_DETAIL,
  DRIVER_PLACE_A_BID_ROUTE,
  DRIVER_PROFILE_ADDRESS_DETAILS_ROUTE,
  DRIVER_PROFILE_COMPLETE_PROFILE_ROUTE,
  DRIVER_PROFILE_DOCUMENT_UPLOAD_ROUTE,
  DRIVER_PROFILE_VEHICLE_DETAILS_ROUTE,
  DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE,
  DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
  DRIVER_BID_SECURITY,
  DRIVER_EXTRAS_EDIT_PROFILE,
  ROUTE_TERMS_AND_CONDITIONS,
  ROUTE_REFUND_POLICY,
  DRIVER_EXTRAS_PAST_RIDES,
  DRIVER_EXTRAS_PAST_RIDE_DETAIL,
  DRIVER_SCHEDULED_RIDE,
  ROOT_ROUTE,
  DRIVER_BID_SECURITY_CC_AVENUE,
  CC_AVENUE_WAITING_PAGE,
  DRIVER_RIDE_END_OVERDUE_SCREEN,
  ADV_DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
  ADV_DRIVER_BID_ACCEPTED_START_RIDE_ROUTE,
  ADV_DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE,
} from "./constants/routeConstants";
import {
  SignUp,
  SplashScreen,
  VerifyOtpRiderDriver,
  // RiderPastRideDetails,
  // RiderPastRides,
  DriverCompleteProfile,
  BidAcceptedMeetAtPickupPoint,
  DriverAddressDetails,
  DriverUploadDocuments,
  DriverVehicleDetails,
  RideDetailsEndRide,
  ViewDetailsAndPlaceBid,
  ViewRidesWithMyBids,
  NotFound,
  BidSecurityDeposit,
  DriverHelp,
  DriverEditProfilePage,
  TermsNCondition,
  RefundPolicy,
  RiderPastRides,
  RiderPastRideDetails,
  ScheduledRide,
  BidSecurityDepositCcAvenue,
  BidSecurityDepositCcAvenueWaitingPage,
  DriverRideEndOverduePage,
} from "./pages";
// import app from "./firebase";
// import { getMessaging, getToken } from "firebase/messaging";
import { useDispatch, useSelector } from "react-redux";
import {
  driverAllRideActions,
  fcmTokenActions,
  uiElementActions,
} from "./store/slice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ALL_IN_APP_NOTIFICATION_DEFAULT_TIME } from "./constants/timeConstants";
import { useCustomLogger, useNavigateOnEvent, useWakeLock } from "./hooks";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  ANDROID_VERSION,
  CURRENT_LIVE_ANDROID_VERSION,
  USER_LOCAL_STORAGE,
} from "./constants/storageConstants";
import Cookies from "js-cookie";
import {
  BASE_URL,
  DRIVER_FCM_TOKEN,
  DRIVER_VIEW_ALL_RIDES,
} from "./constants/apiConstants";
import {
  AdvBidAcceptedMeetAtPickupPoint,
  AdvRideDetailsEndRide,
  AdvViewRidesWithMyBids,
} from "./pages/AdvanceBooking";
// import { sounds } from "./assets/sounds";

function App() {
  useWakeLock();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logger = useCustomLogger();
  const logsDownloadDialogBox = useSelector(
    (state) => state.uiElement.logsDownloadDialogBox
  );

  // window.addEventListener("beforeunload", function (event) {
  //   event.preventDefault();
  //   event.returnValue = "Reloading the page is not allowed.";
  // });

  // const messaging = getMessaging(app);

  const navigateToSplashScreen = useNavigateOnEvent(SPLASH_SCREEN_ROUTE, true);
  const [showUpdatePrompt, setShowUpdatePrompt] = useState(false);

  const confirmDataAndAuth = (paystatus, paymentUniqueId) => {
    // console.log("Response", response);

    if (paystatus.toString() === "Success") {
      toast.success("Payment Success");
    }
    if (paystatus.toString() === "Failure") {
      toast.error("Payment Failed");
      // navigate(`${RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE}?rideId=${ride}`, {
      //   replace: true,
      // });
      // return;
    }
    if (
      paystatus.toString() !== "Success" &&
      paystatus.toString() !== "Failure"
    ) {
      toast.info("Waiting...");
      navigate(`${CC_AVENUE_WAITING_PAGE}?paymentUniqueId=${paymentUniqueId}`, {
        replace: true,
      });
    }
  };

  useEffect(() => {
    // Get the URL search parameters
    const searchParams = new URLSearchParams(window.location.search);

    // Extract the value of the "fcm_token" query parameter
    const fcmToken = searchParams.get("fcm_token");
    if (fcmToken) {
      // Do something with the extracted token
      dispatch(fcmTokenActions.setFcmToken(fcmToken));
      // Send the token to your desired destination or perform any other actions
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

      const data = JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE) || "{}");

      try {
        if (data?.details && accessToken) {
          fetch(`${BASE_URL}${DRIVER_FCM_TOKEN}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            method: "POST",
            body: JSON.stringify({
              user_id: data?.details,
              fcm: fcmToken,
            }),
          });
        }
      } catch (error) {
        console.log(error);
      }

      console.log("APP.js - FCM Token, from Android, Found  :");
      console.log(fcmToken);
    } else {
      console.log("APP.js - FCM Token from Android, Not Found :");
      console.log(fcmToken);
    }

    const androidVersion = searchParams.get("android_version");
    if (androidVersion) {
      localStorage.setItem(ANDROID_VERSION, androidVersion);
    }

    // navigation pipelines
    const goToPlaceBidRideId = searchParams.get("go_to_place_bid_page_ride_id");
    const pickupTime = searchParams.get("pickup_time");
    const pickupDistance = searchParams.get("pickup_distance");

    if (
      goToPlaceBidRideId &&
      pickupTime &&
      pickupDistance &&
      goToPlaceBidRideId !== "init" &&
      pickupTime !== "init" &&
      pickupDistance !== "init"
    ) {
      console.log("In updated rides");
      // * UPDATE RIDES
      const updateRides = () => {
        console.log("Fetch request sent..");
        const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
        fetch(` ${BASE_URL}${DRIVER_VIEW_ALL_RIDES}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((result) => {
            console.log("Driver view al rides result - ", result);
            if (!Array.isArray(result)) {
              dispatch(driverAllRideActions.updateRides([]));
              navigateToSplashScreen();
              return;
            }
            console.log(result);
            dispatch(driverAllRideActions.updateRides(result));
            // window.history.pushState(
            //   null,
            //   "",
            //   `${DRIVER_VIEW_RIDES_MY_BIDS_ROUTE}`
            // );
            dispatch(
              driverAllRideActions.setSelectedRide(parseInt(goToPlaceBidRideId))
            );
            console.log("nav to place bid");
            navigate(DRIVER_PLACE_A_BID_ROUTE, {
              replace: false,
              state: { eta_time: pickupTime, eta_distance: pickupDistance },
            });
          })
          .catch((error) =>
            console.log("ViewRidesWithMyBids.js, error", error)
          );
      };
      // * UPDATE RIDES

      // window.history.pushState(null, "", `${DRIVER_VIEW_RIDES_MY_BIDS_ROUTE}`);
      // dispatch(driverAllRideActions.setSelectedRide(goToPlaceBidRideId));
      // navigate(DRIVER_PLACE_A_BID_ROUTE, {
      //   replace: false,
      //   state: { eta_time: pickupTime, eta_distance: pickupDistance },
      // });
      updateRides();
      return;
    }

    const paystatus = searchParams.get("paystatus");
    const paymentUniqueId = searchParams.get("orderId");

    if (paystatus && paymentUniqueId) {
      confirmDataAndAuth(paystatus, paymentUniqueId);
    }

    navigateToSplashScreen();

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      // console.log("updateOverlay value fired");
      // console.log("The Value: ",event.value)
      setShowUpdatePrompt(true);
    };

    // Add the customEventUpdateDownloaded event listener
    window.addEventListener("customEventUpdateDownloaded", handleStorageChange);

    return () => {
      // Clean up by removing the customEventUpdateDownloaded event listener
      window.removeEventListener(
        "customEventUpdateDownloaded",
        handleStorageChange
      );
    };
  }, []);

  return (
    <>
      {/* <Notifications /> */}
      <ToastContainer
        position="top-center"
        autoClose={ALL_IN_APP_NOTIFICATION_DEFAULT_TIME}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <UpdateRequestModal openBool={showUpdatePrompt} />
      <UpdateRequestModal
        openBool={
          parseInt(localStorage.getItem(ANDROID_VERSION) || 10) <
          CURRENT_LIVE_ANDROID_VERSION
            ? true
            : false
        }
        playStoreUpdate={true}
      />
      <LogsBottomModal
        openLogDialogBox={logsDownloadDialogBox}
        downloadLogsButton={() => {
          logger.exportLogs();
          dispatch(uiElementActions.closeLogsDownloadDialogBox());
        }}
        cancelButton={() => {
          dispatch(uiElementActions.closeLogsDownloadDialogBox());
        }}
      />
      <Routes>
        {/* WARNING - DO NOT EXPORT ROUTES TO ANOTHER FILE, */}
        {/* OR ELSE REACT ROUTER WILL IGNORE INITIAL */}
        {/* BINDINDS WITH THE COMPONENTS AND GIVE CONSOLE WARNS */}
        {/* WILL BE CLEANED WHEN SHIFT TO useBrowserRouter v6.4 */}
        {/* ****************** */}
        {/* ON BOARDING ROUTES */}
        {/* ****************** */}
        <Route element={<ScreenWrapper />}>
          <Route path={ROOT_ROUTE} element={<></>} />
          <Route path={SPLASH_SCREEN_ROUTE} element={<SplashScreen />} />
          {/* <Route path={WELCOME_ROUTE} element={<Welcome />} /> */}
          <Route path={AUTH_SIGN_UP_ROUTE} element={<SignUp />} />
          <Route
            path={AUTH_VERIFY_OTP_ROUTE}
            element={<VerifyOtpRiderDriver />}
          />
          <Route
            path={ROUTE_TERMS_AND_CONDITIONS}
            element={<TermsNCondition />}
          />
          <Route path={ROUTE_REFUND_POLICY} element={<RefundPolicy />} />
          {/* <Route
        path={ROOT_ROUTE}
        element={<Navigate to={SPLASH_SCREEN_ROUTE} replace />}
      /> */}
        </Route>

        {/* ****************** */}
        {/* DRIVER ROUTES */}
        {/* ****************** */}

        <Route
          path={DRIVER_PROFILE_COMPLETE_PROFILE_ROUTE}
          element={<DriverCompleteProfile />}
        />

        <Route
          path={DRIVER_PROFILE_ADDRESS_DETAILS_ROUTE}
          element={<DriverAddressDetails />}
        />
        <Route
          path={DRIVER_PROFILE_VEHICLE_DETAILS_ROUTE}
          element={<DriverVehicleDetails />}
        />
        <Route
          path={DRIVER_PROFILE_DOCUMENT_UPLOAD_ROUTE}
          element={<DriverUploadDocuments />}
        />
        <Route path={DRIVER_BID_SECURITY} element={<BidSecurityDeposit />} />
        <Route
          path={DRIVER_RIDE_END_OVERDUE_SCREEN}
          element={<DriverRideEndOverduePage />}
        />
        <Route
          path={DRIVER_BID_SECURITY_CC_AVENUE}
          element={<BidSecurityDepositCcAvenue />}
        />
        <Route
          path={CC_AVENUE_WAITING_PAGE}
          element={<BidSecurityDepositCcAvenueWaitingPage />}
        />

        <Route element={<DriverRmhScreenWrapper />}>
          <Route
            path={DRIVER_VIEW_RIDES_MY_BIDS_ROUTE}
            element={<ViewRidesWithMyBids />}
          />
          <Route
            path={ADV_DRIVER_VIEW_RIDES_MY_BIDS_ROUTE}
            element={<AdvViewRidesWithMyBids />}
          />
          <Route path={DRIVER_SCHEDULED_RIDE} element={<ScheduledRide />} />
        </Route>

        <Route
          path={DRIVER_PLACE_A_BID_ROUTE}
          element={<ViewDetailsAndPlaceBid />}
        />
        <Route
          path={DRIVER_BID_ACCEPTED_START_RIDE_ROUTE}
          element={<BidAcceptedMeetAtPickupPoint />}
        />
        <Route
          path={ADV_DRIVER_BID_ACCEPTED_START_RIDE_ROUTE}
          element={<AdvBidAcceptedMeetAtPickupPoint />}
        />
        <Route
          path={DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE}
          element={<RideDetailsEndRide />}
        />
        <Route
          path={ADV_DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE}
          element={<AdvRideDetailsEndRide />}
        />
        <Route
          path={DRIVER_EXTRAS_EDIT_PROFILE}
          element={<DriverEditProfilePage />}
        />

        <Route path={DRIVER_EXTRAS_PAST_RIDES} element={<RiderPastRides />} />
        <Route
          path={DRIVER_EXTRAS_PAST_RIDE_DETAIL}
          element={<RiderPastRideDetails />}
        />
        <Route path={DRIVER_EXTRAS_HELP} element={<DriverHelp />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
