// eslint-disable-next-line
import styles from "./DriverRmhScreenWrapper.module.css";

import { images } from "../../../images";

import { AnimatePresence, motion, useCycle } from "framer-motion";

import { Outlet, useNavigate } from "react-router-dom";

import { AnimationLayout } from "../../../components";
import {
  useCustomLogger,
  useLogoutHandler,
  useNavigateOnEvent,
} from "../../../hooks";
import {
  ADV_DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
  DRIVER_EXTRAS_EDIT_PROFILE,
  DRIVER_EXTRAS_HELP,
  DRIVER_EXTRAS_PAST_RIDES,
  DRIVER_SCHEDULED_RIDE,
  DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
} from "../../../constants/routeConstants";
import { GLOBAL_VERSION_CONSTANT } from "../../../constants/storageConstants";
import { useDispatch } from "react-redux";
import { uiElementActions } from "../../../store/slice";
// import { useSelector } from "react-redux";

const DriverRmhScreenWrapper = () => {
  const dispatch = useDispatch();
  // const isTimeSelectModalOpen = useSelector(
  //   (state) => state.ui.isTimeSelectModalOpen
  // );
  // const isSortBidsModalOpen = useSelector(
  //   (state) => state.ui.isSortBidsModalOpen
  // );
  const navigateToDriverHome = useNavigateOnEvent(
    DRIVER_VIEW_RIDES_MY_BIDS_ROUTE
  );
  const navigateToExtrasPastRides = useNavigateOnEvent(
    DRIVER_EXTRAS_PAST_RIDES
  );
  const navigateToExtrasHelp = useNavigateOnEvent(DRIVER_EXTRAS_HELP);
  const navigateToSchduledRide = useNavigateOnEvent(DRIVER_SCHEDULED_RIDE);
  const naviateToFirst = () => {
    cycleOpen();
    navigateToDriverHome();
  };

  const naviateToSecond = () => {
    cycleOpen();
    navigateToExtrasPastRides();
  };

  const naviateToThird = () => {
    cycleOpen();
    navigateToExtrasHelp();
  };
  const logoutFunction = useLogoutHandler();

  const logsDialogBox = () => {
    // console.log("Clicked here");
    cycleOpen();
    dispatch(uiElementActions.openLogsDownloadDialogBox());
  };

  const navigate = useNavigate();

  const onClickScheduledRide = () => {
    cycleOpen();
    // navigateToSchduledRide();
    navigate(`${ADV_DRIVER_VIEW_RIDES_MY_BIDS_ROUTE}`);
  };

  const hamMenuItems = [
    {
      imgSrc: images.car,
      alt: "car",
      text: "Current ride",
      onClickNavigation: naviateToFirst,
    },
    {
      imgSrc: images.timeCar,
      alt: "car",
      text: "Advance Booking",
      onClickNavigation: onClickScheduledRide,
    },
    {
      imgSrc: images.clock,
      alt: "clock",
      text: "Your Rides",
      onClickNavigation: naviateToSecond,
    },
    {
      imgSrc: images.headset,
      alt: "help",
      text: "Help",
      onClickNavigation: naviateToThird,
    },
    {
      imgSrc: images.logOff,
      alt: "log out",
      text: "Log Off",
      onClickNavigation: logoutFunction,
    },
    // {
    //   imgSrc: images.saveLogs,
    //   alt: "download ",
    //   text: "Save Logs",
    //   onClickNavigation: logsDialogBox,
    // },
  ];

  const [open, cycleOpen] = useCycle(false, true);

  const navigateToEditProfile = useNavigateOnEvent(DRIVER_EXTRAS_EDIT_PROFILE);

  return (
    <>
      {/* rrelative */}
      <AnimationLayout>
        <div className={styles.rmhScreenWrapper}>
          <AnimatePresence>
            {open && (
              <>
                <motion.aside
                  initial={{ width: "0%", opacity: 0 }}
                  animate={{ width: "70%", opacity: 1 }}
                  exit={{
                    width: "0%",
                    transition: { delay: 0, duration: 0.3 },
                    opacity: 0,
                  }}
                  className={styles.hamburgerMenu}
                >
                  <button onClick={cycleOpen} className={styles.crossButton}>
                    X
                  </button>

                  <motion.ul
                    initial={{ opacity: 0, visibility: "hidden" }}
                    animate={{ opacity: 1, visibility: "visible" }}
                    exit={{
                      opacity: 0,
                      transition: { delay: 0, duration: 0.1 },
                    }}
                    className={styles.hamburgerList}
                  >
                    {hamMenuItems.map((eachItem) => (
                      <li
                        key={eachItem.text}
                        className={styles.liClass}
                        onClick={eachItem.onClickNavigation}
                      >
                        <img src={eachItem.imgSrc} alt={eachItem.alt} />
                        <span>{eachItem.text}</span>
                      </li>
                    ))}
                  </motion.ul>

                  <motion.p
                    initial={{ opacity: 0, visibility: "hidden" }}
                    animate={{ opacity: 1, visibility: "visible" }}
                    exit={{
                      opacity: 0,
                      transition: { delay: 0, duration: 0.1 },
                    }}
                    className={styles.termsOfService}
                    onClick={() => {}}
                    onDoubleClick={logsDialogBox}
                  >
                    {`Version - ${GLOBAL_VERSION_CONSTANT}`}
                  </motion.p>
                </motion.aside>
                <motion.aside
                  onClick={cycleOpen}
                  className={styles.hamburgerSideClosing}
                ></motion.aside>
              </>
            )}
          </AnimatePresence>
          {/* absolute z-10 */}
          {/* absolute z-5 */}
          <div
            style={{
              backgroundColor: "",
            }}
            className={styles.appbar}
          >
            <>
              <img
                onClick={cycleOpen}
                className={styles.hamburgerIcon}
                src={images.hamburgerIcon}
                alt="menu icon"
              />

              <img
                className={styles.logoWithTextRow}
                src={images.logoWithTextRow}
                alt="taxiwars logo"
              />
              <img
                onClick={navigateToEditProfile}
                className={styles.userGeneric}
                src={images.userGeneric}
                alt="profile pic"
              />
            </>
          </div>
          <div className={styles.content}>
            <Outlet />
          </div>
          <div className={styles.bottomModal}>
            {/* {isTimeSelectModalOpen && <TimeSelectModal />}
            {isSortBidsModalOpen && <SortBidsModal />} */}
            <div></div>
            <div></div>
          </div>
        </div>
      </AnimationLayout>
    </>
  );
};

export default DriverRmhScreenWrapper;
