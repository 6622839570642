import React from "react";
import { images } from "../../images";
// eslint-disable-next-line
import styles from "./CurrentStateTimePriceBids.module.css";

const CurrentStateTimePriceBids = ({
  bidPlacedWaiting = false,
  bidAccepted = false,
  rideTime,
  numberOfBids,
  driverBidAmount,
  rideActiveBool = false,
}) => {
  return (
    <div className={styles.currentStateTimePriceBids}>
      <div className={styles.loadingStatusTime}>
        {bidPlacedWaiting && (
          <img
            className={styles.hourGlass}
            src={images.hourGlass}
            alt="hour glass"
          />
        )}
        {bidAccepted && (
          <img
            className={styles.flatGreenCircleBlackTick}
            src={images.flatGreenCircleBlackTick}
            alt="black check in green circle"
          />
        )}
        <p className={styles.time}>{rideTime}</p>
      </div>
      <div
        // className={`${styles.priceBids} ${
        //   bidPlacedWaiting && styles.bidPlacedWaiting
        // }`}
        className={`${styles.priceBids} ${
          bidPlacedWaiting && styles.bidPlacedWaiting
        } ${bidAccepted && styles.bidAccepted} ${
          rideActiveBool && styles.backgroundColorAnimation
        }`}
      >
        <span>
          {`${
            bidPlacedWaiting || bidAccepted
              ? "₹" + parseFloat(driverBidAmount / 100).toFixed(1)
              : ""
          }`}
        </span>
        <span>({numberOfBids} bids)</span>
      </div>
    </div>
  );
};

export default CurrentStateTimePriceBids;
