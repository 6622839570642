import React, { useEffect, useMemo, useState } from "react";
// eslint-disable-next-line
import styles from "./SignUp.module.css";

import {
  AnimationLayout,
  BottomMessagePara,
  Button,
  ErrorInNumber,
  LoginSignSwitchLine,
  RiderDriverSign,
  TextHeadingSign,
  TextParaSign,
} from "../../../components";

// Phone Number package import
import PhoneInput from "react-phone-number-input";
// Original Styling import - NOT REQUIRED, Custom done
// import "react-phone-number-input/style.css";
// Custom Styling import
import "./PhoneInputStyles.css";

import { useLocalStorage, useNavigateOnEvent } from "../../../hooks";
import {
  AUTH_VERIFY_OTP_ROUTE,
  ROUTE_REFUND_POLICY,
  ROUTE_TERMS_AND_CONDITIONS,
} from "../../../constants/routeConstants";

import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../store/slice";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  BASE_URL,
  RIDER_SIGN_UP,
  DRIVER_SIGN_UP,
  RIDER_GET_OTP,
  DRIVER_GET_OTP,
  CODE_200,
  CODE_300,
} from "../../../constants/apiConstants";
import { parsePhoneNumber } from "react-phone-number-input";
import {
  GLOBAL_VERSION_CONSTANT,
  USER_AGENT,
  USER_AGENT_DRIVER,
  USER_AGENT_RIDER,
} from "../../../constants/storageConstants";

const SignUp = () => {
  const displayVarSignUp = useMemo(
    () => ({
      headingText: "Welcome Driver",
      paraMessage:
        "Enter your mobile number to Sign Up and continue your journey with TaxiWars",
      questionText: "Already have an Account?",
      functionText: "Login here.",
    }),
    []
  );

  const displayVarSignIn = useMemo(
    () => ({
      headingText: "Welcome Driver",
      paraMessage:
        "Enter your mobile number to Log In and continue your journey with TaxiWars",
      questionText: "Need an account?",
      functionText: "Sign Up here.",
    }),
    []
  );

  const navigateToTermsAndConditionPage = useNavigateOnEvent(
    ROUTE_TERMS_AND_CONDITIONS
  );

  const navigateToRefundPolicyPage = useNavigateOnEvent(ROUTE_REFUND_POLICY);

  const dispatch = useDispatch();
  const inputNumber = useSelector((state) => state.auth.inputNumber);
  const navigateToVerifyOtp = useNavigateOnEvent(AUTH_VERIFY_OTP_ROUTE, true);

  const [isErrorDisplay, setIsErrorDisplay] = useState(false);
  const [isNetworkError, setIsNetworkError] = useState(false);
  const [notSignedUpError, setNotSignedUpError] = useState(false);
  const [userExistError, setUserExistError] = useState(false);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [otpNotSentError, setOtpNotSentError] = useState(false);

  const [isSignIn, setIsSignIn] = useState(true);
  const [displayVar, setDisplayVar] = useState({ ...displayVarSignIn });

  const [countryCode, setCountryCode] = useState();
  const [onlyNumber, setOnlyNumber] = useState();

  const changeToSignInHandler = () => {
    setIsSignIn((prev) => !prev);
  };

  const [, setUserAgent] = useLocalStorage(USER_AGENT);

  useEffect(() => {
    setUserAgent(USER_AGENT_DRIVER);
  }, [setUserAgent]);

  const inputNumberOnChangeHandler = (mobileNumber) => {
    setIsErrorDisplay(false);
    setIsNetworkError(false);
    setNotSignedUpError(false);
    setUserExistError(false);
    setOtpNotSentError(false);

    dispatch(authActions.updateInputMobileNumber(mobileNumber));
  };

  const validatePhoneNumberAndNavigate = () => {
    setIsErrorDisplay(false);
    setIsNetworkError(false);
    setNotSignedUpError(false);
    setUserExistError(false);
    setOtpNotSentError(false);

    if (inputNumber === undefined) {
      setIsErrorDisplay(true);
      return;
    }

    if (!isValidPhoneNumber(inputNumber)) {
      setIsErrorDisplay(true);
      return;
    }

    // console.log("Valid Number ✔");
    // console.log("Each component of number...");
    // console.log("Country Code: ", countryCode, "Number: ", onlyNumber);

    if (!isSignIn) {
      // console.log("Signing up...from driver side");
      // signUpCallDriver();
      setIsLoadingError(true);
      fetch(`${BASE_URL}${DRIVER_SIGN_UP}`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          country_code: countryCode,
          phone_number: onlyNumber,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }

          throw response;
        })
        .then((data) => {
          if (data && data.Code === CODE_300) {
            // console.log(data);
            setUserExistError(true);
            return;
          }
          if (data && data.Code === CODE_200) {
            return fetch(`${BASE_URL}${DRIVER_GET_OTP}`, {
              headers: {
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify({
                country_code: countryCode,
                phone_number: onlyNumber,
              }),
            })
              .then((response) => {
                if (response.ok) {
                  return response.json();
                }
                throw response;
              })
              .then((data) => {
                if (data && data.Code === CODE_200) {
                  // console.log(data);
                  navigateToVerifyOtp();
                  return;
                } else {
                  setOtpNotSentError(true);
                  return;
                }
              })
              .catch((e) => {
                setIsNetworkError(true);
                console.log("SignUp.js 1: ", e);
              })
              .finally(() => {
                setIsLoadingError(false);
              });
          }
        })
        .catch((e) => {
          setIsNetworkError(true);
          console.log("SignUp.js 2: ", e);
        })
        .finally(() => {
          setIsLoadingError(false);
        });
      // }
    }

    if (isSignIn) {
      // console.log("Logging in...");

      // console.log("Logging in...otp call form driver.");
      // otpCallDriver();
      setIsLoadingError(true);
      fetch(`${BASE_URL}${DRIVER_GET_OTP}`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          country_code: countryCode,
          phone_number: onlyNumber,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw response;
        })
        .then((data) => {
          if (data && data.Code === CODE_200) {
            // console.log(data);
            navigateToVerifyOtp();
            return;
          } else if (data && data.Code === CODE_300) {
            // setOtpNotSentError(true);
            setNotSignedUpError(true);
            return;
          }
        })
        .catch((e) => {
          setIsNetworkError(true);
          console.log("Login.js, ", e);
        })
        .finally(() => {
          setIsLoadingError(false);
        });
      // }
    }
  };

  // Mobile number parsing for country code and phone number in locale
  useEffect(() => {
    const parsedValue = parsePhoneNumber(inputNumber || "");
    setCountryCode(parsedValue?.countryCallingCode);
    // console.log("Country code", parsedValue?.countryCallingCode);
    // console.log("National Phone number", parsedValue?.nationalNumber);
    setOnlyNumber(parsedValue?.nationalNumber);
  }, [inputNumber]);

  // changing from login to signup
  useEffect(() => {
    setDisplayVar(isSignIn ? displayVarSignIn : displayVarSignUp);
  }, [isSignIn, displayVarSignIn, displayVarSignUp]);

  return (
    <>
      <RiderDriverSign>
        <AnimationLayout>
          <div className={styles.children}>
            <TextHeadingSign displayText={displayVar.headingText} />
            <TextParaSign displayText={displayVar.paraMessage} />
            <PhoneInput
              countryOptionsOrder={["IN", "CA", "US"]}
              international
              countryCallingCodeEditable
              defaultCountry="IN"
              placeholder="Enter phone number"
              value={inputNumber}
              onChange={inputNumberOnChangeHandler}
              limitMaxLength
            />
            {isErrorDisplay && (
              <ErrorInNumber displayText="This phone number is invalid." />
            )}
            {isNetworkError && (
              <ErrorInNumber displayText="Network / Server error" />
            )}

            {notSignedUpError && (
              <ErrorInNumber displayText="Please Sign Up first" />
            )}
            {isLoadingError && (
              <ErrorInNumber displayText="Sending request..." />
            )}
            {userExistError && (
              <ErrorInNumber displayText="User already present, please login" />
            )}
            {otpNotSentError && <ErrorInNumber displayText="Otp not sent" />}

            <Button
              displayText="Continue"
              onClickFunction={validatePhoneNumberAndNavigate}
            />
            <LoginSignSwitchLine
              displayQuestion={displayVar.questionText}
              displayFunctionText={displayVar.functionText}
              pageSwitchFunction={changeToSignInHandler}
            />
            <div className={styles.termsCondistionRefundPolicy}>
              <div
                className={styles.refundPolicy}
                onClick={() => {
                  navigateToTermsAndConditionPage();
                }}
              >
                <p>TnC's and Privacy Policy</p>
                {/* <BottomMessagePara displayText="TnC's and Privacy Policy" /> */}
              </div>
              <div
                className={styles.refundPolicy}
                onClick={() => {
                  navigateToRefundPolicyPage();
                }}
              >
                <p>Refund Policy</p>
              </div>
            </div>
            <BottomMessagePara
              displayText={`Version - ${GLOBAL_VERSION_CONSTANT}`}
            />
          </div>
        </AnimationLayout>
      </RiderDriverSign>
    </>
  );
};

export default SignUp;
