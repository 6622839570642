import Cookies from "js-cookie";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  START_STOP,
  USER_LOCAL_STORAGE,
} from "../constants/storageConstants";
import { useNavigateOnEvent } from "./use-navigateOnEvent";
import { AUTH_SIGN_UP_ROUTE } from "../constants/routeConstants";
import { useDispatch } from "react-redux";
import { authActions } from "../store/slice";
import { toast } from "react-toastify";
import { generateIntentLink } from "../util";

const useLogoutHandler = () => {
  const dispatch = useDispatch();
  const navigateToLoginPage = useNavigateOnEvent(AUTH_SIGN_UP_ROUTE, true);
  const data = JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE)) || null;
  const logoutFunction = () => {
    // intent
    // if (!data) {
    //   toast.error("unknown Error");
    //   return;
    // }
    if (true) {
      const intentLink = generateIntentLink(
        "logged_out",
        "logged_out",
        "logged_out",
        START_STOP.stop
      );
      Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
      localStorage.clear();
      sessionStorage.clear();
      dispatch(authActions.clearInputNumber());
      dispatch(authActions.clearOTP());

      //navigateToLoginPage
      window.open(intentLink);
      navigateToLoginPage();

      return;
    }

    Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
    localStorage.clear();
    sessionStorage.clear();
    dispatch(authActions.clearInputNumber());
    dispatch(authActions.clearOTP());

    //navigateToLoginPage
    navigateToLoginPage();
  };

  return logoutFunction;
};

export { useLogoutHandler };
