import { BASE_INTENT_LINK, START_STOP } from "../constants/storageConstants";

export const generateIntentLink = (
  accessToken: string,
  userId: string,
  phoneNumber: string,
  startLocationUpdate: string = START_STOP.stop
) => {
  const tokenParam = `S.access_token=${accessToken};`;
  const useParam = `S.user_id=${userId};`;
  const numberParam = `S.phone_number=${phoneNumber};`;
  const locationUpdateParam = `S.start_location_update=${startLocationUpdate};`;
  const endPart = "end";
  const intentLink = `${BASE_INTENT_LINK}${tokenParam}${useParam}${numberParam}${locationUpdateParam}${endPart}`;
  return intentLink;
};
