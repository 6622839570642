// splash screen imports
import splashScreenLandscape from "../assets/images/splashScreenLandscape.png";
import splashScreenTaxiwarsLogo from "../assets/images/splashScreenTaxiwarsLogo.png";
import logoWithTextRow from "../assets/images/logoWithTextRow.png";
import riderDriverSignBottomPicture from "../assets/images/riderDriverSignBottomPicture.png";
// import splashScreenTaxiwarsLogoBlack from "../assets/images/splashScreenTaxiwarsLogoBlack.png";
// import welcomeScreenTwoTaxi from "../assets/images/welcomeScreenTwoTaxi.png";
// import logoWithTextRowBlack from "../assets/images/logoWithTextRowBlack.png";
// import welcomeScreen from "../assets/images/welcomeScreen.png";
import cab from "../assets/images/cab.png";
// import mapMarkerRed from "../assets/images/mapMarkerRed.png";
// import mapMarkerRed32 from "../assets/images/mapMarkerRed32.png";
// import startPointPin from "../assets/images/startPointPin.png";
// import startPointPinGreen from "../assets/images/startPointPinGreen.png";
// import endPointPin from "../assets/images/endPointPin.png";

import markerAppIcon48 from "../assets/images/markerAppIcon48.png";
import userGenericInvert from "../assets/images/userGenericInvert.png";
import walletVector from "../assets/images/walletVector.png";
import clockVector from "../assets/images/clockVector.png";
import carVector from "../assets/images/carVector.png";
import mapMarkerRed32 from "../assets/images/mapMarkerRed32.png";
import startPointPin from "../assets/images/startPointPin.png";
import startPointPinGreen from "../assets/images/startPointPinGreen.png";
import driverCarPin from "../assets/images/driverCarPin.png";
import saveLogs from "../assets/images/saveLogs.png";
import timeCar from "../assets/images/timeCar.png";
import googleMaps from "../assets/images/googleMaps.png";
import click from "../assets/images/click.png";

import arrowBackWhite from "../assets/icons/arrowBackWhite.svg";
import camera from "../assets/icons/camera.svg";
import userGeneric from "../assets/icons/userGeneric.svg";
import hamburgerIcon from "../assets/icons/hamburgerIcon.svg";
import car from "../assets/icons/car.svg";
import clock from "../assets/icons/clock.svg";
import headset from "../assets/icons/headset.svg";
import backArrow from "../assets/icons/backArrow.svg";
import search from "../assets/icons/search.svg";
import clockThin from "../assets/icons/clockThin.svg";
import arrowDown from "../assets/icons/arrowDown.svg";
import star from "../assets/icons/star.svg";
import location from "../assets/icons/location.svg";
import arrrowUpAndDown from "../assets/icons/arrrowUpAndDown.svg";
import hourGlass from "../assets/icons/hourGlass.svg";
import greenCircleBlackTick from "../assets/icons/greenCircleBlackTick.svg";
import orangeStar from "../assets/icons/orangeStar.svg";
import paperMoney from "../assets/icons/paperMoney.svg";
import flatGreenCircleBlackTick from "../assets/icons/flatGreenCircleBlackTick.svg";
import mapThin from "../assets/icons/mapThin.svg";
import arrowSortUpDown from "../assets/icons/arrowSortUpDown.svg";
import logOff from "../assets/icons/logOff.svg";

// eslint-disable-next-line
export default {
  splashScreenLandscape,
  splashScreenTaxiwarsLogo,
  logoWithTextRow,
  riderDriverSignBottomPicture,
  // splashScreenTaxiwarsLogoBlack,
  // welcomeScreenTwoTaxi,
  // logoWithTextRowBlack,
  // welcomeScreen,
  cab,
  // mapMarkerRed,
  // mapMarkerRed32,
  // startPointPin,
  // startPointPinGreen,
  // endPointPin,
  markerAppIcon48,
  userGenericInvert,
  walletVector,
  clockVector,
  carVector,
  mapMarkerRed32,
  startPointPin,
  startPointPinGreen,
  driverCarPin,
  timeCar,
  googleMaps,
  click,

  arrowBackWhite,
  camera,
  userGeneric,
  hamburgerIcon,
  car,
  clock,
  headset,
  backArrow,
  search,
  clockThin,
  arrowDown,
  star,
  location,
  arrrowUpAndDown,
  hourGlass,
  greenCircleBlackTick,
  orangeStar,
  paperMoney,
  flatGreenCircleBlackTick,
  mapThin,
  arrowSortUpDown,
  logOff,

  saveLogs,
};
