import { createSlice } from "@reduxjs/toolkit";

const driverCurrentRideAdvSlice = createSlice({
  name: "driverCurrentRideAdv",
  initialState: {
    currentRideId: "",
    currentRideDetails: {},
  },
  reducers: {
    setCurrentRideId(state, action) {
      state.currentRideId = action.payload;
    },
    setCurrentRideDetails(state, action) {
      state.currentRideDetails = action.payload;
    },
  },
});

export const driverCurrentRideAdvActions = driverCurrentRideAdvSlice.actions;
export default driverCurrentRideAdvSlice;
