import { useEffect, useRef } from "react";

const useWakeLock = () => {
  const wakeLockRef = useRef(null);

  const requestWakeLock = async () => {
    try {
      wakeLockRef.current = await navigator.wakeLock.request("screen");

      wakeLockRef.current.addEventListener("release", onRelease);

      // console.log("Wake Lock is active");
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  };

  const onRelease = () => {
    // console.log("Wake Lock was released");
    wakeLockRef.current.removeEventListener("release", onRelease);
  };

  const handleVisibilityChange = () => {
    if (
      wakeLockRef.current !== null &&
      document.visibilityState === "visible"
    ) {
      requestWakeLock();
    }
  };

  useEffect(() => {
    requestWakeLock();

    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("fullscreenchange", handleVisibilityChange);

    return () => {
      if (wakeLockRef.current !== null) {
        wakeLockRef.current.release();
      }

      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("fullscreenchange", handleVisibilityChange);
    };
  }, []);
};

export { useWakeLock };
