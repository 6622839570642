import React, { useState } from "react";
// eslint-disable-next-line
import styles from "./SelectVehicleTypeModal.module.css";
import { InputOptionBoxForCityDriver } from "../../components";
const SelectVehicleTypeModal = ({ openLogDialogBox = true, updateButton }) => {
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const updateVehicleTypeChangeHandler = (e) => {
    setSelectedVehicleType(e.target.value);
  };
  const updateButtonStateLiftUp = () => {
    // lifting state up
    if (selectedVehicleType === "") return;
    updateButton(selectedVehicleType);
  };

  return (
    <>
      {openLogDialogBox && (
        <div className={styles.selectVehicleTypeModal}>
          <div className={styles.modalBackdrop}>
            <div className={styles.visibleModal}>
              <h3 className={styles.modalMainHeading}>
                Please confirm your Vehicle type
              </h3>
              <div className={styles.inputLabel}>
                <InputOptionBoxForCityDriver
                  disabledOption="Select Vehcile Type"
                  idAndName="updateVehicleDetails"
                  onChange={updateVehicleTypeChangeHandler}
                  // defaultValue={{}}
                  options={["Taxi-Mini", "Taxi-Sedan", "Taxi-SUV"]}
                />
              </div>
              {/* <div className={styles.selectRadio}>Taxi - Mini</div>
              <div>Taxi - Sedan</div>
              <div>Taxi - SUV</div> */}

              <button
                onClick={updateButtonStateLiftUp}
                className={`${styles.button} ${
                  !selectedVehicleType && styles.buttonNoColor
                }`}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectVehicleTypeModal;
