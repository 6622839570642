import { useEffect } from "react";
import { HASH_MAP_HISTORY_SIZE_ARRAY } from "../constants/storageConstants";

const usePreventBackBehaviour = () => {
  useEffect(() => {
    Array.from({ length: HASH_MAP_HISTORY_SIZE_ARRAY }).map((_, index) => {
      window.history.pushState(
        null,
        null,
        `#backButtonDisabled=${JSON.stringify(index + 1)}`
      );
      return null;
    });
  }, []);
};

export { usePreventBackBehaviour };
