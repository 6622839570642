import React from "react";
// eslint-disable-next-line
import styles from "./RefundPolicy.module.css";
import { BackPageHeadingWrapper } from "../../../components";
import { useEffect } from "react";
import { BASE_URL, REFUND_POLICY } from "../../../constants/apiConstants";
import { useState } from "react";

const RefundPolicy = () => {
  const [refundPolicy, setRefundPolicy] = useState();

  useEffect(() => {
    fetch(`${BASE_URL}${REFUND_POLICY}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        // console.log("Refund Policy data", data);
        setRefundPolicy(data);
      })
      .catch((e) => {
        console.log("Refund Policy Error fetchin refund policy", e);
      });
  }, []);

  return (
    <BackPageHeadingWrapper logoHeadingText="Refund Policy">
      <div className={styles.scrollable}>
        <div className={styles.refundPolicy}>
          <h1>{refundPolicy && refundPolicy?.title}</h1>
          <p>{refundPolicy && refundPolicy?.policy}</p>
          {refundPolicy &&
            refundPolicy?.eligibility.map((eachItem) => (
              <>
                <h2 key={eachItem.title}>{eachItem?.title}</h2>
                <ul>
                  {eachItem?.examples.map((eachPoint) => (
                    <li>{eachPoint}</li>
                  ))}
                </ul>
              </>
            ))}
          <p>{refundPolicy && refundPolicy?.safety_incidents}</p>
          <h2>Resolving request</h2>
          <p>{refundPolicy && refundPolicy?.resolving_request?.description}</p>
          <p>
            {refundPolicy && refundPolicy?.resolving_request?.false_reports}
          </p>
        </div>
      </div>
    </BackPageHeadingWrapper>
  );
};

export default RefundPolicy;
