import { createSlice } from "@reduxjs/toolkit";

const fcmTokenLocal = "_fcmTokenLocal_android_";

const initialState = {
  fcm: localStorage.getItem(fcmTokenLocal) || "", // Retrieve from local storage if available
};

const fcmTokenSlice = createSlice({
  name: "fcmToken",
  initialState,
  reducers: {
    /**
     *
     * @param {fcm token string} action
     * Directly sets fcm token
     */
    setFcmToken(state, action) {
      state.fcm = action.payload;
      localStorage.setItem(fcmTokenLocal, action.payload);
    },
  },
});

export const fcmTokenActions = fcmTokenSlice.actions;
export default fcmTokenSlice;
