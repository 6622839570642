import React, { useEffect, useState } from "react";
// eslint-disable-next-line
import styles from "./SuggestionAcceptActiveRideView.module.css";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../constants/storageConstants";
import Cookies from "js-cookie";
import {
  BASE_URL,
  CODE_200,
  DRIVER_VIEW_ALL_BIDS,
  NEW_FAIR_VIEW,
} from "../../constants/apiConstants";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../../components";

const SuggestionAcceptActiveRideView = ({
  // onViewDetailsClickHandler,
  rideId,
  etaTime,
  etaDistance,
}) => {
  const [suggestedData, setSuggestedData] = useState({
    incrementAmount: 0,
    decrementAmount: 0,
    upperLimit: 0,
    lowerLimit: 0,
    suggestedAmount: 0,
  });

  const getSuggestedData = async () => {
    try {
      setPlacingBid("loading");
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      const url = `${BASE_URL}${NEW_FAIR_VIEW}`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          ride_id: rideId,
        }),
      };

      const response = await fetch(url, options);

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setSuggestedData({
          decrementAmount: data?.decrement_amount,
          incrementAmount: data?.increment_amount,
          lowerLimit: data?.lower_limit,
          upperLimit: data?.upper_limit,
          suggestedAmount: data?.suggestion_amount,
        });
        setBidAmount(data?.suggestion_amount);
      }
    } catch (error) {
    } finally {
      setPlacingBid("idle");
    }
  };

  useEffect(() => {
    getSuggestedData();
  }, []);

  const [bidAmount, setBidAmount] = useState(
    () => suggestedData.suggestedAmount
  );

  const [placingBid, setPlacingBid] = useState("idle"); // idle | loading | failed | success

  const incrementAmountHandler = () => {
    setBidAmount((prev) => {
      if (prev + suggestedData.incrementAmount > suggestedData.upperLimit) {
        return prev;
      }

      if (prev + suggestedData.incrementAmount <= suggestedData.upperLimit) {
        return prev + suggestedData.incrementAmount;
      }
    });
  };

  const decrementAmountHandler = () => {
    setBidAmount((prev) => {
      if (prev - suggestedData.decrementAmount < suggestedData.lowerLimit) {
        return prev;
      }

      if (prev - suggestedData.decrementAmount >= suggestedData.lowerLimit) {
        return prev - suggestedData.decrementAmount;
      }
    });
  };

  const onAccept = async () => {
    setPlacingBid("loading");
    try {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      const url = `${BASE_URL}${DRIVER_VIEW_ALL_BIDS}`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          ride: rideId,
          amount: (parseInt(bidAmount) * 100).toString(),
          eta_time: `${etaTime} `,
          eta_distance: `${etaDistance}`,
        }),
      };

      const response = await fetch(url, options);

      if (response.ok) {
        const data = await response.json();
        if (data && data?.code === CODE_200) {
          setPlacingBid("success");
        }
      }
    } catch (error) {
      setPlacingBid("failed");
      console.log(error);
      toast.error("Error occured");
    }
  };

  return (
    <div className={styles.suggestionAcceptActiveRideView}>
      <hr className={styles.longHrLine} />
      <div className={styles.suggestedDataAcceptLine}>
        <div className={styles.suggestedAmountsExtras}>
          {placingBid !== "failed" &&
            placingBid !== "success" &&
            placingBid !== "loading" && (
              <>
                <button
                  onClick={decrementAmountHandler}
                  className={`${styles.buttonGeneral} ${
                    styles.incrementDecrementAmountButton
                  } ${
                    bidAmount - suggestedData.decrementAmount <
                    suggestedData.lowerLimit
                      ? styles.disabledButton
                      : ""
                  }`}
                >
                  {`-${suggestedData.decrementAmount}`}
                </button>
                <span
                  className={`${styles.buttonGeneral} ${styles.suggestedAmount}`}
                >
                  {`₹${bidAmount}`}
                </span>
                <button
                  onClick={incrementAmountHandler}
                  className={`${styles.buttonGeneral} ${
                    styles.incrementDecrementAmountButton
                  } ${
                    bidAmount + suggestedData.incrementAmount >
                    suggestedData.upperLimit
                      ? styles.disabledButton
                      : ""
                  }`}
                >
                  {`+${suggestedData.incrementAmount}`}
                </button>
              </>
            )}
          {placingBid === "loading" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
              }}
              className={`${styles.buttonGeneral} ${styles.acceptPlaceBidButton}`}
            >
              <LoadingSpinner />
            </div>
          )}
          {placingBid === "success" && (
            <p
              style={{ color: "black" }}
              className={`${styles.buttonGeneral} ${styles.acceptPlaceBidButton}`}
            >
              Bid Placed
            </p>
          )}
          {placingBid === "idle" && (
            <button
              onClick={onAccept}
              className={`${styles.buttonGeneral} ${styles.acceptPlaceBidButton}`}
            >
              Accept
            </button>
          )}
        </div>
      </div>
      {/* <button
        onClick={onViewDetailsClickHandler}
        className={`${styles.viewDetailsButton}`}
      >
        Enter Amount
      </button> */}
    </div>
  );
};

export default SuggestionAcceptActiveRideView;
