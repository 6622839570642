import React from "react";
// eslint-disable-next-line
import styles from "./InputBoxLargeMessage.module.css";

const InputBoxLargeMessage = ({ value, onChange }) => {
  return (
    <textarea
      className={styles.inputBoxLargeMessage}
      placeholder="How can we help you?"
      value={value}
      onChange={onChange}
    ></textarea>
  );
};

export default InputBoxLargeMessage;
