import React, { useCallback, useEffect, useState } from "react";
// eslint-disable-next-line
import styles from "./SingleBidDetailCard.module.css";

import {
  CurrentStateTimePriceBids,
  DistancePickupTimeOriginDestination,
} from "../../components";
import { useDispatch } from "react-redux";
import { driverAllRideActions } from "../../store/slice";
import { useNavigateOnEvent } from "../../hooks";
import {
  DRIVER_BID_ACCEPTED_START_RIDE_ROUTE,
  DRIVER_PLACE_A_BID_ROUTE,
  DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE,
} from "../../constants/routeConstants";
import { useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../../constants/apiConstants";

const SingleBidDetailCard = ({
  rideId,
  customerId,
  distance = "NA",
  duration = "NA",
  originAddress,
  removeGoToDetailsArrow = false,
  rideTime = "23:54:05",
  originAddressText = "NA",
  destinationAddressText = "NA",
  bidPlaced = false,
  bidAcceptedBool = false,
  numberOfBids = 1,
  driverBidAmount = 50,
  driverCurrentLocation,
  rideInProgressBool = false,
  rideActiveBool = false,
  rideInPendingBool = false,
  boolUpliftDirectionResponse = false,
  upliftDirectionResponse,
  upliftDirectionServiceState,
}) => {
  const dispatch = useDispatch();

  const [pickupDistance, setPickupDistance] = useState("");
  const [pickupTime, setPickupTime] = useState("");

  const [directionResponseResult, setDirectionResponseResult] = useState("");

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const [driectionServiceState, setDriectionServiceState] = useState();

  useEffect(() => {
    if (isLoaded) {
      // eslint-disable-next-line no-undef
      const directionService = () => new google.maps.DirectionsService();
      setDriectionServiceState(directionService);
    }
  }, [isLoaded]);

  useEffect(() => {
    const [pickupLat, pickupLng] = originAddress.split(",");

    const latLng = { lat: parseFloat(pickupLat), lng: parseFloat(pickupLng) };

    const calculateRoute = async () => {
      if (!driectionServiceState) return;
      const resultsDistanceDuration = await driectionServiceState.route({
        origin: {
          lat: driverCurrentLocation.latitude,
          lng: driverCurrentLocation.longitude,
        },
        destination: latLng,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      });
      // console.log(resultsDistanceDuration);
      if (boolUpliftDirectionResponse) {
        setDirectionResponseResult(resultsDistanceDuration);
      }
      setPickupDistance(
        resultsDistanceDuration.routes[0].legs[0].distance.text
      );
      setPickupTime(resultsDistanceDuration.routes[0].legs[0].duration.text);
    };

    calculateRoute();
  }, [driectionServiceState, driverCurrentLocation, originAddress]);

  const navigateToMeetAtPickupPoint = useNavigateOnEvent(
    DRIVER_BID_ACCEPTED_START_RIDE_ROUTE
  );

  const navigateToInProgressEndRide = useNavigateOnEvent(
    DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE
  );

  // const navigateToViewRideDetails = useNavigateOnEvent(
  //   DRIVER_PLACE_A_BID_ROUTE,
  //   false,
  //   { eta_time: pickupTime, eta_distance: pickupDistance }
  // );
  // calculating display date
  const [displayTime, setDisplayTime] = useState("");
  useEffect(() => {
    if (rideTime === "now") {
      const time = new Date();
      const options = { hour: "numeric", minute: "numeric", hour12: true };
      const formattedTime = time.toLocaleTimeString("en-US", options);

      setDisplayTime(formattedTime);
      return;
    }
    const now = new Date();
    const [hours, minutes, seconds] = rideTime.split(":");
    const time = new Date();
    time.setHours(hours);
    time.setMinutes(minutes);
    time.setSeconds(seconds);
    //comparing
    const isToday =
      now.getDate() === time.getDate() &&
      now.getMonth() === time.getMonth() &&
      now.getFullYear() === time.getFullYear();
    //formatting time
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedTime = time.toLocaleTimeString("en-US", options);
    // if today
    if (isToday) {
      console.log("SingleBidDetailCard.js, Formatted time", formattedTime);
      setDisplayTime(formattedTime);
    }
  }, [rideTime]);

  // const navigateToMeetAtPickupPointHandler = () => {
  //   dispatch(driverAllRideActions.setSelectedRide(rideId));
  //   navigateToMeetAtPickupPoint();
  // };

  const navigateToMeetAtPickupPointHandler = useCallback(() => {
    dispatch(driverAllRideActions.setSelectedRide(rideId));
    navigateToMeetAtPickupPoint();
  }, [dispatch, rideId, navigateToMeetAtPickupPoint]);

  // const navigateToInProgressEndRideHandler = () => {
  //   dispatch(driverAllRideActions.setSelectedRide(rideId));
  //   navigateToInProgressEndRide();
  // };

  const navigateToInProgressEndRideHandler = useCallback(() => {
    dispatch(driverAllRideActions.setSelectedRide(rideId));
    navigateToInProgressEndRide();
  }, [dispatch, rideId, navigateToInProgressEndRide]);

  //
  const onClickHandler = () => {
    console.log("SingleBidDetailCard.js, Value of bidAcceptedBool: ");
    console.log(bidAcceptedBool);
    console.log("SingleBidDetailCard.js, Value of rideInProgressBool: ");
    console.log(rideInProgressBool);

    if (bidAcceptedBool && !rideInProgressBool) {
      console.log("Navigate to meet at pickup point screen...");
      navigateToMeetAtPickupPointHandler();
      return;
    }

    if (!bidAcceptedBool && rideInProgressBool) {
      console.log("SingleBidDetailCard.js, Navigate to In progress screen...");
      navigateToInProgressEndRideHandler();
      return;
    }

    if (rideActiveBool || rideInPendingBool) {
      // we removed this to prevent in progress going back to pending
      // advised to have this implemented in backend
      // so do not uncomment this
      // console.log(
      //   "SingleBidDetailCard.js, Navigate to View ride details screen..."
      // );
      // dispatch(driverAllRideActions.setSelectedRide(rideId));
      // navigateToViewRideDetails();
    }
  };

  useEffect(() => {
    if (bidAcceptedBool && !rideInProgressBool) {
      console.log(
        "SingleBidDetailCard.js, Automatic Navigate to meet at pickup point screen..."
      );
      dispatch(driverAllRideActions.updateBidConfirmedRides([]));
      navigateToMeetAtPickupPointHandler();
      // if (!navigator.serviceWorker.controller) {
      //   dispatch(driverAllRideActions.updateBidConfirmedRides([]));
      //   navigateToMeetAtPickupPointHandler();
      //   return;
      // }

      // const messageChannel = new MessageChannel();
      // navigator.serviceWorker.controller.postMessage(
      //   {
      //     type: "INIT_PORT",
      //   },
      //   [messageChannel.port2]
      // );

      // messageChannel.port1.onmessage = function (event) {
      //   console.log("event in main thread", event);
      //   if (event.data.type === "PORT_INITIALISED") {
      //     navigator.serviceWorker.controller.postMessage({
      //       type: "START_BACKGROUND_LOCATION_UPDATE",
      //     });
      //     dispatch(driverAllRideActions.updateBidConfirmedRides([]));
      //     navigateToMeetAtPickupPointHandler();
      //   }
      // };

      return;
    }

    // redundant , possible need while scheduling
    // if (!bidAcceptedBool && rideInProgressBool) {
    //   console.log("Navigate to In progress screen...");
    //   navigateToInProgressEndRideHandler();
    //   return;
    // }
  }, [
    bidAcceptedBool,
    rideInProgressBool,
    dispatch,
    navigateToMeetAtPickupPointHandler,
  ]);

  useEffect(() => {
    if (boolUpliftDirectionResponse && directionResponseResult) {
      upliftDirectionResponse(directionResponseResult);
    }
  }, [
    boolUpliftDirectionResponse,
    upliftDirectionResponse,
    directionResponseResult,
  ]);

  useEffect(() => {
    if (boolUpliftDirectionResponse && driectionServiceState) {
      upliftDirectionServiceState(driectionServiceState);
    }
  }, [
    driectionServiceState,
    upliftDirectionServiceState,
    boolUpliftDirectionResponse,
  ]);

  return (
    <div
      className={`${styles.singleBidDetailCard} ${
        bidPlaced && styles.bidPlacedWaiting
      } ${bidAcceptedBool && styles.bidAccepted} ${
        rideInProgressBool && styles.bidAccepted
      } ${rideActiveBool && styles.animationFlash}
      `}
      onClick={onClickHandler}
    >
      <CurrentStateTimePriceBids
        rideId={rideId}
        rideTime={displayTime}
        bidPlacedWaiting={bidPlaced}
        numberOfBids={numberOfBids}
        driverBidAmount={driverBidAmount}
        bidAccepted={bidAcceptedBool || rideInProgressBool}
        rideActiveBool={rideActiveBool}
      />
      <DistancePickupTimeOriginDestination
        rideId={rideId}
        customerId={customerId}
        originAddress={originAddressText}
        destinationAddress={destinationAddressText}
        removeGoToDetailsArrow={removeGoToDetailsArrow}
        pickupPoint={pickupDistance}
        pickupTime={pickupTime}
        totalRideDistance={distance}
      />
      <div></div>
    </div>
  );
};

export default SingleBidDetailCard;
