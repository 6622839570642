import React from "react";
// eslint-disable-next-line
import styles from "./LogsBottomModal.module.css";

const LogsBottomModal = ({
  openLogDialogBox = true,
  downloadLogsButton,
  cancelButton,
  cancelledRide = false,
  heading = "Log File Created",
}) => {
  return (
    <>
      {openLogDialogBox && (
        <div className={styles.logsBottomModal}>
          <div className={styles.modalBackdrop}>
            <div className={styles.visibleModal}>
              <h3 className={styles.modalMainHeading}>{heading}</h3>

              {!cancelledRide && (
                <div className={styles.buttonRow}>
                  <button onClick={cancelButton} className={styles.button}>
                    Close
                  </button>
                  <button
                    onClick={downloadLogsButton}
                    className={styles.button}
                  >
                    Download
                  </button>
                </div>
              )}
              {cancelledRide && (
                <div className={styles.buttonRow}>
                  <button onClick={cancelButton} className={styles.button}>
                    Close
                  </button>
                  {/* <button
                    onClick={downloadLogsButton}
                    className={styles.button}
                  >
                    Download
                  </button> */}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LogsBottomModal;
