import styles from "./OverduePopup.module.css";
import Cookies from "js-cookie";
import { BASE_URL, DRIVER_PROFILE_DETAILS } from "../../constants/apiConstants";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  SUPPORT_EMAIL_ID,
  SUPPORT_MOBILE_NUMBERS,
  USER_LOCAL_STORAGE,
} from "../../constants/storageConstants";
import { useEffect, useState } from "react";
import { Button } from "../../components";
import { useNavigateOnEvent } from "../../hooks";
import { DRIVER_BID_SECURITY_CC_AVENUE } from "../../constants/routeConstants";

const THRESHOLD_AMOUNT_SHOW_OVERDUE = 1000; //! 1000 (paise) = ₹10

const OverduePopup = () => {
  const [overdueAmount, setOverdueAmount] = useState({
    status: "loading",
    dueAmount: 0,
  });

  const getOverdueAmount = async () => {
    setOverdueAmount((prev) => {
      return { ...prev, status: "loading" };
    });
    try {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      const resposne = await fetch(`${BASE_URL}${DRIVER_PROFILE_DETAILS}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await resposne.json();

      const amount = data?.due_amount;

      setOverdueAmount((prev) => {
        return { ...prev, status: "success", dueAmount: amount };
      });
    } catch (error) {
      setOverdueAmount((prev) => {
        return { ...prev, status: "failed" };
      });
    }
  };

  useEffect(() => {
    getOverdueAmount();
  }, []);

  const navaigateToAddBidSecurity = useNavigateOnEvent(
    DRIVER_BID_SECURITY_CC_AVENUE,
    false,
    { overdue_amount: overdueAmount.dueAmount }
  );

  const not_available = isNaN(parseFloat(overdueAmount.dueAmount).toFixed(1));
  const zero_amount = parseFloat(overdueAmount.dueAmount).toFixed(1) === "0.0";

  const data = JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE)) || null;

  const disableOverdueAmount =
    data?.phone_number?.toString() === "7309979127" ||
    data?.phone_number?.toString() === "7838712954" ||
    data?.phone_number?.toString() === "7499448917";

  return (
    <>
      {overdueAmount.status === "success" &&
        !zero_amount &&
        parseInt(overdueAmount.dueAmount) >= THRESHOLD_AMOUNT_SHOW_OVERDUE && (
          <div className={styles.overduePopup}>
            <div className={styles.modalBackdrop}>
              <div className={styles.visibleModal}>
                <h1
                  style={{
                    textAlign: "center",
                    margin: "0",
                    marginTop: "1rem",
                    marginBottom: "0.6rem",
                    fontSize: "3rem",
                    fontWeight: "700",
                    textTransform: "uppercase",
                    letterSpacing: "0.025px",
                  }}
                >
                  Overdue Amount
                </h1>
                <p
                  className={`${styles.amountDisplay} ${
                    !zero_amount ? styles.amountRed : ""
                  }`}
                >
                  {not_available
                    ? "NA"
                    : !zero_amount
                    ? `-₹${parseFloat(overdueAmount.dueAmount / 100).toFixed(
                        1
                      )}`
                    : "0"}
                </p>
                {!zero_amount && (
                  <p
                    style={{
                      marginTop: "1.2rem",
                    }}
                    className={`${styles.addMoneyText}`}
                  >
                    Thanks for completing ride
                  </p>
                )}
                {!zero_amount && (
                  <p className={`${styles.addMoneyText}`}>
                    Please clear your dues to continue receiving rides
                  </p>
                )}
                {!zero_amount && (
                  <div
                    style={{
                      marginTop: "1.4rem",
                    }}
                  >
                    <Button
                      style={{
                        fontSize: "1.8rem",
                        padding: "1rem 0",
                      }}
                      displayText="Add Money"
                      onClickFunction={navaigateToAddBidSecurity}
                    />
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "1.2rem",
                  }}
                >
                  <p
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      fontSize: "2rem",
                      letterSpacing: "0.025px",
                    }}
                  >
                    Support numbers
                  </p>
                  <div style={{ marginTop: "0.5rem" }}>
                    <a
                      className={styles.addMoneyText}
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        margin: "0 0.5rem",
                        letterSpacing: "0.025px",
                      }}
                      href={`tel:${SUPPORT_MOBILE_NUMBERS[0]}`}
                    >
                      {SUPPORT_MOBILE_NUMBERS[0]}
                    </a>
                    <span
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        fontSize: "2rem",
                      }}
                    >
                      /
                    </span>
                    <a
                      className={styles.addMoneyText}
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        margin: "0 0.5rem",
                        letterSpacing: "0.025px",
                      }}
                      href={`tel:${SUPPORT_MOBILE_NUMBERS[1]}`}
                    >
                      {SUPPORT_MOBILE_NUMBERS[1]}
                    </a>
                  </div>
                  <div style={{ marginTop: "1rem" }}>
                    <a
                      className={styles.addMoneyText}
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        letterSpacing: "0.025px",
                      }}
                      href={`mailto:${SUPPORT_EMAIL_ID}`}
                    >
                      Support Email - {SUPPORT_EMAIL_ID}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default OverduePopup;
