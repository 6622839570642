import { createSlice } from "@reduxjs/toolkit";

const helpSlice = createSlice({
  name: "help",
  initialState: {
    name: "",
    emailAddress: "",
    phoneNumber: null,
    message: "",
  },
  reducers: {
    updateName(state, action) {
      state.name = action.payload;
    },
    updateEmailAddress(state, action) {
      state.emailAddress = action.payload;
    },
    updatePhoneNumber(state, action) {
      state.phoneNumber = action.payload;
    },
    updateMessage(state, action) {
      state.message = action.payload;
    },
  },
});

export const helpActions = helpSlice.actions;
export default helpSlice;
