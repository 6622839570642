import React from "react";
import {
  ArrowBackButton,
  Button,
  ErrorInNumber,
  InputBox,
  InputOptionBoxForCityDriver,
  RiderDriverSign,
  TextHeadingSign,
} from "../../../components";
// eslint-disable-next-line
import styles from "./DriverAddressDetails.module.css";
import { useDispatch, useSelector } from "react-redux";
import { driverProfileActions } from "../../../store/slice";
import { useState } from "react";
import { useNavigateOnEvent } from "../../../hooks";
import {
  DRIVER_PROFILE_COMPLETE_PROFILE_ROUTE,
  DRIVER_PROFILE_VEHICLE_DETAILS_ROUTE,
} from "../../../constants/routeConstants";

const DriverAddressDetails = () => {
  const dispatch = useDispatch();

  const navigateToVehicleDetails = useNavigateOnEvent(
    DRIVER_PROFILE_VEHICLE_DETAILS_ROUTE,
    true
  );

  const navigateBacktoCompleteProfile = useNavigateOnEvent(
    DRIVER_PROFILE_COMPLETE_PROFILE_ROUTE,
    true
  );

  const [isError, setIsError] = useState({
    addressLineOne: false,
    addressLineTwo: false,
    city: false,
    state: false,
  });

  const addressLineOne = useSelector(
    (state) => state.driverProfile.addressLineOne
  );
  const addressLineTwo = useSelector(
    (state) => state.driverProfile.addressLineTwo
  );
  const city = useSelector((state) => state.driverProfile.city);
  const state = useSelector((state) => state.driverProfile.state);

  const addressLineOneChangeHandler = (e) => {
    dispatch(driverProfileActions.updateAddressLineOne(e.target.value));
    setIsError((prev) => ({ ...prev, addressLineOne: false }));
  };
  const addressLineTwoChangeHandler = (e) => {
    dispatch(driverProfileActions.updateAddressLineTwo(e.target.value));
    setIsError((prev) => ({ ...prev, addressLineTwo: false }));
  };
  const cityChangeHandler = (e) => {
    dispatch(driverProfileActions.updateAddressLineCity(e.target.value));
    setIsError((prev) => ({ ...prev, city: false }));
  };
  const stateChangeHandler = (e) => {
    dispatch(driverProfileActions.updateAddressLineState(e.target.value));
    setIsError((prev) => ({ ...prev, state: false }));
    return;
    // currently not allow State change
    // dispatch(driverProfileActions.updateAddressLineState(e.target.value));
    // setIsError((prev) => ({ ...prev, state: false }));
  };

  const checkValidateAndNavigate = () => {
    setIsError(false);
    // validation
    if (addressLineOne.trim().length === 0) {
      setIsError((prev) => ({ ...prev, addressLineOne: true }));
      return;
    }
    // if (addressLineTwo.trim().length === 0) {
    //   setIsError((prev) => ({ ...prev, addressLineTwo: true }));
    //   return;
    // }
    if (city.trim().length === 0) {
      setIsError((prev) => ({ ...prev, city: true }));
      return;
    }
    if (state.trim().length === 0) {
      setIsError((prev) => ({ ...prev, state: true }));
      return;
    }

    console.log(
      "DriverAddressDetail.js, All checks passed. Navigating to Vehicle details"
    );
    navigateToVehicleDetails();
  };

  return (
    <>
      <RiderDriverSign back driver>
        <div className={styles.children}>
          <TextHeadingSign displayText="Your Address Details" />
          {/* Generic Photo upload */}
          <InputBox
            type="text"
            idAndName="streetAddressOne"
            placeholderText="Address Line 1"
            value={addressLineOne}
            onChange={addressLineOneChangeHandler}
          />
          {isError.addressLineOne && (
            <ErrorInNumber displayText="Street Address 1 cannot be empty" />
          )}
          <InputBox
            type="text"
            idAndName="streetAddressOne"
            placeholderText="Address Line 2 ( Optional )"
            value={addressLineTwo}
            onChange={addressLineTwoChangeHandler}
          />
          {isError.addressLineTwo && (
            <ErrorInNumber displayText="Street Address 2 cannot be empty" />
          )}
          {/* <InputBox
            type="text"
            idAndName="city"
            placeholderText="City"
            value={city}
            onChange={cityChangeHandler}
          /> */}
          {isError.city && <ErrorInNumber displayText="City cannot be empty" />}

          <InputOptionBoxForCityDriver
            idAndName="city"
            onChange={cityChangeHandler}
            defaultValue={city}
            options={["Pune", "Mumbai", "Other City"]}
          />
          <InputOptionBoxForCityDriver
            idAndName="state"
            onChange={stateChangeHandler}
            defaultValue={state}
            options={["Maharashtra", "Other State"]}
          />

          {/* <InputBox
            type="text"
            idAndName="state"
            placeholderText="State"
            value={state}
            onChange={stateChangeHandler}
          /> */}
          {isError.state && (
            <ErrorInNumber displayText="State cannot be empty" />
          )}
          <Button
            displayText="Continue"
            onClickFunction={checkValidateAndNavigate}
          />
        </div>
        <ArrowBackButton onClickFunction={navigateBacktoCompleteProfile} />
      </RiderDriverSign>
    </>
  );
};

export default DriverAddressDetails;
