import React from "react";
// eslint-disable-next-line
import styles from "./InputBox.module.css";

const InputBox = ({
  type = "text",
  idAndName,
  placeholderText = "prop-InputBox",
  value = "",
  onChange,
}) => {
  return (
    <>
      {/* <label for="name"></label> */}
      <input
        className={styles.inputBox}
        type={type}
        id={idAndName}
        name={idAndName}
        placeholder={placeholderText}
        value={value}
        onChange={onChange}
      />
    </>
  );
};

export default InputBox;
