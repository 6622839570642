import styles from "./DriverRideEndOverduePage.module.css";
import {
  Button,
  RiderDriverSign,
  TopGreenCheckStatus,
} from "../../../components";
import { useNavigateOnEvent, usePreventBackBehaviour } from "../../../hooks";
import { DRIVER_BID_SECURITY_CC_AVENUE } from "../../../constants/routeConstants";
import { useEffect, useState } from "react";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../../constants/storageConstants";
import {
  BASE_URL,
  DRIVER_PROFILE_DETAILS,
} from "../../../constants/apiConstants";
import Cookies from "js-cookie";

const DriverRideEndOverduePage = () => {
  usePreventBackBehaviour();

  const [urlParams] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const amount = searchParams.get("collectAmount");

    return { collectAmount: amount };
  });

  const [overdueAmount, setOverdueAmount] = useState({
    status: "loading",
    dueAmount: 0,
  });

  const getOverdueAmount = async () => {
    setOverdueAmount((prev) => {
      return { ...prev, status: "loading" };
    });
    try {
      const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
      const resposne = await fetch(`${BASE_URL}${DRIVER_PROFILE_DETAILS}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await resposne.json();

      const amount = data?.due_amount;

      setOverdueAmount((prev) => {
        return { ...prev, status: "success", dueAmount: amount };
      });
    } catch (error) {
      setOverdueAmount((prev) => {
        return { ...prev, status: "failed" };
      });
    }
  };

  useEffect(() => {
    getOverdueAmount();
  }, []);

  const navaigateToAddBidSecurity = useNavigateOnEvent(
    DRIVER_BID_SECURITY_CC_AVENUE,
    false,
    { overdue_amount: overdueAmount.dueAmount }
  );

  return (
    <RiderDriverSign driver>
      <div className={styles.children}>
        <TopGreenCheckStatus
          style={{ fontSize: "2.8rem" }}
          displayText="Ride Completed"
        />
        <p style={{ fontSize: "2.8rem", marginTop: "2rem" }}>
          Please collect{" "}
          <span
            style={{
              fontSize: "3rem",
              textAlign: "center",
              color: "green",
              fontWeight: "bolder",
            }}
          >
            ₹ {urlParams.collectAmount}
          </span>{" "}
          from customer
        </p>
        <div
          style={{
            marginBottom: "1.8rem",
          }}
        >
          <p
            style={{
              marginTop: "3rem",
              fontSize: "2.8rem",
              marginBottom: "0.2rem",
            }}
          >
            Your total overdue amount
          </p>
          <span
            style={{
              fontSize: "2.8rem",
              color: "#E72222",
              fontWeight: "bold",
            }}
          >{`₹ ${(overdueAmount.dueAmount / 100).toFixed(1)}`}</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            gap: "1.6rem",
          }}
        >
          <Button
            displayText="Pay Now"
            onClickFunction={navaigateToAddBidSecurity}
            style={{
              fontSize: "3.4rem",
              color: "white",
              // color: "black",
              paddingTop: "0.6rem",
              paddingBottom: "0.6rem",
            }}
          />
        </div>
        <Button
          displayText="Pay Later"
          onClickFunction={() => {
            // navigate(DRIVER_VIEW_RIDES_MY_BIDS_ROUTE);
          }}
          style={{
            display: "none",
            backgroundColor: "transparent",
            // boxShadow: "inset 0 0 0 1px orange",
            width: "max-content",
            borderRadius: "5px",
            fontSize: "3rem",
            color: "#374151",
            marginTop: "6rem",
            paddingTop: "0.6rem",
            paddingBottom: "0.6rem",
          }}
        />
      </div>
    </RiderDriverSign>
  );
};

export default DriverRideEndOverduePage;
