// eslint-disable-next-line
import styles from "./PlaceBidButton.module.css";

import { Button } from "../../components";

const PlaceBidButton = ({
  disabledState = false,
  value,
  displayText,
  onClickFunction,
  onChange,
  onFocusChange,
  inputRef,
}) => {
  const handleFocus = () => {
    if (onFocusChange) {
      onFocusChange(true);
    }
  };

  const handleBlur = () => {
    if (onFocusChange) {
      onFocusChange(false);
    }
  };

  return (
    <div className={styles.placeBidButton}>
      <div className={styles.infoBar}>
        {!disabledState && (
          <input
            ref={inputRef}
            className={styles.inputStyle}
            type="number"
            value={value}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        )}
        {disabledState && (
          <input
            disabled
            // defaultValue={value.toString()}
            className={styles.inputStyle}
            type="text"
            value={value.toString()}
            onChange={() => {}}
          />
        )}
      </div>
      <Button displayText={displayText} onClickFunction={onClickFunction} />
    </div>
  );
};

export default PlaceBidButton;
