// import React, { useMemo, useRef } from "react";
import {
  BackPageHeadingWrapper,
  // BidCard,
  BottomMessagePara,
  // ErrorInNumber,
  // FairSuggestionPopUp,
  // GoogleMapArrivingSoonOtpDriverLocation,
  // LoadingSpinner,
  // OtherDriverBidCard,
  // PlaceBidButton,
  // SingleBidDetailCardPlaceBid,
} from "../../../components";
// import {
//   BASE_URL,
//   DRIVER_VIEW_ALL_BIDS,
// } from "../../../constants/apiConstants";
// import { useAPI, useDebounce } from "../../../hooks";
// eslint-disable-next-line
// import styles from "./ViewDetailsAndPlaceBid.module.css";
// import { useSelector } from "react-redux";
// import { useEffect } from "react";
// import {
//   BASE_URL,
//   CODE_200,
//   CODE_400,
//   DRIVER_VIEW_ALL_BIDS,
//   RIDE_STATUS_BID_CONFIRMED,
//   RIDE_STATUS_PENDING,
//   SUGGESTED_FAIR,
// } from "../../../constants/apiConstants";
// import Cookies from "js-cookie";
// import { ACCESS_TOKEN_COOKIE_NAME } from "../../../constants/storageConstants";
// import { useState } from "react";
// import { useGeolocation, useNavigateOnEvent } from "../../../hooks";
// import { DRIVER_VIEW_RIDES_MY_BIDS_ROUTE } from "../../../constants/routeConstants";
// import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
// import { images } from "../../../images";

const ViewDetailsAndPlaceBid = () => {
  // const [location, setLocation] = useState();

  // const [calculatedFairData, setCalculatedFairData] = useState({});
  // // const [suggestedFairPopUp, setsuggestedFairPopUp] = useState(false);
  // const [isInputFocused, setIsInputFocused] = useState(false);
  // const [countInputFocused, setCountInputFocused] = useState(0);
  // const [errorAmount, setErrorAmount] = useState({
  //   lower: 0,
  //   upper: 0,
  // });

  // const inputRef = useRef(null);

  // const etaData = useLocation();
  // // console.log("Eta data", etaData);
  // const { state } = etaData;

  // // console.log("ETA_STATE: ", state); // { name: "John Doe", age: 25 }

  // const options = useMemo(
  //   () => ({
  //     enableHighAccuracy: true,
  //     maximumAge: 1 * 1000,
  //     timeout: 5 * 1000,
  //   }),
  //   []
  // );

  // const {
  //   loading: loadingGeoLoc,
  //   error: errorGeoLoc,
  //   data: dataGeoLoc,
  // } = useGeolocation(options);

  // useEffect(() => {
  //   if (loadingGeoLoc) return;
  //   if (errorGeoLoc) return;

  //   // console.log("Data current location", dataGeoLoc);
  //   setLocation({
  //     latitude: dataGeoLoc.latitude,
  //     longitude: dataGeoLoc.longitude,
  //   });
  // }, [loadingGeoLoc, errorGeoLoc, dataGeoLoc]);

  // const navigateackToDriverHomeScreen = useNavigateOnEvent(
  //   DRIVER_VIEW_RIDES_MY_BIDS_ROUTE
  // );
  // const selectedRide = useSelector(
  //   (state) => state.driverAllRide.selectedRideDetails
  // );
  // const rideId = useSelector((state) => state.driverAllRide.selectedRide);

  // // TODO Implementation at later stages
  // const [bidData, setBidData] = useState([]);

  // const bidDataDUMMY = [
  //   {
  //     id: "5",
  //     amount: "500",
  //     car_name: "Ferrari",
  //     driver_rating: "3.1",
  //     averagePrice: false,
  //   },
  //   {
  //     id: "6",
  //     amount: "400",
  //     car_name: "Alto",
  //     driver_rating: "3.2",
  //   },
  //   {
  //     id: "7",
  //     amount: "300",
  //     car_name: "Swift tour",
  //     driver_rating: "3.3",
  //   },
  //   {
  //     id: "8",
  //     amount: "100",
  //     car_name: "indica",
  //     driver_rating: "3.4",
  //   },
  //   {
  //     id: "9",
  //     amount: "50",
  //     car_name: "gypsy",
  //     driver_rating: "3.5",
  //   },
  //   {
  //     id: "10",
  //     amount: "900",
  //     car_name: "scoprio",
  //     driver_rating: "3.6",
  //   },
  //   {
  //     id: "11",
  //     amount: "500",
  //     car_name: "thar",
  //     driver_rating: "3.7",
  //     averagePrice: false,
  //   },
  //   {
  //     id: "12",
  //     amount: "1300",
  //     car_name: "lamborghini",
  //     driver_rating: "3.8",
  //   },
  //   {
  //     id: "13",
  //     amount: "1900",
  //     car_name: "Maruti Suzuki Swift dzire",
  //     driver_rating: "3.9",
  //   },
  //   {
  //     id: "14",
  //     amount: "1400",
  //     car_name: "Tiago",
  //     driver_rating: "4.1",
  //   },
  //   {
  //     id: "15",
  //     amount: "1800",
  //     car_name: "tigor",
  //     driver_rating: "4.2",
  //   },
  //   {
  //     id: "16",
  //     amount: "1700",
  //     car_name: "TUV300",
  //     driver_rating: "4.3",
  //   },
  //   {
  //     id: "17",
  //     amount: "1600",
  //     car_name: "XUV500",
  //     driver_rating: "4.4",
  //   },
  //   {
  //     id: "18",
  //     amount: "1350",
  //     car_name: "Bolero",
  //     driver_rating: "4.5",
  //   },
  //   {
  //     id: "19",
  //     amount: "1950",
  //     car_name: "wagon r",
  //     driver_rating: "4.6",
  //   },
  //   {
  //     id: "20",
  //     amount: "550",
  //     car_name: "Alto",
  //     driver_rating: "4.7",
  //   },
  //   {
  //     id: "21",
  //     amount: "1850",
  //     car_name: "nexon",
  //     driver_rating: "4.8",
  //   },
  //   {
  //     id: "22",
  //     amount: "1650",
  //     car_name: "mercedes",
  //     driver_rating: "4.9",
  //   },
  //   {
  //     id: "23",
  //     amount: "1550",
  //     car_name: "BMW x5",
  //     driver_rating: "5",
  //   },
  //   {
  //     id: "24",
  //     amount: "1250",
  //     car_name: "Mg hector",
  //     driver_rating: "5",
  //   },
  //   {
  //     id: "25",
  //     amount: "1200",
  //     car_name: "Splendor",
  //     driver_rating: "2.5",
  //   },
  //   {
  //     id: "26",
  //     amount: "2150",
  //     car_name: "jupitor",
  //     driver_rating: "1.5",
  //   },
  //   {
  //     id: "27",
  //     amount: "6500",
  //     car_name: "activa",
  //     driver_rating: "4",
  //   },
  //   {
  //     id: "28",
  //     amount: "7500",
  //     car_name: "Tiago",
  //     driver_rating: "4.2",
  //   },
  //   {
  //     id: "29",
  //     amount: "1221",
  //     car_name: "Tiago",
  //     driver_rating: "4.3",
  //   },
  //   {
  //     id: "30",
  //     amount: "1331",
  //     car_name: "Tiago",
  //     driver_rating: "4.1",
  //   },
  //   {
  //     id: "31",
  //     amount: "1661",
  //     car_name: "Tiago",
  //     driver_rating: "3.2",
  //   },
  //   {
  //     id: "32",
  //     amount: "1881",
  //     car_name: "Tiago",
  //     driver_rating: "4.6",
  //   },
  //   {
  //     id: "33",
  //     amount: "1991",
  //     car_name: "Tiago",
  //     driver_rating: "4.7",
  //   },
  //   {
  //     id: "34",
  //     amount: "1151",
  //     car_name: "Tiago",
  //     driver_rating: "2.5",
  //   },
  //   {
  //     id: "35",
  //     amount: "1251",
  //     car_name: "Tiago",
  //     driver_rating: "4.5",
  //   },
  //   {
  //     id: "36",
  //     amount: "1351",
  //     car_name: "Tiago",
  //     driver_rating: "3.5",
  //   },
  // ];

  // const [bidValue, setBidValue] = useState("");

  // const [bidValueError, setBidValueError] = useState(false);

  // const [ridePolyLineResult, setRidePolyLineResult] = useState("");

  // const [secondRidePolylineResult, setSecondRidePolylineResult] = useState("");

  // useEffect(() => {
  //   // console.log("Selected Ride Id", rideId);
  //   // console.log("URL = ", `${BASE_URL}${DRIVER_VIEW_ALL_BIDS}${rideId}/`);
  //   const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  //   fetch(`${BASE_URL}${DRIVER_VIEW_ALL_BIDS}${rideId}/`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         // console.log("fetch all bids of a ride ");
  //         return response.json();
  //       }
  //       throw response;
  //     })
  //     .then((data) => {
  //       // console.log("Recieved list of bids", data);
  //       setBidData(data);
  //     })
  //     .catch((e) => {
  //       console.log("ViewDetailsAndPlaceBid.js, Error occured", e);
  //     });
  // }, [rideId]);

  // const onBidValueChangeHandler = (e) => {
  //   setBidValueError(false);
  //   const inputValue = e.target.value;
  //   const intValue = parseInt(inputValue);

  //   if (isNaN(intValue)) {
  //     // console.log("value is NaN");
  //     setBidValueError(false);
  //     setBidValue("");
  //   }

  //   // console.log(intValue);
  //   if (Number.isInteger(intValue) && intValue >= 0) {
  //     if (intValue <= 99999) {
  //       setBidValueError(false);
  //       setBidValue(intValue);
  //       return;
  //     } else {
  //       // setBidValueError(true);
  //       return;
  //     }
  //   }
  // };

  // const placingBidHandler = () => {
  //   console.log(
  //     "ViewDetailsAndPlaceBid.js, Ride ID - ",
  //     rideId,
  //     "Bid Value - ",
  //     bidValue
  //   );
  //   const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

  //   if (
  //     parseFloat(bidValue) <= 0 ||
  //     parseFloat(bidValue) < errorAmount.lower ||
  //     parseFloat(bidValue) > errorAmount.upper
  //   ) {
  //     setBidValueError(true);
  //     return;
  //   }

  //   toast.info("  Placing bid...", {
  //     toastId: "placing-bid",
  //     icon: <LoadingSpinner />,
  //     autoClose: false,
  //   });
  //   fetch(`${BASE_URL}${DRIVER_VIEW_ALL_BIDS}`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //     body: JSON.stringify({
  //       ride: rideId,
  //       amount: (parseInt(bidValue) * 100).toString(),
  //       eta_time: `${state?.eta_time} `,
  //       eta_distance: `${state?.eta_distance}`,
  //     }),
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         // console.log("response ok...");

  //         return response.json();
  //       }
  //       throw response;
  //     })
  //     .then((data) => {
  //       // console.log("Data", data);
  //       // toast.success("Bid Placed");

  //       if (data && data?.code === CODE_200) {
  //         // console.log("Else if bid placed place bid block");
  //         toast.update("placing-bid", {
  //           render: data?.message || "Bid Placed.",
  //           type: toast.TYPE.SUCCESS,
  //           autoClose: 20000,
  //           closeButton: true,
  //           icon: null,
  //         });
  //         navigateackToDriverHomeScreen();
  //       } else if (data && data?.code === CODE_400) {
  //         // console.log("Else if ride cancelled place bid block");
  //         toast.update("placing-bid", {
  //           render: data?.message || "Ride cancelled.",
  //           type: toast.TYPE.INFO,
  //           autoClose: 20000,
  //           closeButton: true,
  //           icon: null,
  //         });
  //         navigateackToDriverHomeScreen();
  //       } else {
  //         // console.log("Else place bid block");
  //         toast.update("placing-bid", {
  //           render: "Something went wrong",
  //           type: toast.TYPE.INFO,
  //           autoClose: 20000,
  //           closeButton: true,
  //           icon: null,
  //         });
  //         navigateackToDriverHomeScreen();
  //       }
  //     })
  //     .catch((e) => {
  //       console.log("ViewDetailsAndPlaceBid.js, an errror occured - ", e);
  //     });
  // };

  // const ridePolylineUplifthandler = (result) => {
  //   setRidePolyLineResult(result);
  //   // console.log("THE POLYLINE RESULT", result);
  // };
  // const [resultt, setResultt] = useState("");

  // const directionResponseUpliftStateHandler = (result) => {
  //   // console.log("Hello");
  //   setResultt(result);
  // };

  // useEffect(() => {
  //   const calculateRoutePolyLine = async () => {
  //     if (!resultt) return;
  //     if (!location) return;
  //     if (!ridePolyLineResult) return;
  //     // console.log(ridePolyLineResult);
  //     const resultsDistanceDurationPolyline = await resultt.route({
  //       origin: selectedRide?.pickup_address,
  //       destination: selectedRide?.drop_address,

  //       // destination: {
  //       //   lat: parseFloat(
  //       //     ridePolyLineResult.routes[0].legs[0].end_location.lat()
  //       //   ),
  //       //   lng: parseFloat(
  //       //     ridePolyLineResult.routes[0].legs[0].end_location.lng()
  //       //   ),
  //       // },
  //       // eslint-disable-next-line no-undef
  //       travelMode: google.maps.TravelMode.DRIVING,
  //     });
  //     // console.log(
  //     //   "resultsDistanceDurationPolyline",
  //     //   resultsDistanceDurationPolyline
  //     // );
  //     setSecondRidePolylineResult(resultsDistanceDurationPolyline);
  //   };

  //   calculateRoutePolyLine();
  // }, [location, resultt, ridePolyLineResult]);

  // useEffect(() => {
  //   const getCalculatedFair = async () => {
  //     const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  //     const fetchUrl = `${BASE_URL}${SUGGESTED_FAIR}`;
  //     const fetcfhOptions = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //       body: JSON.stringify({
  //         ride_id: rideId,
  //       }),
  //     };

  //     const response = await fetch(fetchUrl, fetcfhOptions);

  //     if (!response.ok) {
  //       return;
  //     }

  //     const data = await response.json();
  //     console.log(data);

  //     // const data = {
  //     //   calculated_amount: 550,
  //     //   rate_per_km: 10,
  //     //   total_ride_distance: 10,
  //     //   pickup_charge: 50,
  //     // };
  //     if (!data) {
  //       // setsuggestedFairPopUp(false);
  //       setIsInputFocused(false);

  //       return;
  //     }

  //     console.log("data", data);
  //     setCalculatedFairData({
  //       calculatedAmount: data?.calculated_amount,
  //       ratePerKm: data?.rate_per_km,
  //       totalRideDistance: data?.total_ride_distance,
  //       pickupCharge: data?.pickup_charge,
  //       multiplicationFactor: data?.multiple_factor_fair,
  //     });
  //     setErrorAmount({
  //       lower: Math.round((data?.calculated_amount * 3) / 4),
  //       upper: Math.round(data?.calculated_amount * 1.15),
  //     });
  //   };

  //   getCalculatedFair();
  // }, [rideId]);

  // const onFocusChangeHandler = (focused) => {
  //   if (countInputFocused === 1) return;
  //   if (!calculatedFairData) return;
  //   setIsInputFocused(focused); // Update the focus state in the parent component
  //   setCountInputFocused(1);
  //   // if (inputRef.current) {
  //   //   inputRef.current.blur();
  //   // }
  // };

  // const onOkayCloseHandler = () => {
  //   // setsuggestedFairPopUp(false);
  //   setIsInputFocused(false);

  //   // set input to focus
  //   // set the value
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  //   onBidValueChangeHandler({
  //     target: { value: calculatedFairData.calculatedAmount },
  //   });
  // };

  // const onEnterAmountHandler = () => {
  //   // setsuggestedFairPopUp(false);
  //   setIsInputFocused(false);
  //   // only on focus no value
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // };

  return (
    <>
      <BackPageHeadingWrapper logoHeadingText="Place a bid">
        <BottomMessagePara displayText="Page no longer available" />
      </BackPageHeadingWrapper>
    </>
  );
};

export default ViewDetailsAndPlaceBid;

// Inside BackPageHeadingWrapper
// {calculatedFairData &&
//   calculatedFairData.calculatedAmount &&
//   calculatedFairData.ratePerKm &&
//   calculatedFairData.pickupCharge &&
//   calculatedFairData.multiplicationFactor && (
//     <FairSuggestionPopUp
//       openBool={isInputFocused}
//       onEnterAmountHandler={onEnterAmountHandler}
//       onOkayCloseHandler={onOkayCloseHandler}
//       details={{
//         calculatedAmount: calculatedFairData.calculatedAmount,
//         ratePerKm: calculatedFairData.ratePerKm,
//         totalRideDistance: calculatedFairData.totalRideDistance,
//         pickupCharge: calculatedFairData.pickupCharge,
//         multiplicationFactor: calculatedFairData.multiplicationFactor,
//       }}
//     />
//   )}
// {location && ridePolyLineResult && secondRidePolylineResult && (
//   <GoogleMapArrivingSoonOtpDriverLocation
//     mapCenterlatLng={ridePolyLineResult.routes[0].legs[0].end_location}
//     directionResponseList={[
//       { id: 1, response: ridePolyLineResult, color: "#F17257" },
//       { id: 2, response: secondRidePolylineResult, color: "#000" },
//     ]}
//     markerList={[
//       {
//         id: 1,
//         position: ridePolyLineResult.routes[0].legs[0].start_location,
//         icon: images.driverCarPin,
//       },
//       {
//         id: 2,
//         position:
//           secondRidePolylineResult.routes[0].legs[0].start_location,
//         icon: images.startPointPinGreen,
//       },
//       {
//         id: 3,
//         position:
//           secondRidePolylineResult.routes[0].legs[0].end_location,
//         icon: images.startPointPin,
//       },
//     ]}
//   />
// )}
// <div className={styles.viewDetailsAndPlaceBid}>
//   {!loadingGeoLoc && (
//     <SingleBidDetailCardPlaceBid
//       rideId={rideId}
//       customerId={selectedRide?.customer_id}
//       distance={selectedRide?.distance}
//       originAddress={selectedRide?.pickup_address}
//       destinationAddress={selectedRide?.drop_address}
//       originAddressText={selectedRide?.pickup_address_text}
//       destinationAddressText={selectedRide?.drop_address_text}
//       rideTime={selectedRide?.ride_time}
//       bidPlaced={selectedRide?.ride_status === RIDE_STATUS_PENDING}
//       bidAcceptedBool={
//         selectedRide?.ride_status === RIDE_STATUS_BID_CONFIRMED
//       }
//       numberOfBids={
//         selectedRide?.bid_count === 0 ? "No" : selectedRide?.bid_count
//       }
//       // bidPlaced={selectedRide?.ride_status === "pending"}
//       // bidAcceptedBool={selectedRide?.ride_status === "accepted"}
//       // numberOfBids={selectedRide?.number_of_bids}
//       driverBidAmount={selectedRide?.driver_bid_amount}
//       removeGoToDetailsArrow
//       driverCurrentLocation={location}
//       boolUpliftDirectionResponse={true}
//       upliftDirectionResponse={ridePolylineUplifthandler}
//       upliftDirectionServiceState={directionResponseUpliftStateHandler}
//     />
//   )}

//   {Array.isArray(bidData) &&
//     bidData.length > 0 &&
//     bidData.map((eachBid) => (
//       <React.Fragment key={eachBid.id}>
//         {/* <OtherDriverBidCard
//         // key={eachBid.id}
//         bidId={eachBid.id}
//         price={eachBid.amount}
//         vehicleName={eachBid.car_name}
//         rating={eachBid.driver_rating}
//         averagePrice={eachBid?.averagePrice}
//       /> */}
//         <BidCard
//           // key={eachBid.id}
//           bidId={eachBid.id}
//           selectedBidId={eachBid.id}
//           driverImage={eachBid.driver_image}
//           vehicleName={eachBid.vehicle_name}
//           vehicleImage={eachBid.vehicle_image}
//           vehicleType={eachBid.vehicle_type}
//           price={eachBid?.amount}
//         />
//       </React.Fragment>
//     ))}
//   {bidValueError && (
//     <ErrorInNumber
//       extraMessage="फिर भी OLA Uber से ज्यादा कमाएंगे आप"
//       fontSizeLarge={true}
//       displayText={`${errorAmount.lower} < Bid amount < ${errorAmount.upper}`}
//     />
//   )}
//   <PlaceBidButton
//     inputRef={inputRef}
//     onFocusChange={onFocusChangeHandler}
//     value={bidValue}
//     onChange={onBidValueChangeHandler}
//     displayText="Place your bid"
//     onClickFunction={placingBidHandler}
//   />
//   {/* <BottomMessagePara displayText="Other drivers can also see your bid. You will be notified if the rider accepts your bid." /> */}
//   <BottomMessagePara displayText="Place your bid for this ride." />
//   {false && (
//     <div className={styles.otherDriverBids}>
//       {false &&
//         Array.isArray(bidDataDUMMY) &&
//         bidDataDUMMY.length > 0 &&
//         bidDataDUMMY
//           .sort((a, b) => parseInt(a.amount) - parseInt(b.amount))
//           .map((eachBid) => (
//             <React.Fragment key={eachBid.id}>
//               <OtherDriverBidCard
//                 // key={eachBid.id}
//                 bidId={eachBid.id}
//                 price={eachBid.amount}
//                 vehicleName={eachBid.car_name}
//                 rating={eachBid.driver_rating}
//                 averagePrice={eachBid?.averagePrice}
//               />
//               {/* <BidCard
//         // key={eachBid.id}
//         bidId={eachBid.id}
//         selectedBidId={eachBid.id}
//         driverImage={eachBid.driver_image}
//         vehicleName={eachBid.vehicle_name}
//         vehicleImage={eachBid.vehicle_image}
//         price={eachBid?.amount}
//       /> */}
//             </React.Fragment>
//           ))}
//     </div>
//   )}
// </div>
