import { useEffect } from "react";
// eslint-disable-next-line
import styles from "./SplashScreen.module.css";
import { images } from "../../../images";
import {
  useInterval,
  useLocalStorage,
  useNavigateOnEvent,
} from "../../../hooks";
import {
  AUTH_SIGN_UP_ROUTE,
  DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
} from "../../../constants/routeConstants";
import { motion } from "framer-motion";
import Cookies from "js-cookie";
import { ONE_SECOND } from "../../../constants/timeConstants";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  DRIVER_NAME,
  NULL,
  UNDEFINED,
  USER_AGENT,
  USER_AGENT_DRIVER,
} from "../../../constants/storageConstants";
import {} from "../../../constants/apiConstants";
import { useDispatch } from "react-redux";
import { fcmTokenActions } from "../../../store/slice";

const SplashScreen = () => {
  const dispatch = useDispatch();
  const [, setUserAgent] = useLocalStorage(USER_AGENT);
  const [driverName] = useLocalStorage(DRIVER_NAME);

  const navigateToSignUp = useNavigateOnEvent(AUTH_SIGN_UP_ROUTE, true);
  const navigateToDriverViewRides = useNavigateOnEvent(
    DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
    true
  );

  //

  // useEffect(() => {
  //   const confirmDataAndAuth = async (ride, bid, user, mobile, paystatus) => {
  //     const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  //     const fetchConfirmUrl = `${BASE_URL}${RIDER_PROFILE_DETAILS}`;
  //     const fetchConfirmOptions = {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     };

  //     const response = await fetch(fetchConfirmUrl, fetchConfirmOptions);

  //     if (response.status !== 200) {
  //       logoutFunction();
  //     }

  //     // console.log("Response", response);

  //     if (response.ok) {
  //       const data = await response.json();
  //       const phoneNumber = data.phone_number;
  //       const userId = data.user_id;
  //       console.log("payment data", data);
  //       if (
  //         phoneNumber.toString() !== mobile.toString() &&
  //         userId.toString() !== user.toString()
  //       ) {
  //         logoutFunction();
  //         return;
  //       }
  //       if (
  //         phoneNumber.toString() === mobile.toString() &&
  //         userId.toString() === user.toString() &&
  //         paystatus.toString() === "Success"
  //       ) {
  //         toast.success("Payment Success");
  //         // navigateToRideArrivingScreen();
  //         navigate(`${RIDER_RIDE_ARRIVING_ROUTE}?rideId=${ride}&bidId=${bid}`, {
  //           replace: true,
  //         });
  //         return;
  //       }
  //       if (
  //         phoneNumber.toString() === mobile.toString() &&
  //         userId.toString() === user.toString() &&
  //         paystatus.toString() === "Failure"
  //       ) {
  //         toast.error("Payment Failed");
  //         // navigateToWaitingForBidScreen();
  //         navigate(
  //           `${RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE}?rideId=${ride}`,
  //           {
  //             replace: true,
  //           }
  //         );
  //         return;
  //       }
  //       // if (
  //       //   phoneNumber.toString() === mobile.toString() &&
  //       //   userId.toString() === user.toString() &&
  //       //   paystatus.toString() === "erroroccured"
  //       // ) {
  //       //   toast.error("Error occured");
  //       //   navigateToWaitingForBidScreen();
  //       // }
  //       // if (
  //       //   phoneNumber.toString() === mobile.toString() &&
  //       //   userId.toString() === user.toString() &&
  //       //   paystatus.toString() !== "Failure" &&
  //       //   paystatus.toString() !== "Success"
  //       // ) {
  //       //   toast.error("Something went wrong");
  //       //   navigateToWaitingForBidScreen();
  //       // }
  //       toast.info("Waiting...");
  //       navigate(
  //         `${RIDER_RIDE_CONFIRMED_MAKE_PAYMENT_ROUTE_CC_AVENUE_WAITING_PAGE}?rideId=${ride}&bidId=${bid}`,
  //         {
  //           replace: true,
  //         }
  //       );
  //     }
  //   };

  //   const searchParams = new URLSearchParams(window.location.search);
  //   const ride = searchParams.get("ride");
  //   const bid = searchParams.get("bid");
  //   const user = searchParams.get("user");
  //   const mobile = searchParams.get("mobile");
  //   const paystatus = searchParams.get("paystatus");

  //   if (ride && bid && user && mobile && paystatus) {
  //     confirmDataAndAuth(ride, bid, user, mobile, paystatus);
  //   }
  //   console.log("Redirected url params", ride, bid, user, mobile, paystatus);
  // }, []);

  //

  useEffect(() => {
    setUserAgent(USER_AGENT_DRIVER);
  }, [setUserAgent]);

  useInterval(() => {
    // console.log("Checking access token and driver name...");
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    if (
      !accessToken ||
      accessToken === null ||
      accessToken === undefined ||
      accessToken === NULL ||
      accessToken === UNDEFINED ||
      !driverName ||
      driverName === null ||
      driverName === undefined ||
      driverName === NULL ||
      driverName === UNDEFINED
    ) {
      // console.log("Access token or driver name not found...");
      // console.log("Access token - ");
      // console.log(accessToken);
      // console.log("Driver name - ");
      // console.log(driverName);
      // console.log("Navigating to Signup...");
      navigateToSignUp();
      return;
    }

    // console.log("Found access token - ");
    // console.log(accessToken);

    // console.log("Driver name found - ");
    // console.log(driverName);

    navigateToDriverViewRides();
  }, ONE_SECOND);

  const variants = {
    initial: {
      opacity: 0,
      scale: 0,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      variants={variants}
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        className={styles.splashScreenTaxiwarsLogo}
        src={images.splashScreenTaxiwarsLogo}
        alt="taxiwars logo with text"
      />

      <img
        className={styles.splashScreenLandscape}
        src={images.splashScreenLandscape}
        alt="splash screen landscape"
      />
    </motion.div>
  );
};

export default SplashScreen;
