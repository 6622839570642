import { useNavigate } from "react-router-dom";

function useNavigateOnEvent(url, replace = false, state = {}) {
  const navigate = useNavigate();

  function navigateOnEvent() {
    navigate(url, { replace, state: state });
  }

  return navigateOnEvent;
}

export { useNavigateOnEvent };
