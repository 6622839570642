import React, { useState } from "react";
import {
  Button,
  FloatingActionButton,
  LoadingSpinner,
  MenuTextUserWrapper,
  PlaceBidButton,
  RiderNamePickupDropCancelCard,
  SosButton,
  TopGreenCheckStatus,
  YourEarningCommission,
} from "../../../components";
// eslint-disable-next-line
import styles from "./RideDetailsEndRide.module.css";

import {
  useDriverFetchRideDetails,
  useNavigateOnEvent,
  usePreventBackBehaviour,
  useStopBackgroundLocationUpdate,
} from "../../../hooks";
import Cookies from "js-cookie";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../../constants/storageConstants";
import { BASE_URL, DRIVER_END_RIDE } from "../../../constants/apiConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  DRIVER_RIDE_END_OVERDUE_SCREEN,
  DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
} from "../../../constants/routeConstants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { driverAllRideActions } from "../../../store/slice";

const RideDetailsEndRide = () => {
  usePreventBackBehaviour();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useStopBackgroundLocationUpdate();
  const { rideDetails, windowOpenGoogleMapHandler } =
    useDriverFetchRideDetails();

  const selectedRideId = useSelector(
    (state) => state.driverCurrentRide.currentRideId
  );

  // useEffect(() => {
  //   console.log("Seelected ride id ", selectedRideId);
  // }, [selectedRideId]);

  const amount = useSelector(
    (state) => state.driverCurrentRide.currentRideDetails?.amount
  );

  const paymentType = useSelector(
    (state) => state.driverCurrentRide.currentRideDetails?.paymentType
  );

  const navigateToDriverHome = useNavigateOnEvent(
    DRIVER_VIEW_RIDES_MY_BIDS_ROUTE
  );

  // const navToOverduePageAfterRideEnd = useNavigateOnEvent(
  //   DRIVER_RIDE_END_OVERDUE_SCREEN
  // );
  const [endRideButtonCount, setEndRideButtonCount] = useState(0);

  const onEndRideHandler = () => {
    if (endRideButtonCount === 1) return;
    setEndRideButtonCount(1);
    // console.log("Start ride function");
    toast.info("  Ending ride...", {
      toastId: "ride",
      icon: <LoadingSpinner />,
      autoClose: false,
    });
    // console.log("Notification loading");
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    fetch(`${BASE_URL}${DRIVER_END_RIDE}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: selectedRideId,
      }),
    })
      .then((response) => {
        if (response.ok) {
          // navigateToDriverHome();
          // console.log("Email sent!!");

          return response.json();
        }
        throw response;
      })
      .then((data) => {
        // console.log("email data", data);
        // console.log("Notification success");
        toast.update("ride", {
          render: "Ride Completed",
          type: toast.TYPE.SUCCESS,
          autoClose: 20000,
          closeButton: true,
          icon: null,
        });
        // navigateToDriverHome();
        // navToOverduePageAfterRideEnd();
        dispatch(driverAllRideActions.updateBidConfirmedRides([]));
        const amountInRs = (parseFloat(amount) / 100).toFixed(1);
        navigate(
          `${DRIVER_RIDE_END_OVERDUE_SCREEN}/?collectAmount=${amountInRs}`
        );
      })
      .catch((e) => {
        setEndRideButtonCount(0);
        console.log("RideDetailsEndRide.js, Error ending ride", e);
      });
  };

  const floatingButtonOnClickHandler = async () => {
    // console.log("...");
    // console.log("Clicked ...");

    navigateToDriverHome();
    // const inProgressButtonOnClickHandler = () => {
    //   console.log("Going to in progress screen...");
    //   console.log("Navigate to In progress screen...");
    //   dispatch(driverAllRideActions.setSelectedRide(rideIdForInProgressRide));
    //   navigateToInProgressEndRide();
    //   return;
    // };
  };
  return (
    <MenuTextUserWrapper logoHeadingText="Ride details">
      <div className={styles.rideDetailsEndRide}>
        <TopGreenCheckStatus displayText="Your ride is in progress." />

        <RiderNamePickupDropCancelCard
          fullName={rideDetails?.fullName || "NA"}
          pickupAddressText={rideDetails?.pickupAddressText || "NA"}
          dropAddressText={rideDetails?.dropAddressText || "NA"}
          phoneNumber={rideDetails?.phoneNumber || "NA"}
          endRide
        />

        <p className={styles.message}>Collect your payment after ride ends.</p>
        <Button
          displayText="Go to maps"
          onClickFunction={() => {
            windowOpenGoogleMapHandler(
              rideDetails?.dropAddressLat,
              rideDetails?.dropAddressLng
            );
          }}
        />
        <PlaceBidButton
          disabledState
          onClickFunction={onEndRideHandler}
          displayText="End Ride"
          value={`₹ ${
            paymentType === "Cash"
              ? (parseFloat(amount) / 100).toFixed(1)
              : (parseFloat(amount) / 100).toFixed(1)
          }`}
        />
        <YourEarningCommission
          amountPayable={rideDetails?.amount}
          commissionCollectedByDriver={rideDetails?.commissionCollectedByDriver}
          taxiwarsCommissionAmount={rideDetails?.taxiwarsCommissionAmount}
          totalAmount={rideDetails?.totalAmount}
        />

        {false && (
          <FloatingActionButton
            displayText={"Live Rides"}
            styleOptions={{ leftAligned: true }}
            floatingButtonOnClick={floatingButtonOnClickHandler}
          />
        )}
      </div>
      <SosButton currentRideId={selectedRideId} />
    </MenuTextUserWrapper>
  );
};

export default RideDetailsEndRide;
