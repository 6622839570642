import React from "react";
// eslint-disable-next-line
import styles from "./FloatingActionButton.module.css";

const FloatingActionButton = ({
  floatingButtonOnClick = () => {
    console.log("FloatingActionButton.js, Function not connected");
  },
  displayText = "prop - displayText",
  styleOptions = {
    leftAligned: false,
    rightAligned: true,
  },
  externalStyles = {},
}) => {
  return (
    <div
      className={`${styles.floatingAction} 
      ${styleOptions?.leftAligned && styles.left}  
      ${styleOptions?.rightAligned && styles.right}`}
      style={externalStyles}
    >
      <button
        onClick={floatingButtonOnClick}
        className={styles.floatingActionButton}
      >
        {displayText}
      </button>
    </div>
  );
};

export default FloatingActionButton;
