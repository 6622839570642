import React from "react";
import { BackPageHeadingWrapper, Button } from "../../../components";
import { useNavigateOnEvent } from "../../../hooks";
// eslint-disable-next-line
import styles from "./NotFound.module.css";

const NotFound = () => {
  const nvaigateBack = useNavigateOnEvent(-1, true);
  return (
    <BackPageHeadingWrapper logoHeadingText="Page Not Found">
      <div className={styles.notFound}>
        <p style={{ fontSize: "4rem" }}>404</p>
        <p style={{ fontSize: "1.6rem" }}>Not Found</p>
        <p style={{ fontSize: "1.6rem" }}>Page might have been deleted</p>
        <Button
          displayText="Navigate Back"
          onClickFunction={() => {
            nvaigateBack();
          }}
        />
      </div>
    </BackPageHeadingWrapper>
  );
};

export default NotFound;
