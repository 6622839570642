import React from "react";
import { Outlet } from "react-router-dom";
// eslint-disable-next-line
import styles from "./ScreenWrapper.module.css";

const ScreenWrapper = () => {
  return (
    <div className={styles.screenWrapper}>
      <Outlet />
    </div>
  );
};

export default ScreenWrapper;
