import React from "react";
import { images } from "../../../images";
// eslint-disable-next-line
import styles from "./MenuTextUserWrapper.module.css";

import { AnimatePresence, motion, useCycle } from "framer-motion";
import {
  useCustomLogger,
  useLogoutHandler,
  useNavigateOnEvent,
} from "../../../hooks";
import {
  ADV_DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
  ADV_RIDE_INITIALS,
  DRIVER_EXTRAS_HELP,
  DRIVER_EXTRAS_PAST_RIDES,
  DRIVER_SCHEDULED_RIDE,
  DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
  RIDER_EXTRAS_HELP,
  RIDER_EXTRAS_PAST_RIDES,
  RIDER_HOME_POST_A_RIDE__TO_ROUTE,
} from "../../../constants/routeConstants";
import { GLOBAL_VERSION_CONSTANT } from "../../../constants/storageConstants";
import { useDispatch } from "react-redux";
import { uiElementActions } from "../../../store/slice";
import { useNavigate } from "react-router-dom";

const MenuTextUserWrapper = ({
  children,
  logoHeadingText = "prop-MenuTextUserWrapper",
}) => {
  const dispatch = useDispatch();
  const navigateToDriverHome = useNavigateOnEvent(
    DRIVER_VIEW_RIDES_MY_BIDS_ROUTE
  );
  const navigateToExtrasPastRides = useNavigateOnEvent(
    DRIVER_EXTRAS_PAST_RIDES
  );
  const navigateToExtrasHelp = useNavigateOnEvent(DRIVER_EXTRAS_HELP);

  const navigateToSchduledRide = useNavigateOnEvent(DRIVER_SCHEDULED_RIDE);
  const naviateToFirst = () => {
    cycleOpen();
    navigateToDriverHome();
  };

  const naviateToSecond = () => {
    cycleOpen();
    navigateToExtrasPastRides();
  };

  const naviateToThird = () => {
    cycleOpen();
    navigateToExtrasHelp();
  };

  const logoutFunction = useLogoutHandler();

  // const naviateToFirst = () => {
  //   cycleOpen();
  //   navigateToPostRideTo();
  // };

  // const naviateToSecond = () => {
  //   cycleOpen();
  //   navigateToExtrasPastRides();
  // };

  // const naviateToThird = () => {
  //   cycleOpen();
  //   navigateToExtrasHelp();
  // };

  const logsDialogBox = () => {
    cycleOpen();
    dispatch(uiElementActions.openLogsDownloadDialogBox);
  };

  const navigate = useNavigate();

  const onClickScheduledRide = () => {
    cycleOpen();
    navigateToSchduledRide();
    navigate(`${ADV_DRIVER_VIEW_RIDES_MY_BIDS_ROUTE}`);
  };

  const hamMenuItems = [
    {
      imgSrc: images.car,
      alt: "car",
      text: "Current ride",
      onClickNavigation: naviateToFirst,
    },
    {
      imgSrc: images.timeCar,
      alt: "car",
      text: "Advance Booking",
      onClickNavigation: onClickScheduledRide,
    },
    {
      imgSrc: images.clock,
      alt: "clock",
      text: "Your Rides",
      onClickNavigation: naviateToSecond,
    },
    {
      imgSrc: images.headset,
      alt: "help",
      text: "Help",
      onClickNavigation: naviateToThird,
    },
    {
      imgSrc: images.logOff,
      alt: "log out",
      text: "Log Off",
      onClickNavigation: logoutFunction,
    },
    // {
    //   imgSrc: images.saveLogs,
    //   alt: "download ",
    //   text: "Save Logs",
    //   onClickNavigation: logsDialogBox,
    // },
  ];
  const [open, cycleOpen] = useCycle(false, true);

  //
  const url = window.location.href;
  const searchString = ADV_RIDE_INITIALS;

  const isStringInURL = url.includes(searchString);
  console.log(isStringInURL, url);
  return (
    <>
      <div className={styles.menuTextUserWrapper}>
        <AnimatePresence>
          {open && (
            <>
              <motion.aside
                initial={{ width: "0%", opacity: 0 }}
                animate={{ width: "70%", opacity: 1 }}
                exit={{
                  width: "0%",
                  transition: { delay: 0, duration: 0.3 },
                  opacity: 0,
                }}
                className={styles.hamburgerMenu}
              >
                <button onClick={cycleOpen} className={styles.crossButton}>
                  X
                </button>

                <motion.ul
                  initial={{ opacity: 0, visibility: "hidden" }}
                  animate={{ opacity: 1, visibility: "visible" }}
                  exit={{
                    opacity: 0,
                    transition: { delay: 0, duration: 0.1 },
                  }}
                  className={styles.hamburgerList}
                >
                  {hamMenuItems.map((eachItem) => (
                    <li
                      onClick={eachItem.onClickNavigation}
                      key={eachItem.text}
                      className={styles.liClass}
                    >
                      <img src={eachItem.imgSrc} alt={eachItem.alt} />
                      <span>{eachItem.text}</span>
                    </li>
                  ))}
                </motion.ul>

                <motion.p
                  initial={{ opacity: 0, visibility: "hidden" }}
                  animate={{ opacity: 1, visibility: "visible" }}
                  exit={{
                    opacity: 0,
                    transition: { delay: 0, duration: 0.1 },
                  }}
                  className={styles.termsOfService}
                  onClick={() => {}}
                  onDoubleClick={logsDialogBox}
                >
                  {`Version - ${GLOBAL_VERSION_CONSTANT}`}
                </motion.p>
              </motion.aside>
              <motion.aside
                onClick={cycleOpen}
                className={styles.hamburgerSideClosing}
              ></motion.aside>
            </>
          )}
        </AnimatePresence>
        <div
          style={{
            backgroundColor: isStringInURL ? "#419c00" : "",
          }}
          className={styles.appbar}
        >
          <img
            onClick={cycleOpen}
            className={styles.hamburgerIcon}
            src={images.hamburgerIcon}
            alt="back icon"
          />
          <h1
            style={{
              fontWeight: isStringInURL ? "bold" : "",
            }}
            className={styles.logoHeadingText}
          >
            {logoHeadingText}
          </h1>
          <img
            className={styles.userGeneric}
            src={images.userGeneric}
            alt="user profile"
          />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </>
  );
};

export default MenuTextUserWrapper;
