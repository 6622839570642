import React from "react";
// eslint-disable-next-line
import styles from "./LiveBidHeadingWithSort.module.css";

const LiveBidHeadingWithSort = ({ driverName }) => {
  return (
    <div className={styles.liveBidHeadingWithSort}>
      <p className={styles.headingLiveBids}>Hello {driverName}</p>
      {/* <select>
        <option>Sort</option>
        <option>distance</option>
        <option>pickup point</option>
      </select> */}
      {/* <p className={styles.headingLiveBidsSearching}>
        Searching rides near you
      </p> */}
    </div>
  );
};

export default LiveBidHeadingWithSort;
