import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ErrorInNumber,
  InputBox,
  InputLabelUserProfileImage,
  RiderDriverSign,
  TextHeadingSign,
} from "../../../components";
import { DRIVER_PROFILE_ADDRESS_DETAILS_ROUTE } from "../../../constants/routeConstants";
import { useNavigateOnEvent } from "../../../hooks";
import { driverProfileActions } from "../../../store/slice";
// eslint-disable-next-line
import styles from "./DriverCompleteProfile.module.css";
import { EMAIL_VALIDATION_REGEX } from "../../../constants/storageConstants";
import { toast } from "react-toastify";

const DriverCompleteProfile = () => {
  const dispatch = useDispatch();

  const navigateToAddressDetails = useNavigateOnEvent(
    DRIVER_PROFILE_ADDRESS_DETAILS_ROUTE,
    false
  );

  const driverPhoto = useSelector((state) => state.driverProfile.driverImage);
  const firstName = useSelector((state) => state.driverProfile.firstName);
  const lastName = useSelector((state) => state.driverProfile.lastName);
  const emailAddress = useSelector((state) => state.driverProfile.emailAddress);
  const helpNumber = useSelector((state) => state.driverProfile.helpNumber);

  const [isError, setIsError] = useState({
    firstName: false,
    lastName: false,
    emailAddress: false,
    helpNumber: false,
    image: false,
    isValidEmail: false,
  });
  const firstNameChangeHandler = (e) => {
    dispatch(driverProfileActions.updateFirstName(e.target.value));
    setIsError((prev) => ({ ...prev, firstName: false }));
  };

  const lastNameChangeHandler = (e) => {
    dispatch(driverProfileActions.updateLastName(e.target.value));
    setIsError((prev) => ({ ...prev, lastName: false }));
  };

  const emailAddressChangeHandler = (e) => {
    dispatch(driverProfileActions.updateEmailAddress(e.target.value));
    setIsError((prev) => ({ ...prev, emailAddress: false }));
    setIsError((prev) => ({ ...prev, isValidEmail: false }));
  };

  const helpNumberChangeHandler = (e) => {
    const num = e.target.value;
    if (num.length > 10) return;

    dispatch(driverProfileActions.updateHelpNumber(e.target.value));
    setIsError((prev) => ({ ...prev, helpNumber: false }));
  };

  const checkValidateAndNavigate = () => {
    setIsError(false);
    // validation
    if (firstName.trim().length === 0) {
      setIsError((prev) => ({ ...prev, firstName: true }));
      return;
    }
    if (lastName.trim().length === 0) {
      setIsError((prev) => ({ ...prev, lastName: true }));
      return;
    }

    if (emailAddress.trim().length !== 0) {
      if (!EMAIL_VALIDATION_REGEX.test(emailAddress)) {
        setIsError((prev) => ({ ...prev, isValidEmail: true }));
        return;
      }
    }

    if (helpNumber.trim().length !== 0) {
      if (helpNumber.length < 10) {
        setIsError((prev) => ({ ...prev, helpNumber: true }));
        return;
      }
    }
    // email validation

    // if (emailAddress.trim().length === 0) {
    //   setIsError((prev) => ({ ...prev, emailAddress: true }));
    //   return;
    // }

    // if (!driverPhoto) {
    //   setIsError((prev) => ({ ...prev, image: true }));
    //   return;
    // }

    console.log(
      "DriverCompleteProfile.js, All checks passed. Navigating to Address details"
    );
    navigateToAddressDetails();
  };

  // const [file, setFile] = useState();
  function handleImageChange(e) {
    // console.log(e.target.files.length);
    toast.dismiss();
    if (e.target.files.length === 0) {
      toast.dismiss();
      // toast.info("No file selected");
      return;
    }
    dispatch(
      driverProfileActions.updateDriverImage(
        URL.createObjectURL(e.target.files[0])
      )
    );

    // dispatch to redux store the filename
    dispatch(
      driverProfileActions.updateDriverPhotoName(e.target.files[0]?.name)
    );
    // dispatch to redux store the URL lOcation
    dispatch(
      driverProfileActions.updateDriverPhotoFileLocation(
        URL.createObjectURL(e.target.files[0])
      )
    );
  }

  return (
    <>
      <RiderDriverSign driver>
        <div className={styles.children}>
          <TextHeadingSign displayText="Complete your profile" />
          {/* Generic Photo upload */}
          <div className={styles.translate}>
            <InputLabelUserProfileImage
              idAndName="driverProfilePic"
              handleChange={handleImageChange}
              uploadedImage={driverPhoto}
            />
          </div>
          {isError.image && (
            <ErrorInNumber displayText="Please select an image" />
          )}
          <InputBox
            type="text"
            idAndName="firstName"
            placeholderText="Enter your First Name"
            value={firstName}
            onChange={firstNameChangeHandler}
          />
          {isError.firstName && (
            <ErrorInNumber displayText="First name cannot be empty." />
          )}
          <InputBox
            type="text"
            idAndName="lastName"
            placeholderText="Enter your Last Name"
            value={lastName}
            onChange={lastNameChangeHandler}
          />
          {isError.lastName && (
            <ErrorInNumber displayText="Last name cannot be empty." />
          )}
          <InputBox
            type="email"
            idAndName="emailAddress"
            placeholderText="Enter your e-mail address? ( Optional )"
            value={emailAddress}
            onChange={emailAddressChangeHandler}
          />
          {isError.isValidEmail && (
            <ErrorInNumber displayText="Please enter correct email." />
          )}
          <InputBox
            type="tel"
            idAndName="helpNumber"
            placeholderText="Emergency Contact ( Optional )"
            value={helpNumber}
            onChange={helpNumberChangeHandler}
          />
          {isError.helpNumber && (
            <ErrorInNumber displayText="Please enter correct number." />
          )}
          {/* {isError.emailAddress && (
            <ErrorInNumber displayText="Email cannot be empty." />
          )} */}
          <Button
            displayText="Continue"
            onClickFunction={checkValidateAndNavigate}
          />
        </div>
      </RiderDriverSign>
    </>
  );
};

export default DriverCompleteProfile;
