import React, { useEffect, useState } from "react";
// eslint-disable-next-line
import styles from "./BidSecurityDepositCcAvenueWaitingPage.module.css";
import { BackPageHeadingWrapper, LoadingSpinner } from "../../../components";
import { useNavigateOnEvent, usePreventBackBehaviour } from "../../../hooks";
import {
  BASE_URL,
  CC_AVENUE_STATUS_AFTER_UPI,
} from "../../../constants/apiConstants";
import Cookies from "js-cookie";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../../constants/storageConstants";
import { DRIVER_VIEW_RIDES_MY_BIDS_ROUTE } from "../../../constants/routeConstants";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";

const elapsedSecondsLocalStorage = "_elapsedSeconds_";
const isActiveLocalStorage = "_isActive_";

const BidSecurityDepositCcAvenueWaitingPage = () => {
  usePreventBackBehaviour();
  // const navigate = useNavigate();

  const navigateToDriverViewRides = useNavigateOnEvent(
    DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
    true
  );

  const [urlParams] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const paymentUniqueId = searchParams.get("paymentUniqueId");

    console.log("Param ride id waiting page", paymentUniqueId, "");
    return { paymentUniqueId: paymentUniqueId };
  });

  const [elapsedSeconds, setElapsedSeconds] = useState(
    parseInt(localStorage.getItem(elapsedSecondsLocalStorage)) || 300
  );
  const [isActive, setIsActive] = useState(
    JSON.parse(localStorage.getItem(isActiveLocalStorage)) || false
  );

  useEffect(() => {
    setIsActive(true);
  }, []);

  useEffect(() => {
    let timer;

    if (isActive && elapsedSeconds > 0) {
      timer = setInterval(() => {
        setElapsedSeconds((prevElapsedSeconds) => prevElapsedSeconds - 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isActive, elapsedSeconds]);

  const startTimer = () => {
    setIsActive(true);
  };

  const stopTimer = () => {
    setIsActive(false);
  };

  const resetTimer = () => {
    setIsActive(false);
    setElapsedSeconds(300);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  useEffect(() => {
    if (elapsedSeconds === 0) {
      setIsActive(false);
      // You can add any actions you want to perform when the timer reaches 0:00 here.
      // alert("Countdown timer has reached 0:00!");
    }

    // Store the timer state in local storage
    localStorage.setItem(elapsedSecondsLocalStorage, elapsedSeconds.toString());
    localStorage.setItem(isActiveLocalStorage, JSON.stringify(isActive));
  }, [elapsedSeconds, isActive]);

  useEffect(() => {
    console.log("Array Called");
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    const url = `${BASE_URL}${CC_AVENUE_STATUS_AFTER_UPI}`;

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      method: "POST",
      body: JSON.stringify({
        payment_unique_id: urlParams.paymentUniqueId,
      }),
    };

    const fetchStatus = async () => {
      const response = await fetch(url, options);

      if (response.ok) {
        const data = await response.json();
        console.log("Waiting data", data);

        if (data && data.Status && data.Status.toString() === "1") {
          toast.error("Error occured");
        } else if (data && data.Status && data.Status.toString() === "0") {
          toast.success("Payment Success");
          // navigate to home page
          navigateToDriverViewRides();
        } else {
          //
        }
      }
    };

    const intervalStatus = setInterval(() => {
      fetchStatus();
    }, 5000);

    return () => {
      clearInterval(intervalStatus);
    };
  }, []);

  return (
    <BackPageHeadingWrapper
      logoHeadingText="Payment Confirmation"
      diableBackButton
    >
      <div className={styles.bidSecurityDepositCcAvenueWaitingPage}>
        <h1 className={styles.heading}>Complete your payment within</h1>
        <div>
          <h2 className={styles.heading}>
            {formatTime(elapsedSeconds)} minutes
          </h2>
          {/* <button onClick={startTimer}>Start</button> */}
        </div>
        <div className={styles.redirect}>
          <LoadingSpinner useLargeSpinner />
          <p>Do not press back or refresh this page</p>
          <p>We will redirect you after payment is confirmed</p>
        </div>
      </div>
    </BackPageHeadingWrapper>
  );
};

export default BidSecurityDepositCcAvenueWaitingPage;
