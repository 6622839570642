import React from "react";
// eslint-disable-next-line
import styles from "./InputOptionBoxForCityDriver.module.css";

const InputOptionBoxForCityDriver = ({
  idAndName,
  disabledOption = "",
  options = [],
  defaultValue = "",
  onChange,
}) => {
  return (
    <>
      {/* <label for="name"></label> */}
      <select
        className={styles.inputOptionBoxForCityDriver}
        id={idAndName}
        name={idAndName}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {disabledOption && (
          <option disabled value="">
            {disabledOption}
          </option>
        )}
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </>
  );
};

export default InputOptionBoxForCityDriver;
