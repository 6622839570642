import React from "react";
import {
  BackPageHeadingWrapper,
  RideCarDriverPickupDropDetailsCard,
  StaticMapWithTimeCancelledCard,
} from "../../../components";
// eslint-disable-next-line
import styles from "./RiderPastRideDetails.module.css";

const RiderPastRideDetails = () => {
  return (
    <BackPageHeadingWrapper logoHeadingText="TRIPID002">
      <div className={styles.riderPastRideDetails}>
        <StaticMapWithTimeCancelledCard />
        <RideCarDriverPickupDropDetailsCard />
      </div>
    </BackPageHeadingWrapper>
  );
};

export default RiderPastRideDetails;
