import React from "react";
import { motion } from "framer-motion";

const AnimationLayoutSlide = ({ children }) => {
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ duration: 0.1 }}
    >
      {children}
    </motion.div>
  );
};

export default AnimationLayoutSlide;
