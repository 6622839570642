import React from "react";
// eslint-disable-next-line
import styles from "./Button.module.css";

const Button = ({
  displayText = "prop-Button",
  onClickFunction = () => {
    console.warn("TODO - Function not connected");
  },
  imageAvailble = false,
  leftImageAvailable = false,
  customFont = false,
  style,
}) => {
  return (
    <div
      style={style}
      className={`${styles.button} ${customFont ? styles.customFont : ""}`}
      onClick={onClickFunction}
    >
      {leftImageAvailable && (
        <img
          className={`${styles.imageInsideButton} ${styles.leftImageCustomStyle}`}
          src={leftImageAvailable}
          alt="click"
        />
      )}
      {displayText}
      {imageAvailble && (
        <img
          className={styles.imageInsideButton}
          src={imageAvailble}
          alt="google map"
        />
      )}
    </div>
  );
};

export default Button;
