import React from "react";
import { images } from "../../images";
import { SingleGridTwoColumnCard } from "../../components";
// eslint-disable-next-line
import styles from "./RideCarDriverPickupDropDetailsCard.module.css";

const RideCarDriverPickupDropDetailsCard = ({
  cancelled = false,
  carName,
  driverName,
  rideStartTime,
  rideEndTime,
  pickupAddressText,
  dropAddressText,
  totalAmount,
}) => {
  const rideStartTime12 = new Date(
    "1970-01-01T" + rideStartTime + "Z"
  ).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const rideEndTime12 = new Date(
    "1970-01-01T" + rideEndTime + "Z"
  ).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  console.log("rideStartTime12 hour format", rideStartTime12);
  console.log("rideEndTime12 hour format", rideEndTime12);

  return (
    <div className={styles.rideCarDriverPickupDropDetailsCard}>
      <SingleGridTwoColumnCard>
        <div className={styles.carImage}>
          <img className={styles.cab} src={images.cab} alt="cab" />
        </div>
        <div className={styles.carTypeAndName}>
          <p></p>
          <p>{carName}</p>
        </div>
      </SingleGridTwoColumnCard>

      <hr className={styles.hrLine} />
      <SingleGridTwoColumnCard>
        <div className={styles.driverPic}>
          <img
            className={styles.userGeneric}
            src={images.userGeneric}
            alt="user"
          />
        </div>
        <p className={styles.driverName}>{driverName}</p>
      </SingleGridTwoColumnCard>

      <hr className={styles.hrLine} />
      <SingleGridTwoColumnCard>
        <div className={styles.time}>
          <p>{rideStartTime12}</p>
          <p>{rideEndTime12}</p>
        </div>
        <div className={styles.originDestination}>
          <p>
            <span className={`${styles.dot} ${styles.green}`}></span>
            {pickupAddressText?.slice(0, 45)}
          </p>
          <p>
            <span className={`${styles.dot} ${styles.red}`}></span>
            {dropAddressText?.slice(0, 45)}
          </p>
        </div>
      </SingleGridTwoColumnCard>

      {!cancelled && (
        <>
          <hr className={styles.hrLine} />
          <SingleGridTwoColumnCard>
            <div className={styles.price}>
              <img
                className={styles.paperMoney}
                src={images.paperMoney}
                alt="paper money"
              />
            </div>
            <p className={styles.amountSpent}>{totalAmount}</p>
          </SingleGridTwoColumnCard>
        </>
      )}
    </div>
  );
};

export default RideCarDriverPickupDropDetailsCard;
