import React from "react";
// eslint-disable-next-line
import styles from "./StaticMapWithTimeCancelledCard.module.css";

const StaticMapWithTimeCancelledCard = ({ cancelled = false }) => {
  return (
    <div className={styles.staticMapWithTimeCancelledCard}>
      <div className={styles.heading}>
        {cancelled && <p className={styles.cancelled}>Cancelled - </p>}
        <p>Yesterday 11:06 AM</p>
      </div>
      <div className={styles.staticMapImage}>Static Map Image</div>
    </div>
  );
};

export default StaticMapWithTimeCancelledCard;
