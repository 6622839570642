import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  ErrorInNumber,
  LogsBottomModal,
  MenuTextUserWrapper,
  RiderNamePickupDropCancelCard,
  SosButton,
  TopGreenCheckStatus,
} from "../../../components";
// eslint-disable-next-line
import styles from "./BidAcceptedMeetAtPickupPoint.module.css";
import {
  useDriverFetchRideDetails,
  useGeolocation,
  useInterval,
  useNavigateOnEvent,
  usePreventBackBehaviour,
} from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { ACCESS_TOKEN_COOKIE_NAME } from "../../../constants/storageConstants";
import {
  BASE_URL,
  DRIVER_GET_RIDE_STATUS,
  DRIVER_SEND_CURRENT_LOCATION,
  RIDE_STATUS_CANCELLED,
  RIDE_STATUS_IN_PROGRESS,
} from "../../../constants/apiConstants";
import {
  driverCurrentRideActions,
  riderCurrentRideActions,
} from "../../../store/slice";
import {
  LOCATION_UPDATE_TIME_IN_MILLI_SECOND,
  THIRTY_SECONDS,
} from "../../../constants/timeConstants";
import {
  DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE,
  DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
} from "../../../constants/routeConstants";
import OtpInput from "react18-input-otp";
import { toast } from "react-toastify";
import { images } from "../../../images";

const BidAcceptedMeetAtPickupPoint = () => {
  usePreventBackBehaviour();
  const dispatch = useDispatch();

  const [isRideCancelledDialogOpen, setIsRideCancelledDialogOpen] =
    useState(false);

  const { rideDetails, startRideHandler, windowOpenGoogleMapHandler } =
    useDriverFetchRideDetails();

  const navigateToEndRideScreen = useNavigateOnEvent(
    DRIVER_RIDE_IN_PROGRESS_END_RIDE_ROUTE,
    true
  );

  const navigateToDriverHome = useNavigateOnEvent(
    DRIVER_VIEW_RIDES_MY_BIDS_ROUTE,
    true
  );

  const currentRideId = useSelector(
    (state) => state.driverCurrentRide.currentRideId
  );

  const [setOtpLengthError, setSetOtpLengthError] = useState(false);

  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  const httpPolling = () => {
    fetch(`${BASE_URL}${DRIVER_GET_RIDE_STATUS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: currentRideId,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((rideStatus) => {
        console.log("Current ride Status", rideStatus);
        // dispatch(
        //   riderCurrentRideActions.setCurrentRideStatus(rideStatus?.Ride_Status)
        // );
        // dispatch(driverCurrentRideActions.)
        if (rideStatus?.Ride_Status === RIDE_STATUS_CANCELLED) {
          //
          // toast.info("Ride was cancelled by the user");
          setIsRideCancelledDialogOpen(true);
        }
      })
      .catch((e) => {
        console.log("Error in retrieving ride status", e);
      });
  };

  useInterval(httpPolling, THIRTY_SECONDS);

  const [otpNumber, setOtpNumber] = useState("");

  const handleOtpChangeForStartRide = (startRideOtp) => {
    setSetOtpLengthError(false);
    setOtpNumber(startRideOtp);
    // console.log("startRideOtp", startRideOtp);
  };

  const startRideHandlerWithOtp = async () => {
    // api call to checjk for correct otp
    if (otpNumber.length !== 4) {
      setSetOtpLengthError(true);
      return;
    }

    console.log("Data sent for start ride: ");
    console.log("currentRideId - otpNumber");
    console.log(currentRideId, "-", otpNumber);
    const response = await fetch(`${BASE_URL}/driver/start-ride/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        ride_id: currentRideId,
        ride_otp: parseInt(otpNumber),
      }),
    });

    if (response.ok) {
      navigateToEndRideScreen();
      const data = await response.json();
      return;
    }

    setSetOtpLengthError(true);

    //
  };

  const [location, setLocation] = useState();

  const options = useMemo(
    () => ({
      enableHighAccuracy: true,
      maximumAge: 1 * 1000,
      timeout: LOCATION_UPDATE_TIME_IN_MILLI_SECOND,
    }),
    []
  );

  const {
    loading: loadingGeoLoc,
    error: errorGeoLoc,
    data: dataGeoLoc,
  } = useGeolocation(options);

  useEffect(() => {
    if (loadingGeoLoc) return;
    if (errorGeoLoc) return;

    console.log("BidAcceptedMeetAtPickupPoint.js, Current Driver location: ");
    console.log(dataGeoLoc);

    setLocation({
      latitude: dataGeoLoc.latitude,
      longitude: dataGeoLoc.longitude,
    });
  }, [loadingGeoLoc, errorGeoLoc, dataGeoLoc]);

  // useEffect(() => {
  //   const sendLocationUpdates = () => {
  //     console.log("BidAcceptedMeetAtPickupPoint.js, Sending location...");
  //     const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  //     fetch(` ${BASE_URL}${DRIVER_SEND_CURRENT_LOCATION}`, {
  //       method: "PATCH",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //       body: JSON.stringify({
  //         driver_lat: location?.latitude.toString(),
  //         driver_lng: location?.longitude.toString(),
  //       }),
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           console.log(
  //             "BidAcceptedMeetAtPickupPoint.js, Location updated at server..."
  //           );
  //           return response.json();
  //         }
  //         throw response;
  //       })

  //       .catch((error) =>
  //         console.log("BidAcceptedMeetAtPickupPoint.js, error", error)
  //       );
  //   };

  //   if (location) {
  //     sendLocationUpdates();
  //   }

  //   const intervalLocation = setInterval(() => {
  //     if (location) {
  //       sendLocationUpdates();
  //     }
  //     // can be changed to any time we want
  //   }, LOCATION_UPDATE_TIME_IN_MILLI_SECOND);

  //   return () => clearInterval(intervalLocation);
  // }, [dispatch, location]);

  return (
    <MenuTextUserWrapper logoHeadingText="Congratulations! You won the bid">
      <div className={styles.bidAcceptedMeetAtPickupPoint}>
        <LogsBottomModal
          // para={"This ride is not eligible for refund"}
          heading="This ride has been cancelled by the user"
          // heading="Cancel Ride ?"
          // downloadButtonText={"Yes"}
          // cancelButtonText={"No"}
          openLogDialogBox={isRideCancelledDialogOpen}
          // downloadLogsButton={() => {
          //   cancelButtonOnClickFunctionHandler();
          // }}
          cancelButton={() => {
            setIsRideCancelledDialogOpen(false);
            navigateToDriverHome();
          }}
          para={"Cancel Ride ?"}
          // heading="Cancel Ride ?"
          cancelledRide
        />
        <TopGreenCheckStatus displayText="Meet rider at pickup point." />
        <RiderNamePickupDropCancelCard
          fullName={rideDetails?.fullName || "NA"}
          pickupAddressText={rideDetails?.pickupAddressText || "NA"}
          dropAddressText={rideDetails?.dropAddressText || "NA"}
          phoneNumber={rideDetails?.phoneNumber || "NA"}
        />
        <Button
          displayText="पिकअप प्वाइंट का रास्ता"
          onClickFunction={() => {
            windowOpenGoogleMapHandler(
              rideDetails?.pickupAddressLat,
              rideDetails?.pickupAddressLng
            );
          }}
          imageAvailble={images.googleMaps}
          leftImageAvailable={images.click}
          customFont
        />
        <p className={styles.enterOtpMessage}>
          Please ask the customer for OTP to start ride
        </p>
        <OtpInput
          disabledStyle
          isInputNum
          value={otpNumber}
          onChange={handleOtpChangeForStartRide}
          numInputs={4}
          separator={""}
          className={styles.box}
          inputStyle={styles.inputBox}
        />
        {setOtpLengthError && <ErrorInNumber displayText="Incorrect OTP" />}
        <Button
          displayText="Start Ride"
          onClickFunction={startRideHandlerWithOtp}
        />
      </div>
      <SosButton currentRideId={currentRideId} />
    </MenuTextUserWrapper>
  );
};

export default BidAcceptedMeetAtPickupPoint;
