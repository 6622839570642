import React from "react";
import { images } from "../../images";
// eslint-disable-next-line
import styles from "./InputWithLabel.module.css";

const InputWithLabel = ({
  forIdAndName = "prop-InputWithLabel",
  labelDisplayText = "prop-InputWithLabel",
  handleChange,
  multiple = false,
  value = "",
}) => {
  return (
    <>
      <div className={styles.labelWithInput}>
        <input
          type="file"
          id={forIdAndName}
          accept="image/*"
          name={forIdAndName}
          onChange={handleChange}
          style={{ display: "none" }}
          multiple={multiple}
        />
        <p className={styles.inputLabel}>{labelDisplayText}</p>
        <label className={styles.fileUploadName} htmlFor={forIdAndName}>
          <span className={styles.labelName}>{value || "Select file"}</span>
          <img
            className={styles.camera}
            src={images.camera}
            alt="file upload icon"
          />
        </label>
      </div>
    </>
  );
};

export default InputWithLabel;
